// IMPORTANT: Any changes to ROUTES auth need to also be reflected in App.jsx

// Note: If you add a property to one route, ensure it is added to all others so we're aware of it.
// Also, for consistency, use positive over negative boolean names. For example: isOpen instead of isNotOpen.

import { OFFICES, OFFICES_ALL_KNOWN, OFFICES_ANY_ALL } from './COUNTRIES';

const ROUTES = {
  academy: {
    analyticsTitle: undefined,
    auth: {
      offices: [OFFICES.AU, OFFICES.CA, OFFICES.US],
    },
    footerOrder: undefined,
    headerLeftOrder: 4,
    headerRightOrder: undefined,
    id: 'academy',
    isBreadcrumbsVisible: true,
    isExternal: true,
    isFooterVisible: true,
    isHeaderLogoVisible: true,
    isHeaderVisible: true,
    isLayoutHeaderVisible: true,
    isPageTitleVisible: true,
    isPrintLinkVisible: true,
    isPublic: false,
    isTitleUpperCase: true,
    isVikingNumberVisible: false,
    key: 'academy',
    preventRedirectReturn: true,
    subRoutes: undefined,
    tabs: undefined,
    title: 'Travel Advisor Academy',
    url: '[API_PATH]/agent/academy',
  },
  account: {
    analyticsTitle: undefined,
    auth: {
      offices: OFFICES_ALL_KNOWN,
    },
    footerOrder: undefined,
    headerLeftOrder: undefined,
    headerRightOrder: 0,
    id: 'account',
    isBreadcrumbsVisible: true,
    isExternal: false,
    isFooterVisible: true,
    isHeaderLogoVisible: true,
    isHeaderVisible: true,
    isLayoutHeaderVisible: true,
    isPageTitleVisible: true,
    isPrintLinkVisible: true,
    isPublic: false,
    isTitleUpperCase: true,
    isVikingNumberVisible: false,
    key: 'account',
    preventRedirectReturn: false,
    subRoutes: undefined,
    tabs: undefined,
    title: 'My Account',
    url: '/account',
  },
  agencyRegistration: {
    analyticsTitle: undefined,
    auth: {
      offices: OFFICES_ANY_ALL,
    },
    footerOrder: undefined,
    headerLeftOrder: 4,
    headerRightOrder: undefined,
    id: 'agency-registration',
    isBreadcrumbsVisible: false,
    isExternal: false,
    isFooterVisible: true,
    isHeaderLogoVisible: true,
    isHeaderVisible: true,
    isLayoutHeaderVisible: true,
    isPageTitleVisible: true,
    isPrintLinkVisible: true,
    isPublic: true,
    isTitleUpperCase: true,
    isVikingNumberVisible: false,
    key: 'agency-registration',
    preventRedirectReturn: false,
    subRoutes: undefined,
    tabs: undefined,
    title: 'Agency ID Overview',
    url: '/agency-registration',
  },
  bestBusinessPartner: {
    analyticsTitle: undefined,
    auth: {
      offices: OFFICES_ANY_ALL,
    },
    footerOrder: undefined,
    headerLeftOrder: undefined,
    headerRightOrder: undefined,
    id: 'business',
    isBreadcrumbsVisible: false,
    isExternal: false,
    isFooterVisible: true,
    isHeaderLogoVisible: true,
    isHeaderVisible: true,
    isLayoutHeaderVisible: true,
    isPageTitleVisible: false,
    isPrintLinkVisible: false,
    isPublic: true,
    isTitleUpperCase: true,
    isVikingNumberVisible: false,
    key: 'business-partner',
    preventRedirectReturn: false,
    subRoutes: undefined,
    tabs: undefined,
    title: 'Travel Advisors’ Best Business Partner',
    url: '/best-business-partner',
  },
  bookingCreate: {
    analyticsTitle: undefined,
    auth: {
      offices: OFFICES_ALL_KNOWN,
    },
    footerOrder: undefined,
    headerLeftOrder: 1,
    headerRightOrder: undefined,
    id: 'booking-create',
    isBreadcrumbsVisible: true,
    isExternal: false,
    isFooterVisible: true,
    isHeaderLogoVisible: true,
    isHeaderVisible: true,
    isLayoutHeaderVisible: true,
    isPageTitleVisible: true,
    isPrintLinkVisible: true,
    isPublic: false,
    isTitleUpperCase: true,
    isVikingNumberVisible: false,
    key: 'booking-create',
    preventRedirectReturn: false,
    subRoutes: {
      itinerary: 'itinerary',
    },
    tabs: undefined,
    title: 'Make A Booking',
    url: '/make-a-booking',
  },
  bookingPersonal: {
    analyticsTitle: undefined,
    auth: {
      offices: OFFICES_ALL_KNOWN,
    },
    footerOrder: undefined,
    headerLeftOrder: undefined,
    headerRightOrder: 1,
    id: 'bookings-personal',
    isBreadcrumbsVisible: true,
    isExternal: false,
    isFooterVisible: true,
    isHeaderLogoVisible: true,
    isHeaderVisible: true,
    isLayoutHeaderVisible: true,
    isPageTitleVisible: true,
    isPrintLinkVisible: true,
    isPublic: false,
    isTitleUpperCase: true,
    isVikingNumberVisible: false,
    key: 'bookings-personal',
    preventRedirectReturn: false,
    subRoutes: undefined,
    tabs: undefined,
    title: 'My Personal Bookings',
    url: '/bookings-personal',
  },
  bookings: {
    analyticsTitle: undefined,
    auth: {
      offices: OFFICES_ALL_KNOWN,
    },
    footerOrder: undefined,
    headerLeftOrder: 2,
    headerRightOrder: undefined,
    id: 'bookings',
    isBreadcrumbsVisible: true,
    isExternal: false,
    isFooterVisible: true,
    isHeaderLogoVisible: true,
    isHeaderVisible: true,
    isLayoutHeaderVisible: true,
    isPageTitleVisible: true,
    isPrintLinkVisible: false,
    isPublic: false,
    isTitleUpperCase: true,
    isVikingNumberVisible: false,
    key: 'bookings',
    preventRedirectReturn: false,
    subRoutes: undefined,
    tabs: undefined,
    title: 'Manage My Bookings',
    url: '/manage-bookings',
  },
  contact: {
    analyticsTitle: undefined,
    auth: {
      offices: [OFFICES.AU, OFFICES.CA, OFFICES.US],
    },
    footerOrder: undefined,
    headerLeftOrder: 9,
    headerRightOrder: undefined,
    id: 'contact',
    isBreadcrumbsVisible: true,
    isExternal: false,
    isFooterVisible: true,
    isHeaderLogoVisible: true,
    isHeaderVisible: true,
    isLayoutHeaderVisible: true,
    isPageTitleVisible: true,
    isPrintLinkVisible: true,
    isPublic: false,
    isTitleUpperCase: true,
    isVikingNumberVisible: false,
    key: 'contact',
    preventRedirectReturn: false,
    subRoutes: undefined,
    tabs: undefined,
    title: 'Contact Your Sales Representative',
    url: '/resources/contact',
  },
  faq: {
    analyticsTitle: undefined,
    auth: {
      offices: [OFFICES.AU, OFFICES.CA, OFFICES.US],
    },
    footerOrder: undefined,
    headerLeftOrder: 12,
    headerRightOrder: undefined,
    id: 'faq',
    isBreadcrumbsVisible: true,
    isExternal: false,
    isFooterVisible: true,
    isHeaderLogoVisible: true,
    isHeaderVisible: true,
    isLayoutHeaderVisible: true,
    isPageTitleVisible: true,
    isPrintLinkVisible: true,
    isPublic: false,
    isTitleUpperCase: false,
    isVikingNumberVisible: false,
    key: 'faq',
    preventRedirectReturn: false,
    subRoutes: undefined,
    tabs: {
      bookingCruiseVikingAir: 'tap-booking-cruise-air',
      lifeOnBoard: 'life-on-board',
      manageMyBookings: 'manage-my-bookings',
      other: 'other',
      tripAndDocuments: 'trip-and-document',
      usingTheTravelAdvisorPortal: 'using-the-travel-advisor-portal',
      vikingRewards: 'viking-rewards',
    },
    title: 'FAQs',
    url: '/resources/faqs',
  },
  formsGuides: {
    analyticsTitle: undefined,
    auth: {
      offices: [OFFICES.AU],
    },
    footerOrder: undefined,
    headerLeftOrder: 11,
    headerRightOrder: undefined,
    id: 'forms-guides',
    isBreadcrumbsVisible: true,
    isExternal: false,
    isFooterVisible: true,
    isHeaderLogoVisible: true,
    isHeaderVisible: true,
    isLayoutHeaderVisible: true,
    isPageTitleVisible: true,
    isPrintLinkVisible: true,
    isPublic: false,
    isTitleUpperCase: true,
    isVikingNumberVisible: false,
    key: 'forms-guides',
    preventRedirectReturn: false,
    subRoutes: undefined,
    tabs: undefined,
    title: 'Forms & Guides',
    url: '/forms-guides',
  },
  groups: {
    analyticsTitle: undefined,
    auth: {
      offices: [OFFICES.AU, OFFICES.CA, OFFICES.US],
    },
    footerOrder: undefined,
    headerLeftOrder: 8,
    headerRightOrder: undefined,
    id: 'groups',
    isBreadcrumbsVisible: true,
    isExternal: false,
    isFooterVisible: true,
    isHeaderLogoVisible: true,
    isHeaderVisible: true,
    isLayoutHeaderVisible: true,
    isPageTitleVisible: true,
    isPrintLinkVisible: true,
    isPublic: false,
    isTitleUpperCase: true,
    isVikingNumberVisible: false,
    key: 'groups',
    preventRedirectReturn: false,
    subRoutes: undefined,
    tabs: undefined,
    title: 'Groups',
    url: '/groups',
  },
  home: {
    analyticsTitle: undefined,
    auth: {
      offices: OFFICES_ALL_KNOWN,
    },
    footerOrder: undefined,
    headerLeftOrder: 0,
    headerRightOrder: undefined,
    id: 'home',
    isBreadcrumbsVisible: true,
    isExternal: false,
    isFooterVisible: true,
    isHeaderLogoVisible: true,
    isHeaderVisible: true,
    isLayoutHeaderVisible: true,
    isPageTitleVisible: false,
    isPrintLinkVisible: false,
    isPublic: false,
    isTitleUpperCase: true,
    isVikingNumberVisible: false,
    key: 'home',
    preventRedirectReturn: false,
    subRoutes: undefined,
    tabs: undefined,
    title: 'Home',
    url: '/home',
  },
  itinerary: {
    analyticsTitle: 'Itinerary',
    auth: {
      offices: OFFICES_ALL_KNOWN,
    },
    footerOrder: undefined,
    headerLeftOrder: undefined,
    headerRightOrder: undefined,
    id: 'itinerary',
    isBreadcrumbsVisible: true,
    isExternal: false,
    isFooterVisible: true,
    isHeaderLogoVisible: true,
    isHeaderVisible: true,
    isLayoutHeaderVisible: true,
    isPageTitleVisible: true,
    isPrintLinkVisible: true,
    isPublic: false,
    isTitleUpperCase: true,
    isVikingNumberVisible: false,
    key: 'itinerary',
    preventRedirectReturn: false,
    subRoutes: undefined,
    tabs: undefined,
    title: 'Make A Booking',
    url: '/make-a-booking/itinerary',
  },
  loginFaqs: {
    analyticsTitle: undefined,
    auth: {
      offices: OFFICES_ANY_ALL,
    },
    footerOrder: undefined,
    headerLeftOrder: undefined,
    headerRightOrder: undefined,
    id: 'login-faqs',
    isBreadcrumbsVisible: false,
    isExternal: false,
    isFooterVisible: true,
    isHeaderLogoVisible: true,
    isHeaderVisible: true,
    isLayoutHeaderVisible: true,
    isPageTitleVisible: true,
    isPrintLinkVisible: true,
    isPublic: true,
    isTitleUpperCase: true,
    isVikingNumberVisible: true,
    key: 'login-faqs',
    preventRedirectReturn: false,
    subRoutes: undefined,
    tabs: undefined,
    // TODO: Wire up to Layout properly. We shouldn't be reading from this properly.
    // https://vikingtravel.atlassian.net/browse/TAP-3061
    title: 'TRAVEL ADVISOR ACCOUNT FAQs',
    url: '/login-faqs',
  },
  maintenance: {
    analyticsTitle: undefined,
    auth: {
      offices: OFFICES_ANY_ALL,
    },
    footerOrder: undefined,
    headerLeftOrder: undefined,
    headerRightOrder: undefined,
    id: 'maintenance',
    isBreadcrumbsVisible: false,
    isExternal: false,
    isFooterVisible: false,
    isHeaderLogoVisible: false,
    isHeaderVisible: false,
    isLayoutHeaderVisible: false,
    isPageTitleVisible: false,
    isPrintLinkVisible: false,
    isPublic: true,
    isTitleUpperCase: false,
    isVikingNumberVisible: false,
    key: 'maintenance',
    preventRedirectReturn: true,
    subRoutes: undefined,
    tabs: undefined,
    title: 'System Maintenance',
    url: '/maintenance',
  },
  manageAgency: {
    analyticsTitle: undefined,
    auth: {
      offices: OFFICES_ALL_KNOWN,
    },
    footerOrder: undefined,
    headerLeftOrder: 2,
    headerRightOrder: undefined,
    id: 'manageAgency',
    isBreadcrumbsVisible: true,
    isExternal: false,
    isFooterVisible: true,
    isHeaderLogoVisible: true,
    isHeaderVisible: true,
    isLayoutHeaderVisible: true,
    isPageTitleVisible: true,
    isPrintLinkVisible: true,
    isPublic: false,
    isTitleUpperCase: true,
    isVikingNumberVisible: false,
    key: 'manageAgency',
    preventRedirectReturn: true,
    subRoutes: undefined,
    tabs: undefined,
    title: 'Manage My Agency',
    url: '/manage-agency',
  },
  marketing: {
    analyticsTitle: undefined,
    auth: {
      offices: [OFFICES.AU, OFFICES.CA, OFFICES.US],
    },
    footerOrder: undefined,
    headerLeftOrder: 3,
    headerRightOrder: undefined,
    id: 'marketing',
    isBreadcrumbsVisible: true,
    isExternal: true,
    isFooterVisible: true,
    isHeaderLogoVisible: true,
    isHeaderVisible: true,
    isLayoutHeaderVisible: true,
    isPageTitleVisible: true,
    isPrintLinkVisible: true,
    isPublic: false,
    isTitleUpperCase: true,
    isVikingNumberVisible: false,
    key: 'marketing',
    preventRedirectReturn: true,
    subRoutes: undefined,
    tabs: undefined,
    title: 'Marketing Center',
    url: '[API_PATH]/agent/marketingcenter',
  },
  notFound: {
    analyticsTitle: undefined,
    auth: {
      offices: OFFICES_ANY_ALL,
    },
    footerOrder: undefined,
    headerLeftOrder: undefined,
    headerRightOrder: undefined,
    id: 'not-found',
    isBreadcrumbsVisible: false,
    isExternal: false,
    isFooterVisible: true,
    isHeaderLogoVisible: true,
    isHeaderVisible: true,
    isLayoutHeaderVisible: true,
    isPageTitleVisible: true,
    isPrintLinkVisible: true,
    isPublic: true,
    isTitleUpperCase: true,
    isVikingNumberVisible: false,
    key: 'not-found',
    preventRedirectReturn: true,
    subRoutes: undefined,
    tabs: undefined,
    title: 'Page Not Found',
    url: '/page-not-found',
  },
  offers: {
    analyticsTitle: undefined,
    auth: {
      offices: OFFICES_ANY_ALL,
    },
    footerOrder: undefined,
    headerLeftOrder: undefined,
    headerRightOrder: undefined,
    id: 'offers',
    isBreadcrumbsVisible: false,
    isExternal: false,
    isFooterVisible: true,
    isHeaderLogoVisible: true,
    isHeaderVisible: true,
    isLayoutHeaderVisible: true,
    isPageTitleVisible: false,
    isPrintLinkVisible: false,
    isPublic: true,
    isTitleUpperCase: true,
    isVikingNumberVisible: false,
    key: 'offers',
    preventRedirectReturn: false,
    subRoutes: undefined,
    tabs: undefined,
    title: '',
    url: '/offers',
  },
  pageError: {
    analyticsTitle: undefined,
    auth: {
      offices: OFFICES_ANY_ALL,
    },
    footerOrder: undefined,
    headerLeftOrder: undefined,
    headerRightOrder: undefined,
    id: 'page-error',
    isBreadcrumbsVisible: false,
    isExternal: false,
    isFooterVisible: true,
    isHeaderLogoVisible: true,
    isHeaderVisible: true,
    isLayoutHeaderVisible: true,
    isPageTitleVisible: true,
    isPrintLinkVisible: true,
    isPublic: true,
    isTitleUpperCase: true,
    isVikingNumberVisible: true,
    key: 'page-error',
    preventRedirectReturn: true,
    subRoutes: undefined,
    tabs: undefined,
    title: 'Page Not Available',
    url: '/page-error',
  },
  platinumClub: {
    analyticsTitle: undefined,
    auth: {
      offices: [OFFICES.AU],
    },
    footerOrder: undefined,
    headerLeftOrder: 10,
    headerRightOrder: undefined,
    id: 'platinum-club',
    isBreadcrumbsVisible: true,
    isExternal: false,
    isFooterVisible: true,
    isHeaderLogoVisible: true,
    isHeaderVisible: true,
    isLayoutHeaderVisible: true,
    isPageTitleVisible: true,
    isPrintLinkVisible: true,
    isPublic: false,
    isTitleUpperCase: true,
    isVikingNumberVisible: false,
    key: 'platinum-club',
    preventRedirectReturn: false,
    subRoutes: undefined,
    tabs: undefined,
    title: 'Viking Platinum Club',
    url: '/platinum-club',
  },
  privacy: {
    analyticsTitle: undefined,
    auth: {
      offices: OFFICES_ALL_KNOWN,
    },
    footerOrder: 3,
    headerLeftOrder: undefined,
    headerRightOrder: undefined,
    id: 'privacy',
    isBreadcrumbsVisible: true,
    isExternal: false,
    isFooterVisible: true,
    isHeaderLogoVisible: true,
    isHeaderVisible: true,
    isLayoutHeaderVisible: true,
    isPageTitleVisible: true,
    isPrintLinkVisible: true,
    isPublic: false,
    isTitleUpperCase: true,
    isVikingNumberVisible: false,
    key: 'privacy',
    preventRedirectReturn: false,
    subRoutes: undefined,
    tabs: undefined,
    title: 'Privacy Policy',
    url: '/privacy',
  },
  resources: {
    analyticsTitle: undefined,
    auth: {
      offices: [OFFICES.AU, OFFICES.CA, OFFICES.US],
    },
    footerOrder: undefined,
    headerLeftOrder: 5,
    headerRightOrder: undefined,
    id: 'resources',
    isBreadcrumbsVisible: true,
    isExternal: false,
    isFooterVisible: true,
    isHeaderLogoVisible: true,
    isHeaderVisible: true,
    isLayoutHeaderVisible: true,
    isPageTitleVisible: true,
    isPrintLinkVisible: true,
    isPublic: false,
    isTitleUpperCase: true,
    isVikingNumberVisible: false,
    key: 'resources',
    preventRedirectReturn: false,
    subRoutes: {
      contact: 'contact',
      faq: 'faqs',
      training: 'training',
      whySellViking: 'why-sell-viking',
    },
    tabs: undefined,
    title: 'Resources',
    url: '/resources',
  },
  rewards: {
    analyticsTitle: undefined,
    auth: {
      offices: [OFFICES.AU, OFFICES.CA, OFFICES.US],
    },
    footerOrder: undefined,
    headerLeftOrder: 7,
    headerRightOrder: undefined,
    id: 'rewards',
    isBreadcrumbsVisible: true,
    isExternal: false,
    isFooterVisible: true,
    isHeaderLogoVisible: true,
    isHeaderVisible: true,
    isLayoutHeaderVisible: true,
    isPageTitleVisible: true,
    isPrintLinkVisible: true,
    isPublic: false,
    isTitleUpperCase: true,
    isVikingNumberVisible: false,
    key: 'rewards',
    preventRedirectReturn: false,
    subRoutes: undefined,
    tabs: undefined,
    title: 'Viking Rewards',
    url: '/rewards',
  },
  rewardsExternal: {
    analyticsTitle: undefined,
    auth: {
      offices: [OFFICES.AU, OFFICES.CA, OFFICES.US],
    },
    footerOrder: undefined,
    headerLeftOrder: undefined,
    headerRightOrder: undefined,
    id: 'rewards-external',
    isBreadcrumbsVisible: true,
    isExternal: true,
    isFooterVisible: true,
    isHeaderLogoVisible: true,
    isHeaderVisible: true,
    isLayoutHeaderVisible: true,
    isPageTitleVisible: true,
    isPrintLinkVisible: false,
    isPublic: false,
    isTitleUpperCase: true,
    isVikingNumberVisible: false,
    key: 'rewards-external',
    preventRedirectReturn: true,
    subRoutes: undefined,
    tabs: undefined,
    title: 'Viking Rewards',
    url: '[API_PATH]/agent/rewards',
  },
  signedOut: {
    analyticsTitle: undefined,
    auth: {
      offices: OFFICES_ANY_ALL,
    },
    footerOrder: undefined,
    headerLeftOrder: undefined,
    headerRightOrder: undefined,
    id: 'signed-out',
    isBreadcrumbsVisible: false,
    isExternal: false,
    isFooterVisible: true,
    isHeaderLogoVisible: true,
    isHeaderVisible: true,
    isLayoutHeaderVisible: true,
    isPageTitleVisible: true,
    isPrintLinkVisible: false,
    isPublic: true,
    isTitleUpperCase: true,
    isVikingNumberVisible: false,
    key: 'signed-out',
    preventRedirectReturn: true,
    subRoutes: undefined,
    tabs: undefined,
    title: 'Signed Out',
    url: '/signed-out',
  },
  siteMap: {
    analyticsTitle: undefined,
    auth: {
      offices: OFFICES_ALL_KNOWN,
    },
    footerOrder: undefined,
    headerLeftOrder: undefined,
    headerRightOrder: undefined,
    id: 'site-map',
    isBreadcrumbsVisible: true,
    isExternal: false,
    isFooterVisible: true,
    isHeaderLogoVisible: true,
    isHeaderVisible: true,
    isLayoutHeaderVisible: true,
    isPageTitleVisible: true,
    isPrintLinkVisible: true,
    isPublic: false,
    isTitleUpperCase: true,
    isVikingNumberVisible: false,
    key: 'site-map',
    preventRedirectReturn: false,
    subRoutes: undefined,
    tabs: undefined,
    title: 'Site Map',
    url: '/site-map',
  },
  training: {
    analyticsTitle: undefined,
    auth: {
      offices: [OFFICES.AU, OFFICES.CA, OFFICES.US],
    },
    footerOrder: undefined,
    headerLeftOrder: undefined,
    headerRightOrder: undefined,
    id: 'training',
    isBreadcrumbsVisible: true,
    isExternal: false,
    isFooterVisible: true,
    isHeaderLogoVisible: true,
    isHeaderVisible: true,
    isLayoutHeaderVisible: true,
    isPageTitleVisible: true,
    isPrintLinkVisible: true,
    isPublic: false,
    isTitleUpperCase: true,
    isVikingNumberVisible: false,
    key: 'training-webinars',
    preventRedirectReturn: false,
    subRoutes: undefined,
    tabs: undefined,
    title: 'Training Materials, Webinars & TA Rates',
    url: '/resources/training',
  },
  version: {
    analyticsTitle: undefined,
    auth: {
      offices: OFFICES_ANY_ALL,
    },
    footerOrder: undefined,
    headerLeftOrder: undefined,
    headerRightOrder: undefined,
    id: 'version',
    isBreadcrumbsVisible: true,
    isExternal: false,
    isFooterVisible: false,
    isHeaderLogoVisible: true,
    isHeaderVisible: false,
    isLayoutHeaderVisible: false,
    isPageTitleVisible: true,
    isPrintLinkVisible: false,
    isPublic: true,
    isTitleUpperCase: true,
    isVikingNumberVisible: false,
    key: 'version',
    preventRedirectReturn: false,
    subRoutes: undefined,
    tabs: undefined,
    title: 'Version',
    url: '/version',
  },
  website: {
    analyticsTitle: undefined,
    auth: {
      offices: [OFFICES.CA, OFFICES.US],
    },
    footerOrder: undefined,
    headerLeftOrder: 6,
    headerRightOrder: undefined,
    id: 'website',
    isBreadcrumbsVisible: true,
    isExternal: false,
    isFooterVisible: true,
    isHeaderLogoVisible: true,
    isHeaderVisible: true,
    isLayoutHeaderVisible: true,
    isPageTitleVisible: true,
    isPrintLinkVisible: true,
    isPublic: false,
    isTitleUpperCase: true,
    isVikingNumberVisible: false,
    key: 'website',
    preventRedirectReturn: false,
    subRoutes: {
      confirmed: 'websiteConfirmed',
    },
    tabs: undefined,
    title: 'My Co-Branded Website',
    url: '/website',
  },
  websiteConfirmed: {
    analyticsTitle: undefined,
    auth: {
      offices: [OFFICES.CA, OFFICES.US],
    },
    footerOrder: undefined,
    headerLeftOrder: undefined,
    headerRightOrder: undefined,
    id: 'website-confirmed',
    isBreadcrumbsVisible: true,
    isExternal: false,
    isFooterVisible: true,
    isHeaderLogoVisible: true,
    isHeaderVisible: true,
    isLayoutHeaderVisible: true,
    isPageTitleVisible: true,
    isPrintLinkVisible: true,
    isPublic: false,
    isTitleUpperCase: true,
    isVikingNumberVisible: false,
    key: 'website-confirmed',
    preventRedirectReturn: false,
    subRoutes: undefined,
    tabs: undefined,
    title: 'My Co-Branded Website - Thank You',
    url: '/website/confirmed',
  },
  welcome: {
    analyticsTitle: undefined,
    auth: {
      offices: OFFICES_ANY_ALL,
    },
    footerOrder: undefined,
    headerLeftOrder: undefined,
    headerRightOrder: undefined,
    id: 'welcome',
    isBreadcrumbsVisible: false,
    isExternal: false,
    isFooterVisible: true,
    isHeaderLogoVisible: true,
    isHeaderVisible: true,
    isLayoutHeaderVisible: true,
    isPageTitleVisible: false,
    isPrintLinkVisible: false,
    isPublic: true,
    isTitleUpperCase: true,
    isVikingNumberVisible: false,
    key: 'welcome',
    preventRedirectReturn: false,
    subRoutes: undefined,
    tabs: undefined,
    title: 'Welcome',
    url: '/welcome',
  },
  whySellViking: {
    analyticsTitle: undefined,
    auth: {
      offices: [OFFICES.AU, OFFICES.CA, OFFICES.US],
    },
    footerOrder: undefined,
    headerLeftOrder: undefined,
    headerRightOrder: undefined,
    id: 'why-sell-viking',
    isBreadcrumbsVisible: true,
    isExternal: false,
    isFooterVisible: true,
    isHeaderLogoVisible: true,
    isHeaderVisible: true,
    isLayoutHeaderVisible: true,
    isPageTitleVisible: true,
    isPrintLinkVisible: true,
    isPublic: false,
    isTitleUpperCase: true,
    isVikingNumberVisible: false,
    key: 'why-sell-viking',
    preventRedirectReturn: false,
    subRoutes: undefined,
    tabs: undefined,
    title: 'Why Sell Viking',
    url: '/resources/why-sell-viking',
  },
};

const ROUTE_AUTH_KEYS = [ROUTES.manageAgency.key];

const BASE_PATH = '/travel-advisor';
const HOME_BASE = `${BASE_PATH}${ROUTES.home.url}`;
const HOME_KEY = ROUTES.home.key;
const HOME_URLS = [HOME_BASE, `${BASE_PATH}/`, BASE_PATH];

export default ROUTES;

export { BASE_PATH, HOME_BASE, HOME_KEY, HOME_URLS, ROUTE_AUTH_KEYS };
