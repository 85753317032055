import {
  IMAGE_ASPECT_RATIOS,
  IMAGE_ASPECT_RATIOS_NUMERIC,
  IMAGE_FALLBACK,
  IMAGE_FALLBACK_CSS_CLASS,
  IMAGE_HANDLER_BASE_URL,
  IMAGE_NONE,
} from 'constants/IMAGE';
import DOMPurify from 'dompurify';
import { logError } from './logging';
import { BREAKPOINTS } from './responsive';

const brokenImageHandler = (img, fallbackImageSrc) => (evt) => {
  const addErrorClass = () => {
    if (target.className.indexOf(IMAGE_FALLBACK_CSS_CLASS) < 0) {
      target.className += ` ${IMAGE_FALLBACK_CSS_CLASS}`;
    }
  };
  const removeErrorClass = () => {
    target.className = target.className.replace(IMAGE_FALLBACK_CSS_CLASS, '');
  };
  const { target } = evt;
  if (target.srcset) {
    target.srcset = '';
  }
  const errorSrc = IMAGE_FALLBACK;
  removeErrorClass();
  if (target.src.indexOf(IMAGE_HANDLER_BASE_URL) > -1) {
    target.src = img.errorSrc || errorSrc;
    addErrorClass();
  } else if ([img.errorSrc, target.src, fallbackImageSrc].includes(IMAGE_NONE)) {
    target.src = errorSrc;
    target.style.visibility = 'hidden'; // Hide but keep the space
    target.onError = '';
  } else if (target.src !== IMAGE_FALLBACK) {
    target.src = errorSrc;
    addErrorClass();
  } else {
    target.style.display = 'none';
    target.onError = '';
  }
};

const findFirstViableBreakpoint = (imageHandlerPropsByBreakpoint) => {
  const providedBreakpoints = Object.keys(imageHandlerPropsByBreakpoint);
  return Object.values(BREAKPOINTS).find((orderedBreakpoint) =>
    providedBreakpoints.some((providedBreakpoint) => providedBreakpoint === orderedBreakpoint)
  );
};

const getBestFitImage =
  (imgArray = []) =>
  (aspectRatioType) => {
    const best = imgArray.find((img) => img.type === aspectRatioType);
    if (best) {
      return best;
    }

    const desiredAr = IMAGE_ASPECT_RATIOS_NUMERIC[aspectRatioType];
    const imageArray = [];
    (imgArray || []).forEach((img) => {
      const ar = IMAGE_ASPECT_RATIOS_NUMERIC[img.type];
      imageArray.push({ ...img, aspectRatio: ar, diffRatio: Math.abs(desiredAr - ar) });
    });
    const nextBest = (imageArray || []).sort((a, b) => (a?.diffRatio < b?.diffRatio ? -1 : 1))?.[0];
    if (nextBest) {
      return nextBest;
    }
    return imgArray[0];
  };

const convertBreakpointMap = (breakpointToAR) => {
  const ret = {};
  Object.keys(BREAKPOINTS).forEach((key) => {
    if (breakpointToAR[key] && !ret[breakpointToAR[key]]) {
      ret[breakpointToAR[key]] = key;
    }
  });
  return ret;
};

const getNumericAspectRatio = (aspectRatioStrings = []) => {
  const ret = {};
  aspectRatioStrings.forEach((ar) => {
    if (IMAGE_ASPECT_RATIOS_NUMERIC[ar]) {
      ret[ar] = IMAGE_ASPECT_RATIOS_NUMERIC[ar];
    }
  });
  return ret;
};

const getNarrowestAspectRatio = (mediaSet) => {
  const available = (mediaSet || []).map((img) => img?.type);
  const ratios = getNumericAspectRatio(available);
  const narrow = Math.max(...Object.values(ratios));
  return Object.keys(ratios).find((key) => ratios[key] === narrow);
};

const getWidestAspectRatio = (mediaSet) => {
  const available = (mediaSet || []).map((img) => img?.type);
  const ratios = getNumericAspectRatio(available);
  const narrow = Math.min(...Object.values(ratios));
  return Object.keys(ratios).find((key) => ratios[key] === narrow);
};

const getImageHandlerPropsByBreakpoint = ({
  breakpointToAspectRatioMap = {
    xs: IMAGE_ASPECT_RATIOS.WIDE_3x2,
    md: IMAGE_ASPECT_RATIOS.WIDE_2x1,
    lg: IMAGE_ASPECT_RATIOS.WIDE_5x2,
  },
  failQuietly = false,
  includeMissingBreakpoints = true,
  maxRequiredBreakpoint,
  mediaSet = [],
  requireXsStart = false,
}) => {
  let prevAR = getNarrowestAspectRatio(mediaSet);
  const imageHandlerPropsByBreakpoint = Object.values(BREAKPOINTS).reduce((acc, breakpoint) => {
    const mapped = breakpointToAspectRatioMap[breakpoint] || prevAR;
    const bpImage = getBestFitImage(mediaSet)(mapped);
    prevAR = bpImage?.type || prevAR;
    acc[breakpoint] = getImageHandlerProps({ image: bpImage });
    return acc;
  }, {});

  if (includeMissingBreakpoints) {
    const startingBreakpoint = requireXsStart
      ? BREAKPOINTS.xs
      : findFirstViableBreakpoint(imageHandlerPropsByBreakpoint) || BREAKPOINTS.xs;

    if (imageHandlerPropsByBreakpoint[startingBreakpoint] === undefined) {
      if (failQuietly) {
        return {};
      }
      logError(
        `imageUtils - getImageHandlerPropsByBreakpoint: 
        If includeMissingBreakpoints && requireXsStart are true, then an XS breakpoint is required.
        If includeMissingBreakpoints is true && requireXsStart is false, then at least one valid breakpoint is required.
        Consider passing the failQuietly flag to avoid this breaking error.`
      );
      return null;
    }

    const maxRequiredBreakpointHasMatch =
      maxRequiredBreakpoint && !!imageHandlerPropsByBreakpoint[maxRequiredBreakpoint];
    const orderedBreakpointsOfInterest = getOrderedBreakpointsOfInterest({
      startingBreakpoint,
      stoppingBreakpoint: !maxRequiredBreakpointHasMatch ? maxRequiredBreakpoint : null,
    });

    let lastImage = imageHandlerPropsByBreakpoint[startingBreakpoint];
    orderedBreakpointsOfInterest.forEach((breakpoint) => {
      imageHandlerPropsByBreakpoint[breakpoint] = imageHandlerPropsByBreakpoint[breakpoint] || lastImage;
      lastImage = imageHandlerPropsByBreakpoint[breakpoint];
    });
  }

  return imageHandlerPropsByBreakpoint;
};

const getImageHandlerProps = ({ image, fallbackImageSrc }) => {
  try {
    const { alt, id, src, url, ...imageProps } = image || {};
    const imgSrc = url || src || fallbackImageSrc || '';
    const splitIndex = imgSrc.lastIndexOf('/');
    const bucket = imgSrc.substr(0, splitIndex);
    const imageKey = imgSrc.substr(splitIndex + 1);
    const errorSrc = fallbackImageSrc || IMAGE_FALLBACK;
    const formattedAlt = DOMPurify.sanitize(alt, { ALLOWED_TAGS: [] });
    return {
      ...imageProps,
      alt: formattedAlt,
      ariaLabel: formattedAlt,
      baseUrl: IMAGE_HANDLER_BASE_URL,
      brokenImageHandler: brokenImageHandler({ errorSrc }, fallbackImageSrc || imgSrc),
      bucket,
      errorSrc,
      id: id || imageKey,
      imageKey,
      isResponsive: true,
      src: imgSrc?.toLowerCase()?.endsWith('.svg') ? imgSrc : '',
    };
  } catch (error) {
    logError('imageUtils - getImageHandlerProps', error);
    return undefined;
  }
};

const getOrderedBreakpointsOfInterest = ({ startingBreakpoint, stoppingBreakpoint }) => {
  const orderedBreakpoints = Object.values(BREAKPOINTS);
  const startingIndex = orderedBreakpoints.indexOf(startingBreakpoint);
  const endingIndex = !!stoppingBreakpoint && orderedBreakpoints.indexOf(stoppingBreakpoint);
  return endingIndex && endingIndex > startingIndex
    ? orderedBreakpoints.slice(startingIndex, endingIndex)
    : orderedBreakpoints.slice(startingIndex);
};

const onImageErrorSvg = (event) => {
  const { target } = event;
  if (target.src !== IMAGE_FALLBACK) {
    target.src = IMAGE_FALLBACK;
    if (target.className.indexOf('error-img') < 0) {
      target.className += ' error-img';
    }
  }
};

export {
  convertBreakpointMap,
  getBestFitImage,
  getImageHandlerProps,
  getImageHandlerPropsByBreakpoint,
  getNarrowestAspectRatio,
  getWidestAspectRatio,
  onImageErrorSvg,
};
