import VikingFooter from '@viking-eng/footer';
import { ANALYTICS_EVENT_TYPES, ANALYTICS_LINK_TYPES, triggerLinkEvent } from 'analytics/Analytics';
import FooterPrivacyModal from 'common/modal/FooterPrivacyModal';
import { CMS_PATHS } from 'constants/API';
import { OFFICE_UNKNOWN, OFFICES } from 'constants/COUNTRIES';
import ROUTES from 'constants/ROUTES';
import useCmsState from 'hooks/useCmsState';
import useNavigate from 'hooks/useNavigate';
import useRoute from 'hooks/useRoute';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getOneTrust, getOneTrustOffice } from 'utils/oneTrust';
import { onNavigate } from 'utils/routing';
import { keyify, removePTags } from 'utils/string';
import './Footer.scss';

const Footer = () => {
  const { country, office } = useSelector(
    (state) => state?.auth?.agency || { country: OFFICES.US, office: OFFICE_UNKNOWN }
  );
  const { common: data } = useCmsState({ cmsPath: CMS_PATHS.pages.common }) || {};
  const route = useRoute();
  const [isPrivacyModalOpen, setIsPrivacyModalOpen] = useState(false);
  const [geoOffice, setGeoOffice] = useState(office);
  const [oneTrust, setOneTrust] = useState();
  useEffect(() => {
    const getGeoloc = async () => {
      const [otOfficeResponse, otResponse] = await Promise.all([getOneTrustOffice(), getOneTrust()]);
      setGeoOffice(otOfficeResponse);
      setOneTrust(otResponse);
    };

    getGeoloc();
  }, []);

  const navigate = useNavigate();
  const footerStrings = data?.strings?.footer;
  let vikingPhoneNumber = '';

  if (route?.isVikingNumberVisible) {
    vikingPhoneNumber = data?.props?.countries?.[geoOffice]?.phoneNumber || data?.props?.countries?.US?.phoneNumber;
  } else if (!route.isPublic) {
    vikingPhoneNumber = data?.props?.countries?.[office]?.phoneNumber;
  }

  const manageCookiesName = 'manage-cookies';
  const oneTrustLinkClasses = {
    [manageCookiesName]: 'ot-sdk-show-settings',
    cookie_policy: 'ot-sdk-cookie-policy',
  };
  const { items: footerItems, copyrightText } = data?.cms?.footers?.[0]?.footer || {};
  const leftMenuItems =
    footerItems?.map((footerRoute) => {
      let routeOnClick = () =>
        onNavigate({ navigate, url: footerRoute.contentUrl, target: footerRoute?.newBrowserTab ? '_blank' : '' });

      switch (footerRoute.name) {
        case ROUTES.privacy.key:
          routeOnClick = () => setIsPrivacyModalOpen(true);
          break;
        case manageCookiesName:
          routeOnClick = async () => {
            oneTrust?.ToggleInfoDisplay();
          };
          break;
        default:
          break;
      }
      return {
        ...footerRoute,
        text: footerRoute.altText,
        title: footerRoute.altText,
        className: oneTrustLinkClasses[footerRoute.name] || '',
        onClick: () => {
          triggerLinkEvent({
            country,
            event: ANALYTICS_EVENT_TYPES.FOOTER_CLICK,
            footer_link_name: keyify(footerRoute.name),
            linkId: `footer_link_${footerRoute.name}`,
            linkText: footerRoute.altText,
            linkType: ANALYTICS_LINK_TYPES.LINK,
          });
          if (routeOnClick) {
            routeOnClick();
          }
        },
      };
    }) || [];

  useEffect(() => {
    (async () => {
      let isSubscribed = true;
      if (isSubscribed) {
        oneTrust?.initializeCookiePolicyHtml();
      }
      return () => {
        isSubscribed = false;
      };
    })();
  }, [oneTrust]);

  return route?.isFooterVisible || route?.isPublic ? (
    <div className={`layout-footer mt-auto${leftMenuItems.length === 0 ? ' no-links' : ''}`}>
      {vikingPhoneNumber && (
        <div className="viking-number">
          <p>
            {`${footerStrings?.callViking} `}
            <a href={`tel:${vikingPhoneNumber}`}>{vikingPhoneNumber}</a>
          </p>
        </div>
      )}
      <VikingFooter
        copyrightText={copyrightText?.replace(/\$\{YEAR\}/g, new Date().getFullYear())}
        handleModalOpen={() => undefined}
        leftMenuItems={leftMenuItems}
      />
      <FooterPrivacyModal
        commonLabels={data?.strings?.labels}
        footerPrivacyModalData={data?.cms?.privacyPolicy?.sections}
        isPrivacyModalOpen={isPrivacyModalOpen}
        onClosePrivacyModal={() => setIsPrivacyModalOpen(false)}
        title={removePTags(data?.cms?.privacyPolicy?.header || 'Privacy Policy')}
      />
    </div>
  ) : null;
};

export default Footer;
