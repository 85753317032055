import { useCreateAgentMutation } from 'app/services/tap/agent';
import { setAuth } from 'app/slices/auth/authSlice';
import { ACCOUNT_STATUS } from 'common/account/ACCOUNT_CONSTANTS';
import { CMS_PATHS } from 'constants/API';
import { COUNTRY_BY_CURRENCY } from 'constants/COUNTRIES';
import useCmsState from 'hooks/useCmsState';
import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { logError, logInfo } from 'utils/logging';
import { stringCompare } from 'utils/string';

const useAccount = () => {
  const auth = useSelector((state) => state.auth);
  const data = useCmsState({ cmsPath: CMS_PATHS.pages.common });
  const [accountStatus, setAccountStatusState] = useState(ACCOUNT_STATUS.gettingAccount);
  const [error, setError] = useState();
  const [createNewAccount] = useCreateAgentMutation();
  const dispatch = useDispatch();

  const setAccountStatus = useCallback(
    (status) => {
      dispatch(setAuth({ accountStatus: status, callingFunction: 'useAccount.js - setAccountStatus' }));
      setAccountStatusState(status);
    },
    [dispatch]
  );

  const setAccountError = useCallback(
    ({ error: accountError } = {}) => {
      if (accountError) {
        // TODO: Remove once login and spinner issue is more stable
        // eslint-disable-next-line no-console
        console.log('Account Error', JSON.stringify({ accountError, accountStatus }, null, 2));
        logError(accountStatus, accountError);
        setError({ accountStatus, error: accountError });
      } else {
        setError();
      }
    },
    [accountStatus]
  );

  const createAgent = useCallback(async () => {
    try {
      const agentResponse = await createNewAccount({
        body: {
          ...auth.agent,
          country: auth.agent?.country || auth.agency?.mailingInfo?.country || auth.agent?.office,
          agencyNumber: auth.agency.agencyNumber,
        },
        countries: data.common.props.countries,
        email: auth.agent.email,
        tapEmail: auth.agent.tapEmail,
        stateProvinces: data.common.props.stateProvinces,
        token: auth.token,
      });
      if (agentResponse.error) {
        setAccountError({ error: agentResponse.error });
      } else {
        setAccountStatus(ACCOUNT_STATUS.accountFoundWithAgent);
      }
      logInfo('useAccount - createAgent - success');
    } catch (err) {
      logError('useAccount - createAgent', err);
    }
  }, [auth, createNewAccount, data, setAccountError, setAccountStatus]);

  const parseAgentData = useCallback(
    ({ results, isAgency = false }) => {
      try {
        setAccountError();
        if (results.isSuccess && results.data) {
          if (!results.data.errorDescription) {
            if ([ACCOUNT_STATUS.gettingAccount, ACCOUNT_STATUS.accountFoundMissingInfo].includes(accountStatus)) {
              const { agency, agent } = results.data;
              let newAgent = agent;
              if (
                isAgency &&
                !!(agent?.tapEmail || '').trim() &&
                !stringCompare(agent?.tapEmail, auth?.agent?.tapEmail)
              ) {
                // This may not be the right person. They may just share a primary email so blank some details.
                newAgent = getBlankedAgent(agent, auth?.agent?.tapEmail);
              }

              dispatch(
                setAuth({
                  agent: {
                    ...newAgent,
                    email: (newAgent?.email || '').toLowerCase(),
                    country: newAgent?.country || agent?.country || agency?.officeCode,
                    office: agency?.officeCode,
                  },
                  agency: {
                    agencyNumber: agency?.agencyIata,
                    office: agency.officeCode,
                    ...agency,
                    country: COUNTRY_BY_CURRENCY[agency?.currency],
                  },
                  callingFunction: 'useAccount.js - parseAgentData',
                })
              );
              if (accountStatus === ACCOUNT_STATUS.gettingAccount) {
                setAccountStatus(ACCOUNT_STATUS.accountFoundWithAgent);
              }
            }
          } else if (results.data.errorDescription === 'Agent Not Found') {
            setAccountStatus(ACCOUNT_STATUS.accountFoundMissingInfo);
          } else {
            setAccountError({ error: results.data.errorDescription });
          }
        } else if (results.isError) {
          if ([404, 409].includes(results?.error?.originalStatus || results?.error?.status)) {
            setAccountStatus(ACCOUNT_STATUS.accountFoundMissingInfo);
          } else {
            setAccountError({ error: results.error });
          }
          logInfo('useAccount - parseAgentData - success');
        }
      } catch (err) {
        logError('useAccount - parseAgentData', err);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [accountStatus, dispatch, setAccountError]
  );

  const getBlankedAgent = (agentObject, newEmail) => ({
    ...agentObject,
    email: newEmail,
    tapEmail: newEmail,
    academyPID: '',
    address1: '',
    address2: '',
    address3: '',
    agentCode: '',
    agentRelationship: '',
    agentRole: '',
    agentRoleToken: '',
    city: '',
    country: '',
    middleName: '',
    phone: '',
    phoneMobile: '',
    secondaryPID: '',
    state: '',
    isNewAgent: true,
    zip: '',
  });

  return { accountStatus, createAgent, error, parseAgentData, setAccountError };
};

export default useAccount;
