// IMPORTANT: Any changes to RequireAuth elements must also be reflected in constants/ROUTES.js
import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import AccountContainer from 'common/account/AccountContainer';
import AuthContainer from 'common/auth/AuthContainer';
import Login from 'common/auth/Login';
import { withCmsData, withOptionalCmsData } from 'common/auth/RouterElement';
import HistoryProvider from 'common/history/History';
import AuthRoute from 'common/layout/AuthRoute';
import Layout from 'common/layout/Layout';
import { CMS_PATHS } from 'constants/API';
import { OFFICE_UNKNOWN } from 'constants/COUNTRIES';
import ROUTES, { BASE_PATH } from 'constants/ROUTES';
import Home from 'pages/home/Home';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import lazyWithReload from 'utils/lazyWithReload';
import './App.scss';

const Account = lazyWithReload(() => import('pages/account/Account'));
const AgencyRegistration = lazyWithReload(() => import('pages/agencyRegistration/AgencyRegistration'));
const AgentFaq = lazyWithReload(() => import('pages/agentFaq/AgentFaq'));
const BestBusinessPartner = lazyWithReload(() => import('pages/bestBusinessPartner/BestBusinessPartner'));
const BookingCreate = lazyWithReload(() => import('pages/bookingCreate/BookingCreate'));
const Bookings = lazyWithReload(() => import('pages/bookings/Bookings'));
const BookingsPersonal = lazyWithReload(() => import('pages/bookingsPersonal/BookingsPersonal'));
const Contact = lazyWithReload(() => import('pages/contact/Contact'));
const FormsGuides = lazyWithReload(() => import('pages/formsGuides/FormsGuides'));
const Groups = lazyWithReload(() => import('pages/groups/Groups'));
const Itinerary = lazyWithReload(() => import('pages/itinerary/Itinerary'));
const LoginFaqs = lazyWithReload(() => import('pages/loginFaqs/LoginFaqs'));
const Maintenance = lazyWithReload(() => import('pages/maintenance/Maintenance'));
const ManageAgency = lazyWithReload(() => import('pages/manageAgency/ManageAgency'));
const NotFound = lazyWithReload(() => import('pages/notFound/NotFound'));
const Offers = lazyWithReload(() => import('pages/offers/Offers'));
const PageError = lazyWithReload(() => import('pages/pageError/PageError'));
const PlatinumClub = lazyWithReload(() => import('pages/platinumClub/PlatinumClub'));
const Resources = lazyWithReload(() => import('pages/resources/Resources'));
const Rewards = lazyWithReload(() => import('pages/rewards/Rewards'));
const SignedOut = lazyWithReload(() => import('pages/account/SignedOut'));
const SiteMap = lazyWithReload(() => import('pages/siteMap/SiteMap'));
const Training = lazyWithReload(() => import('pages/training/Training'));
const Version = lazyWithReload(() => import('pages/version/Version'));
const Website = lazyWithReload(() => import('pages/website/Website'));
const WebsiteConfirmed = lazyWithReload(() => import('pages/website/WebsiteConfirmed'));
const Welcome = lazyWithReload(() => import('pages/welcome/Welcome'));
const WhySellViking = lazyWithReload(() => import('pages/whySellViking/WhySellViking'));

const App = ({ store }) => {
  const dispatch = useDispatch();

  const { agency } = store?.getState()?.auth || {};
  const cmsOffice = agency?.cmsOffice || OFFICE_UNKNOWN;

  const CONTENT_OPTIONAL_PRIVATE_ROUTES = [
    { Child: Itinerary, routePath: ROUTES.itinerary.url },
    { Child: SiteMap, routePath: ROUTES.siteMap.url },
  ].map((route) => withOptionalCmsData({ dispatch, office: cmsOffice, route }));

  const CONTENT_OPTIONAL_PUBLIC_ROUTES = [
    { Child: Maintenance, routePath: ROUTES.maintenance.url },
    { Child: NotFound, routePath: ROUTES.notFound.url },
    { Child: PageError, cmsPaths: [CMS_PATHS.pages.pageError], routePath: ROUTES.pageError.url },
    { Child: SignedOut, routePath: ROUTES.signedOut.url },
    { Child: Version, routePath: ROUTES.version.url },
  ].map((route) => withOptionalCmsData({ dispatch, office: cmsOffice, route }));

  const CONTENT_REQUIRED_PRIVATE_ROUTES = [
    { Child: Account, cmsPaths: [CMS_PATHS.pages.account], routePath: ROUTES.account.url },
    { Child: AgentFaq, cmsPaths: [CMS_PATHS.faq, CMS_PATHS.pages.faq], routePath: ROUTES.faq.url },
    { Child: BookingCreate, cmsPaths: [CMS_PATHS.pages.bookingCreate], routePath: ROUTES.bookingCreate.url },
    { Child: Bookings, cmsPaths: [CMS_PATHS.pages.bookings], routePath: `${ROUTES.bookings.url}/:status?/:search?` },
    {
      Child: BookingsPersonal,
      cmsPaths: [CMS_PATHS.pages.bookingPersonal],
      routePath: ROUTES.bookingPersonal.url,
      shouldRevalidate: true,
    },
    { Child: Contact, cmsPaths: [CMS_PATHS.contacts, CMS_PATHS.pages.contact], routePath: ROUTES.contact.url },
    { Child: FormsGuides, cmsPaths: [CMS_PATHS.pages.formsGuides], routePath: ROUTES.formsGuides.url },
    { Child: Groups, cmsPaths: [CMS_PATHS.pages.groups], routePath: ROUTES.groups.url },
    { Child: Home, cmsPaths: [CMS_PATHS.pages.home], routePath: '/' },
    { Child: Home, cmsPaths: [CMS_PATHS.pages.home], routePath: ROUTES.home.url },
    { Child: ManageAgency, cmsPaths: [CMS_PATHS.pages.manageAgency], routePath: `${ROUTES.manageAgency.url}/:tab?` },
    { Child: PlatinumClub, cmsPaths: [CMS_PATHS.pages.platinumClub], routePath: ROUTES.platinumClub.url },
    { Child: Resources, cmsPaths: [CMS_PATHS.pages.resources], routePath: ROUTES.resources.url },
    { Child: Rewards, cmsPaths: [CMS_PATHS.pages.rewards], routePath: ROUTES.rewards.url },
    { Child: Training, cmsPaths: [CMS_PATHS.pages.training], routePath: ROUTES.training.url },
    { Child: Website, cmsPaths: [CMS_PATHS.pages.website], routePath: ROUTES.website.url },
    {
      Child: WebsiteConfirmed,
      cmsPaths: [CMS_PATHS.pages.website],
      routePath: `${ROUTES.websiteConfirmed.url}/:uniqueUrl`,
    },
    { Child: WhySellViking, cmsPaths: [CMS_PATHS.pages.whySellViking], routePath: ROUTES.whySellViking.url },
  ].map((route) => withCmsData({ dispatch, office: cmsOffice, route }));

  const CONTENT_REQUIRED_PUBLIC_ROUTES = [
    {
      Child: AgencyRegistration,
      cmsPaths: [CMS_PATHS.pages.agencyRegistration],
      routePath: ROUTES.agencyRegistration.url,
    },
    {
      Child: BestBusinessPartner,
      cmsPaths: [CMS_PATHS.pages.bestBusinessPartner],
      routePath: ROUTES.bestBusinessPartner.url,
    },
    { Child: LoginFaqs, cmsPaths: [CMS_PATHS.faq, CMS_PATHS.pages.loginFaqs], routePath: ROUTES.loginFaqs.url },
    { Child: Offers, cmsPaths: [CMS_PATHS.pages.offers], routePath: ROUTES.offers.url },
    { Child: Welcome, cmsPaths: [CMS_PATHS.pages.welcome], routePath: ROUTES.welcome.url },
  ].map((route) => withCmsData({ dispatch, office: cmsOffice, route }));

  const PRIVATE_ROUTES = [...CONTENT_OPTIONAL_PRIVATE_ROUTES, ...CONTENT_REQUIRED_PRIVATE_ROUTES];

  const PUBLIC_ROUTES = [...CONTENT_OPTIONAL_PUBLIC_ROUTES, ...CONTENT_REQUIRED_PUBLIC_ROUTES];

  const reactRouterOptions = {
    future: {
      v7_fetcherPersist: true,
      v7_normalizeFormMethod: true,
      v7_partialHydration: true,
      v7_relativeSplatPath: true,
      v7_skipActionErrorRevalidation: true,
    },
  };

  const privateRouter = createBrowserRouter(
    [
      {
        basename: BASE_PATH,
        children: [...PRIVATE_ROUTES, ...PUBLIC_ROUTES, { element: <NotFound />, path: '*' }],
        element: (
          <AuthContainer>
            <AccountContainer>
              <HistoryProvider>
                <AuthRoute />
              </HistoryProvider>
            </AccountContainer>
          </AuthContainer>
        ),
        errorElement: <NotFound />,
        path: '/',
      },
      { element: <NotFound />, path: ROUTES.notFound.url },
      { element: <NotFound />, path: '*' },
    ],
    reactRouterOptions
  );

  const publicRouter = createBrowserRouter(
    [
      {
        basename: BASE_PATH,
        children: [...PUBLIC_ROUTES, { element: <Login />, path: '*' }],
        element: (
          <HistoryProvider>
            <Layout isPublic />
          </HistoryProvider>
        ),
        errorElement: <NotFound />,
        path: '/',
      },
    ],
    reactRouterOptions
  );

  return (
    <>
      <AuthenticatedTemplate>
        <RouterProvider future={{ v7_startTransition: true }} router={privateRouter} />
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <RouterProvider future={{ v7_startTransition: true }} router={publicRouter} />
      </UnauthenticatedTemplate>
    </>
  );
};

App.propTypes = {
  store: PropTypes.shape({ getState: PropTypes.func.isRequired }),
};

export default App;
