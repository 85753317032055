import { createSlice } from '@reduxjs/toolkit';
import { DEFAULT_FILTER_SELECTIONS, DEFAULT_VIEW_STATE } from 'constants/MAB';

const initialState = {
  activeOffer: undefined,
  filterSelections: DEFAULT_FILTER_SELECTIONS,
  viewState: DEFAULT_VIEW_STATE,
};

const searchSlice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    clearActiveOffer: (state) => ({
      ...state,
      activeOffer: undefined,
    }),
    resetSearchSlice: () => initialState,
    setActiveOffer: (state, action) => ({
      ...state,
      activeOffer: action.payload,
    }),
    updateFilterSelections: (state, action) => ({
      ...state,
      filterSelections: {
        ...state.filterSelections,
        ...action.payload,
      },
    }),
    updateViewState: (state, action) => ({
      ...state,
      viewState: {
        ...state.viewState,
        ...action.payload,
      },
    }),
  },
});

// Action creators are generated for each case reducer function
export const { clearActiveOffer, resetSearchSlice, setActiveOffer, updateFilterSelections, updateViewState } =
  searchSlice.actions;

export default searchSlice;
