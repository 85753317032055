import { ANALYTICS_EVENT_TYPES, ANALYTICS_LINK_TYPES, triggerLinkEvent } from 'analytics/Analytics';
import { ALL_YEARS_OPTION } from 'constants/BOOKING';
import { ALL_MONTHS } from 'constants/MAB';
import { SPECIAL_OFFER_AVAILABLE } from 'constants/STRINGS';
import _padstart from 'lodash.padstart';
import { addMonths, getDateFromISOString, getMonthCode } from 'utils/dateUtils';
import { keyify, stripOuterHtml } from 'utils/string';

const formatMonthYear = ({ month = '', year = '' }) => {
  const paddedMonth = _padstart(month, 2, '0');
  const key = `${year}${paddedMonth}`;
  return {
    key,
    year,
    month: paddedMonth,
    first: new Date(year, month - 1, 1),
    last: addMonths(new Date(year, month - 1, 1), 1),
  };
};

const getDatesAvailableText = (yearMonths = []) => {
  let datesAvailableText = '';
  yearMonths
    ?.filter((ymm) => ymm.months.length > 0)
    ?.forEach((ymm) => {
      datesAvailableText = `${datesAvailableText}, ${getMonthCode(ymm.months?.[0])}${
        ymm.months.length > 1 ? ` - ${getMonthCode(ymm.months?.[ymm.months.length - 1])}` : ''
      } ${ymm.year}`;
    });
  return datesAvailableText;
};

const getDepartDateFromSailing = (sailing) => {
  const voyageId = typeof sailing === 'string' ? sailing : sailing?.id;
  return sailing?.voyageFromDate
    ? getDateFromISOString(sailing.voyageFromDate)
    : getSailingDetailsFromVoyageId(voyageId)?.getDate();
};

const getInitialIndexValues = ({ yearMonths, initialSelectedDate }) => {
  const yearMonthData = ALL_YEARS_OPTION.concat(yearMonths || [])
    .filter(({ months }) => months.length > 0)
    .reduce((acc, value) => {
      const months = Object.values(ALL_MONTHS).map((month) => ({
        ...month,
        isDisabled: value.year === 1 ? true : !value.months.includes(month.value),
      }));
      acc[value.year] = {
        months,
        value: value.year,
        isDisabled: value.year === 1 ? false : !months.some((mon) => !mon.isDisabled),
      };
      return acc;
    }, {});

  const years = Object.values(yearMonthData);

  const initialYearString = initialSelectedDate.year.toString();
  const selectedDateYearNotDisabledIndex = Object.entries(yearMonthData).findIndex(
    ([key, value]) => key === initialYearString && !value.isDisabled
  );

  const initialYearIndex =
    selectedDateYearNotDisabledIndex !== -1
      ? selectedDateYearNotDisabledIndex
      : years.findIndex((value) => !value.isDisabled);

  let initialMonthIndex = 0;
  if (selectedDateYearNotDisabledIndex === initialYearIndex) {
    const selectedMonthYearNotDisabledIndex = yearMonthData[initialYearString].months.findIndex(
      (month) => month.value === initialSelectedDate.month && !month.isDisabled
    );
    initialMonthIndex =
      selectedMonthYearNotDisabledIndex !== -1
        ? selectedMonthYearNotDisabledIndex
        : yearMonthData[initialYearString].months.findIndex((month) => !month.isDisabled);
  } else {
    initialMonthIndex = years[initialYearIndex].months.findIndex((month) => !month.isDisabled);
  }

  return { initialMonthIndex, initialYearIndex, yearMonthData, years };
};

const getOfferCodeDescription = ({
  description,
  fallbackDescription = SPECIAL_OFFER_AVAILABLE,
  fallbackId = '',
  offerID,
  separator = ':',
}) => `${offerID || fallbackId}${separator} ${stripOuterHtml(description) || fallbackDescription}`;

const getSailingDetailsFromVoyageId = (voyageId) => {
  return (
    voyageId && {
      shipCode: voyageId.slice(0, 3),
      year: `${Number.parseInt(voyageId.slice(3, 5), 10) + 2000}`,
      month: voyageId.slice(5, 7),
      day: voyageId.slice(7, 9),
      getDate() {
        return new Date(this.year, this.month - 1, this.day);
      },
      comboCode: voyageId.slice(9),
    }
  );
};

const getYearMonthsWithVoyages = ({ voyages }) => {
  const allYearMonths = [];
  if (voyages?.length) {
    voyages.reduce((acc, voyage) => {
      if (!acc.includes(voyage.year)) {
        acc?.push(voyage.year);
      }
      return acc;
    }, []);
    const currentYear = new Date().getFullYear();
    const allYears = [];
    for (let i = 0; i < 5; i += 1) {
      allYears.push(currentYear + i);
    }
    allYears?.forEach((year) => {
      const yearMonth = { year, months: [] };
      voyages.forEach((voyage) => {
        if (voyage.year === year) {
          yearMonth.months.push(voyage.month);
        }
      });
      allYearMonths.push(yearMonth);
    });
  }
  return allYearMonths;
};

const getValidOfferCodeContent = (offerCodeContent, offerCode) => {
  const massagedDescription = getOfferCodeDescription({
    ...offerCodeContent,
    fallbackId: offerCode,
  });
  return {
    ...offerCodeContent,
    breadcrumbDescription: massagedDescription.replace(':', ' -'),
    massagedDescription,
  };
};

const sortStateroomCategoryType = (a, b) => {
  const sortedValues = [
    // SUITES
    'SUITE',
    'DELUXE',
    'JR SUITE',
    // VERANDAS/BALCONIES
    'VERANDA',
    'NORDIC BALCONY',
    'FRENCH BALCONY',
    // STANDARDS
    'STANDARD',
    'OCEANVIEW',
  ];
  return sortedValues.indexOf(a) < sortedValues.indexOf(b) ? -1 : 1;
};

const triggerVoyageLinkEvent = ({ voyageName, filterValue, facCategory }) => {
  const linkEventBase = {
    event: ANALYTICS_EVENT_TYPES.DP_FILTER,
    fac_category: facCategory,
    filter_value: filterValue,
  };
  const linkEvent = Object.assign(
    linkEventBase,
    voyageName ? { linkType: ANALYTICS_LINK_TYPES.TEXT, cruise_id: keyify(voyageName), cruise_name: voyageName } : {}
  );
  triggerLinkEvent(linkEvent);
};

const triggerVoyageButtonEvent = ({ ctaName = 'book_now', stateroomCategoryCode, voyageDirection, voyageName }) => {
  const linkEventBase = {
    event: ANALYTICS_EVENT_TYPES.BUTTON_CLICK,
    linkType: stateroomCategoryCode ? ANALYTICS_LINK_TYPES.TEXT : ANALYTICS_LINK_TYPES.BUTTON,
    cta_name: ctaName,
    cruise_id: keyify(voyageName),
    cruise_name: voyageName,
  };
  const linkEvent = Object.assign(
    linkEventBase,
    voyageDirection ? { itinerary_name: voyageDirection, itinerary_id: keyify(voyageDirection) } : {},
    stateroomCategoryCode ? { category_id: stateroomCategoryCode, stateroom_name: stateroomCategoryCode } : {}
  );
  triggerLinkEvent(linkEvent);
};

export {
  formatMonthYear,
  getDatesAvailableText,
  getDepartDateFromSailing,
  getInitialIndexValues,
  getOfferCodeDescription,
  getSailingDetailsFromVoyageId,
  getValidOfferCodeContent,
  getYearMonthsWithVoyages,
  sortStateroomCategoryType,
  triggerVoyageButtonEvent,
  triggerVoyageLinkEvent,
};
