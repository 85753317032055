import PropTypes from 'prop-types';
import { cloneElement } from 'react';
import { Outlet, useLoaderData } from 'react-router-dom';
import { isEmpty } from 'utils/cms';

const WithOptionalCmsData = ({ children }) => {
  const data = useLoaderData();
  const hasData = data && Object.values(data)?.length && !isEmpty(Object.values(data)?.[0]);
  if (children && hasData) {
    return cloneElement(children, { data });
  }
  if (children) {
    return children;
  }
  return <Outlet />;
};

WithOptionalCmsData.propTypes = {
  children: PropTypes.node.isRequired,
};

export default WithOptionalCmsData;
