import { AUTH_AGENCY_MAX_LENGTH } from 'common/auth/AUTH_CONSTANTS';

/* GENERAL VALIDATION */
const normalizeOfferCode = (code = '') =>
  code
    .replace(/[^a-zA-Z0-9]/g, ' ')
    .toUpperCase()
    .trim();

const normalizeWithRegex = (regex) => (inputValue) => (inputValue || '').replace(new RegExp(regex, 'g'), '');

const normalizeNumericMaxLength = (maxLength) => (value) => (value || '').replace(/[\D_]/g, '').substring(0, maxLength);

// ***************************

/* PHONE NUMBER VALIDATION */
// TODO: Drive this from Properties files
const phoneMaskUSCA = (numbersOnly) => {
  if (numbersOnly.length <= 3) {
    return numbersOnly;
  }
  if (numbersOnly.length <= 6) {
    return `${numbersOnly.slice(0, 3)}-${numbersOnly.slice(3)}`;
  }
  if (numbersOnly.length > 6) {
    return `${numbersOnly.slice(0, 3)}-${numbersOnly.slice(3, 6)}-${numbersOnly.slice(6, 15)}`;
  }
  return numbersOnly;
};

const normalizeAgencyNumber = (value) => normalizeNumericMaxLength(AUTH_AGENCY_MAX_LENGTH)(value);

const normalizePhone = (country) => (value) => {
  const COUNTRY_PHONE = {
    AU: { max: 15, min: 8, replace: /[\D_]/g },
    CA: { max: 10, min: 10, replace: /[\D_]/g, mask: phoneMaskUSCA },
    GB: { max: 15, min: 7, replace: /[\D_]/g },
    NZ: { max: 15, min: 8, replace: /[\D_]/g },
    UK: { max: 15, min: 7, replace: /[\D_]/g },
    US: { max: 10, min: 10, replace: /[\D_]/g, mask: phoneMaskUSCA },
  };
  const prefix = value[0] === '+' ? '+' : '';
  const numbersOnly = normalizeNumericMaxLength(COUNTRY_PHONE[country]?.max || 10)(value);
  if (COUNTRY_PHONE[country]?.mask) {
    return COUNTRY_PHONE[country]?.mask(numbersOnly);
  }
  return prefix + numbersOnly;
};
// ***************************

/* ZIP/POST CODE VALIDATION */
// TODO: Drive this from Properties files
const normalizeUSZipCode = (value) => normalizeNumericMaxLength(5)(value);
const upperCase = (value) => value && value.toUpperCase();
const normalizeCAPostalCode = (value) => {
  if (!value) {
    return value;
  }
  const replaceNonAlphaNumeric = upperCase(value.replace(/[\W_]+/g, ''));
  const numbersOnly = /^[\d]/;
  if (!numbersOnly.test(value)) {
    if (replaceNonAlphaNumeric.length <= 3) {
      return replaceNonAlphaNumeric;
    }
    return replaceNonAlphaNumeric.substr(0, 6);
  }
  return '';
};

const normalizeAUPostCode = (value) => normalizeNumericMaxLength(4)(value);

const normalizeNZPostCode = (value) => normalizeNumericMaxLength(4)(value);

const normalizeGBPostalCode = (value) => {
  const postCode = value
    .trimStart()
    .replace(/[^\w ]/g, '')
    .replace(/ {2}/g, ' ')
    .toUpperCase();
  const hasSpace = postCode.split(' ').length - 1 === 2;
  return hasSpace || postCode.length > 5 ? postCode.substr(0, 8).trimEnd() : postCode.substr(0, 8);
};

const COUNTRY_POSTAL_NORMALIZERS = {
  AU: normalizeAUPostCode,
  CA: normalizeCAPostalCode,
  NZ: normalizeNZPostCode,
  GB: normalizeGBPostalCode,
  UK: normalizeGBPostalCode,
  US: normalizeUSZipCode,
};

const normalizePostCode = (countryCode) => (value) => {
  const normalizer = COUNTRY_POSTAL_NORMALIZERS[countryCode || 'US'];
  if (normalizer) {
    return normalizer(value);
  }
  return value;
};
// ***************************

const onBlurTrim = (event, field, setValue) => {
  const newValue = (event.target.value || '').trim();
  if (newValue !== event.target.value) {
    setValue(event.target.name, event.target.value.trim(), {
      shouldDirty: true,
      shouldValidate: true,
      shouldTouch: true,
    });
  }
  field?.onBlur();
};

const onKeyPressRegex = (event, regex) => {
  if (regex && !regex.test(event.key)) {
    event.preventDefault();
  }
};

export {
  normalizeAgencyNumber,
  normalizeNumericMaxLength,
  normalizeOfferCode,
  normalizePhone,
  normalizePostCode,
  normalizeWithRegex,
  onBlurTrim,
  onKeyPressRegex,
};
