import PropTypes from 'prop-types';
import { createContext, useMemo, useState } from 'react';

const LayoutPrintContext = createContext({});

const LayoutPrintProvider = ({ children }) => {
  const [beforePrintHandlers, setBeforePrintHandlers] = useState({});
  const [printConfigs, setPrintConfigs] = useState({});
  const [onPrintStore, setOnPrintStore] = useState({});

  const value = useMemo(
    () => ({
      beforePrintHandlers,
      setBeforePrintHandlers,
      printConfigs,
      setPrintConfigs,
      onPrintStore,
      setOnPrintStore,
    }),
    [beforePrintHandlers, setBeforePrintHandlers, printConfigs, setPrintConfigs, onPrintStore, setOnPrintStore]
  );

  return <LayoutPrintContext.Provider value={value}>{children}</LayoutPrintContext.Provider>;
};

LayoutPrintProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default LayoutPrintProvider;

export { LayoutPrintContext };
