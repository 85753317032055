import { API_PATHS } from 'constants/API';
import tapApi from './api';

const b2cApi = tapApi.injectEndpoints({
  endpoints: (build) => ({
    getValidAgency: build.query({
      queryFn: async ({ agencyIata, agencyNumber, email }, _api, _extraOptions, baseQuery) => {
        const url = API_PATHS.getValidAgency({ agencyIata: agencyIata || agencyNumber, email });
        const response = await baseQuery({
          method: 'GET',
          url,
        });
        return response;
      },
    }),
  }),
});

const { useLazyGetValidAgencyQuery } = b2cApi;

export { useLazyGetValidAgencyQuery };
