import Spinner from '@viking-eng/spinner';
import './LoadingSpinner.scss';

const LoadingSpinner = () => (
  <div className="loading-spinner text-center align-content-center">
    <div>
      <Spinner />
    </div>
  </div>
);

LoadingSpinner.propTypes = {};

export default LoadingSpinner;
