import { lazy } from 'react';

const RELOAD_CHUNK_KEY = 'chunk-load-refresh-triggered';
/**
 * Lets you defer loading a component’s code until it is rendered for the first time, but safer due to a try-catch for chunk errors.
 * The chunk errors might be caused by a deployment or by maintenance mode being enabled.
 * @param {function} lazyComponent A function that returns a Promise or another thenable (a Promise-like object with a then method).
 * React will not call load until the first time you attempt to render the returned component.
 * After React first calls load, it will wait for it to resolve, and then render the resolved value’s .default as a React component.
 * Both the returned Promise and the Promise’s resolved value will be cached, so React will not call load more than once.
 * If the Promise rejects, React will throw the rejection reason for the nearest Error Boundary to handle.
 * @example
 * import lazyWithReload from 'utils/lazyWithReload';
 * const `MyComponent = lazyWithReload(() => import('/path/to/components/MyComponent'))`
 * @returns {component} Returns the lazy loaded component just like react lazy.
 */
const lazyWithReload = (lazyComponent) =>
  lazy(async () => {
    const isRefreshTriggered = JSON.parse(window.localStorage.getItem(RELOAD_CHUNK_KEY)) || null;
    try {
      const component = await lazyComponent();
      window.localStorage.setItem(RELOAD_CHUNK_KEY, 'false');
      return component;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.warn('Error loading component, reloading.  Error: ', error);
      if (!isRefreshTriggered) {
        window.localStorage.setItem(RELOAD_CHUNK_KEY, 'true');
        return window.location.reload(false);
      }
    }
    return null;
  });

export default lazyWithReload;
export { RELOAD_CHUNK_KEY };
