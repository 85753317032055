import PropTypes from 'prop-types';
import { Link as ReactRouterLink } from 'react-router-dom';
import { isLinkExternal, normalizeInternalUrl } from 'utils/routing';

const Link = ({ children, to, ...other }) => {
  return isLinkExternal(to) ? (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <a rel="noopener noreferrer" target="_blank" {...other} href={to}>
      {children}
    </a>
  ) : (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <ReactRouterLink {...other} to={normalizeInternalUrl(to)}>
      {children}
    </ReactRouterLink>
  );
};

Link.propTypes = {
  children: PropTypes.node.isRequired,
  to: PropTypes.string.isRequired,
};

export default Link;
