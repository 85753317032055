// Sync between tap-client and tap-properties whenever updated.
// Ensure Confluence article is up to date when updated.
// Feature Roles and Permissions
// https://vikingtravel.atlassian.net/wiki/spaces/TAP/pages/edit-v2/12705726617

const PERMISSIONS = Object.freeze({
  accountPrimaryEmail: {
    value: 'accountPrimaryEmail',
    description: 'Account - Primary Email',
  },
  manageAgency: { value: 'manageAgency', description: 'Manage My Agency Page' },
  manageAgencyTeam: { value: 'manageAgencyTeam', description: 'Manage My Agency Page - Team Management Tab' },
  manageAgencyTeamAccountsApprove: {
    value: 'manageAgencyTeamAccountsApprove',
    description: 'Manage My Agency Page - Team Management Tab - Approve Accounts',
  },
  // No role assigned yet
  manageAgencyTeamAccountsDeactivate: {
    value: 'manageAgencyTeamAccountsDeactivate',
    description: 'Manage My Agency Page - Team Management Tab - Deactivate Accounts',
  },
  manageAgencyTeamAccountsDeny: {
    value: 'manageAgencyTeamAccountsDeny',
    description: 'Manage My Agency Page - Team Management Tab - Deny Accounts',
  },
  manageAgencyTeamAccountsPrimaryEmail: {
    value: 'manageAgencyTeamAccountsPrimaryEmail',
    description: 'Manage My Agency Page - Team Management Tab - Assign primary e-mails',
  },
  // No roled assigned yet
  manageAgencyTeamAccountsSharedEmail: {
    value: 'manageAgencyTeamAccountsSharedEmail',
    description: 'Manage My Agency Page - Team Management Tab - Assign shared e-mails',
  },
  manageAgencyTeamRolesAdvisor: {
    value: 'manageAgencyTeamRolesAdvisor',
    description: 'Manage My Agency Page - Team Management Tab - Assign Advisor Role',
  },
  manageAgencyTeamRolesManager: {
    value: 'manageAgencyTeamRolesManager',
    description: 'Manage My Agency Page - Team Management Tab - Assigne Manager Role',
  },
  manageAgencyTeamRolesOwner: {
    value: 'manageAgencyTeamRolesOwner',
    description: 'Manage My Agency Page - Team Management Tab - Assign Owner Role',
  },
  manageAgencyBookings: {
    value: 'manageAgencyBookings',
    description: 'Manage My Agency Page - All Agency Bookings Tab',
  },
  manageAgencyBookingsCommission: {
    value: 'manageAgencyBookingsCommission',
    description: 'Manage My Agency Page - All Agency Bookings Tab - Commission',
  },
  manageAgencyBookingsDateFilter: {
    value: 'manageAgencyBookingsDateFilter',
    description: 'Manage My Agency Page - All Agency Bookings Tab - Date Filter',
  },
  manageAgencyBookingsDownload: {
    value: 'manageAgencyBookingsDownload',
    description: 'Manage My Agency Page - All Agency Bookings Tab - Downloads',
  },
  manageAgencyBookingsMultiplePayment: {
    value: 'manageAgencyBookingsMultiplePayment',
    description: 'Manage My Agency Page - All Agency Bookings Tab - Multiple Bookings Payment',
  },
  manageAgencyBookingsRequestInvoiceGuest: {
    value: 'manageAgencyBookingsRequestInvoiceGuest',
    description: 'Manage My Agency Page - All Agency Bookings Tab - Request Invoice (Guest)',
  },
  manageAgencyBookingsRequestInvoiceTA: {
    value: 'manageAgencyBookingsRequestInvoiceTA',
    description: 'Manage My Agency Page - All Agency Bookings Tab - Request Invoice (TA)',
  },
  manageAgencyReporting: { value: 'manageAgencyReporting', description: 'Reporting' },
  bookingsDateFilter: {
    value: 'bookingsDateFilter',
    description: 'Bookings - Date Filter',
  },
  bookingsRequestInvoiceGuest: {
    value: 'bookingsRequestInvoiceGuest',
    description: 'Bookings - Request Invoice Guest',
  },
  bookingsRequestInvoiceTA: {
    value: 'bookingsRequestInvoiceTA',
    description: 'Bookings - Request Invoice TA',
  },
});

const ROLES = Object.freeze({
  AG: { display: 'Advisor', value: 'AG' },
  IN: { display: 'Inactive', value: 'IN' },
  MG: { display: 'Manager', value: 'MG' },
  OW: { display: 'Owner', value: 'OW' },
  PE: { display: 'Pending', value: 'PE' },
});

const VERBS = Object.freeze({
  create: 'create',
  delete: 'delete',
  update: 'update',
  view: 'view',
});

export { PERMISSIONS, ROLES, VERBS };
