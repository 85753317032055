import { ANALYTICS_EVENT_TYPES, ANALYTICS_LINK_TYPES, triggerLinkEvent } from 'analytics/Analytics';
import { CMS_PATHS, CMS_REDIRECT_URL_KEYS } from 'constants/API';
import { IS_LOCAL } from 'constants/ENV';
import ROUTES, { BASE_PATH, HOME_BASE, HOME_URLS } from 'constants/ROUTES';
import _sortBy from 'lodash.sortby';
import { keyify } from './string';

const baseHref = `/${document?.querySelector('base')?.getAttribute('href')?.split('/')[1]}` || '';

const getAuthSortedRoutes = ({
  auth,
  commonData,
  getIsRouteAuthorized,
  getItemsLikeJcrName,
  navigate,
  orderKey,
  resetHistory,
  role,
}) => {
  const routeItems =
    getItemsLikeJcrName({
      items: commonData?.cms?.common?.items,
      jcrName: 'routes-',
      transformName: transformRouteJcrName,
    }) || [];

  const filteredRoutes = _sortBy(Object.values(ROUTES), orderKey)
    .filter((route) => {
      // TODO: This may change based on the outcome of this ticket.
      // If added to CMS, we can likely revert any references to role here
      // and update the routeItems[`routes-${route.key}`] !== undefined || logic
      // https://vikingtravel.atlassian.net/browse/TAP-3707
      if ((role.isOwner || role.isManager) && route.key === 'bookings') {
        return false;
      }
      return (
        route[orderKey] !== undefined &&
        (routeItems[`routes-${route.key}`] !== undefined ||
          ((role.isOwner || role.isManager) && route.key === ROUTES.manageAgency.key)) &&
        getIsRouteAuthorized({ auth, route })
      );
    })
    .reduce((acc, route) => {
      const { title, callToActionUrl } = getRouteCommonData({ route, commonData });
      acc.push({
        ...route,
        isSmallText: false,
        onClick: () => {
          triggerLinkEvent({
            event: ANALYTICS_EVENT_TYPES.MAIN_NAVIGATION,
            main_nav_cat: orderKey === 'headerLeftOrder' ? 'hamburger_menu' : 'account_menu',
            main_nav_sub_cat: keyify(title),
            linkText: keyify(title),
            linkType: ANALYTICS_LINK_TYPES.LINK,
            linkId: `auth-route-${keyify(title)}`,
          });
          if (!route.isExternal) {
            resetHistory();
          }
          if (!isRedirectUrl(callToActionUrl || route.url)) {
            onNavigate({ navigate, url: callToActionUrl || route.url });
          }
        },
        openInNewWindow: route.isExternal,
        text: title || route.title,
        title: title || route.title,
      });
      return acc;
    }, []);

  return filteredRoutes;
};

const getCmsPathForRoute = (route) => Object.values(CMS_PATHS.pages).find((pg) => pg.path === `pages/${route.key}`);

const getIsHome = (url) => (url ? HOME_URLS.includes(url) : true);

const getRedirectStartPage = (pathname = window.location.pathname, searchString = window.location.search) => {
  pathname = pathname.replace(BASE_PATH, '').replace(baseHref, '');
  const exactPathMatch = Object.values(ROUTES).find((route) => route.url === pathname && !route.preventRedirectReturn);
  const route = exactPathMatch ?? getRouteFromPathname(pathname);
  return route && !route.preventRedirectReturn ? `${BASE_PATH}${route.url}${searchString}` : HOME_BASE;
};

const getRouteCommonData = ({ route, commonData }) => {
  const routeData =
    commonData?.cms?.common?.items?.find(
      ({ jcrName }) =>
        transformRouteJcrName(jcrName) === `routes-${route.key}` ||
        transformRouteJcrName(jcrName) === `routes-${route.id}`
    ) || {};
  if (!routeData?.longText) {
    routeData.longText = route.title;
  }
  return routeData;
};

const getRouteFromLink = (link) => {
  link = link.replace(BASE_PATH, '');
  const routeFromLink =
    link === '/' || link === ''
      ? ROUTES.home
      : Object.values(ROUTES).find((route) => {
          return route.url === link;
        });
  return routeFromLink || ROUTES.notFound;
};

const getRouteFromPathname = (pathname = window.location.pathname) => {
  pathname = pathname.replace(BASE_PATH, '');
  const routeFromPathName =
    pathname === '/' || pathname === ''
      ? ROUTES.home
      : Object.values(ROUTES).find((route) => {
          return route.url === `/${pathname.replace(baseHref, '').split('/')[1] || ''}`;
        });
  return routeFromPathName || ROUTES.notFound;
};

const isLinkExternal = (url) => {
  return (
    url.startsWith('mailto:') ||
    url.startsWith('tel:') ||
    (/^https?:\/\//.test(url) && !url.startsWith(`${window.location.origin}${BASE_PATH}`))
  );
};

const isLinkUrl = (url) => !url.startsWith('mailto:') && !url.startsWith('tel:');

const isRedirectUrl = (url) => Object.values(CMS_REDIRECT_URL_KEYS).includes(url);

const normalizeInternalUrl = (url) => {
  const normalizedUrl = url?.startsWith(window.location.origin) ? url.replace(window.location.origin, '') : url;
  return normalizedUrl?.startsWith(BASE_PATH) ? normalizedUrl : `${BASE_PATH}${normalizedUrl}`;
};

const onNavigate = ({
  callback,
  evaluateAuthStatus = false,
  isAuthenticated = false,
  navigate,
  target = '_blank',
  url = '',
}) => {
  if (isLinkExternal(url)) {
    openUrlInTabWithFallback({ callback, target, url });
  } else {
    const route = getRouteFromLink(url);
    const normalizedUrl = normalizeInternalUrl(url);
    if (evaluateAuthStatus && !route?.isPublic && !isAuthenticated) {
      openUrlInTabWithFallback({ callback, target, url: normalizedUrl });
    } else if (navigate) {
      navigate(normalizedUrl);
    } else {
      window.location.href = normalizedUrl;
    }
  }
};

const openUrlInTabWithFallback = ({ callback, target, url }) => {
  const tab = window.open(url, target);
  if (tab === null && !IS_LOCAL) {
    window.location.href = url;
  }
  if (callback) {
    callback();
  }
};

// Note: Route titles and description are mapped from ROUTES.js using the key name to the jcrName in cms.common.items.
// These are using in navigation and document.title.
// Because jcrName cannot be a duplicate, we append a number in CMS, but then remove when mapping.
// TODO: This code is a bit brittle, so we should revisit once we do the "real" CMS integration.
const transformRouteJcrName = (jcrName) => {
  let routeName;
  const nameSplit = jcrName.split('-');
  if (Number.isNaN(Number(nameSplit[nameSplit.length - 1]))) {
    routeName = jcrName;
  } else {
    nameSplit.pop();
    routeName = nameSplit.join('-');
  }
  return routeName;
};

export {
  getAuthSortedRoutes,
  getCmsPathForRoute,
  getIsHome,
  getRedirectStartPage,
  getRouteCommonData,
  getRouteFromLink,
  getRouteFromPathname,
  isLinkExternal,
  isLinkUrl,
  isRedirectUrl,
  normalizeInternalUrl,
  onNavigate,
  openUrlInTabWithFallback,
};
