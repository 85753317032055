import { useLazyGetAgentQuery } from 'app/services/tap/agent';
import { useLazyGetValidAgencyQuery } from 'app/services/tap/b2c';
import { ACCOUNT_STATUS } from 'common/account/ACCOUNT_CONSTANTS';
import PageLoading from 'common/pageLoading/PageLoading';
import useAccount from 'hooks/useAccount';
import MaintenanceWrapper from 'pages/maintenance/MaintenanceWrapper';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { logError, logInfo } from 'utils/logging';

const AccountContainer = ({ children }) => {
  const { accountStatus, error, parseAgentData, setAccountError } = useAccount();
  const auth = useSelector((state) => state.auth);

  const [getAgent, getAgentResults] = useLazyGetAgentQuery();
  const [getValidAgency, getValidAgencyResults] = useLazyGetValidAgencyQuery();

  useEffect(() => {
    setAccountError();
    (async () => {
      try {
        switch (accountStatus) {
          case ACCOUNT_STATUS.gettingAccount:
            if (auth.agency.agencyIata || auth.agency.agencyNumber) {
              getValidAgency({
                agencyIata: auth.agency.agencyIata,
                agencyNumber: auth.agency.agencyNumber,
                email: auth.agent.tapEmail,
              });
            } else {
              getAgent({ email: auth.agent.tapEmail });
            }
            break;
          case ACCOUNT_STATUS.accountFoundMissingInfo:
          default:
            break;
        }
        logInfo('AccountContainer - useEffect1 - success');
      } catch (err) {
        logError('AccountContainer - useEffect1', err);
        setAccountError({ error: err });
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountStatus]);

  useEffect(() => parseAgentData({ results: getAgentResults }), [getAgentResults, parseAgentData]);

  useEffect(
    () => parseAgentData({ results: getValidAgencyResults, isAgency: true }),
    [getValidAgencyResults, parseAgentData]
  );

  if (error !== undefined && error?.accountStatus !== ACCOUNT_STATUS.accountFoundMissingInfo) {
    return <MaintenanceWrapper />;
  }

  return accountStatus !== ACCOUNT_STATUS.gettingAccount ? children : <PageLoading />;
};

AccountContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AccountContainer;
