import useRoute from 'hooks/useRoute';
import { useLayoutEffect } from 'react';

const ScrollReset = () => {
  const route = useRoute();

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [route]);

  return null;
};

export default ScrollReset;
