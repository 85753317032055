import { API_PATHS } from 'constants/API';
import tapApi from './api';

const cmsApi = tapApi.injectEndpoints({
  endpoints: (build) => ({
    getCms: build.query({
      providesTags: (res, err, { cmsPath, office }) => [
        { type: 'CmsData', id: `${cmsPath?.key}${office ? `-${office}` : ''}` },
      ],
      query: async ({ cmsPath, office }) => {
        const path = await API_PATHS.cms({ path: cmsPath.path, office });
        return path;
      },
    }),
  }),
});

const {
  useGetCmsQuery,
  usePrefetch: useGetCmsPrefetch,
  endpoints: { getCms },
} = cmsApi;

const { useQueryState: useGetCmsQueryState } = getCms;

export { getCms, useGetCmsPrefetch, useGetCmsQuery, useGetCmsQueryState };
