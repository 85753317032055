import PropTypes, { arrayOf } from 'prop-types';
import { OFFICES, VOYAGE_TYPES } from './COUNTRIES';
import { IMAGE_ASPECT_RATIOS } from './IMAGE';

const cmsApplication = PropTypes.arrayOf(PropTypes.string);

const cmsImage = PropTypes.shape({
  alt: PropTypes.string,
  caption: PropTypes.string,
  nodeType: PropTypes.string,
  path: PropTypes.string,
  type: PropTypes.oneOf(Object.values(IMAGE_ASPECT_RATIOS)),
  url: PropTypes.string,
});

const cmsMediaSet = PropTypes.arrayOf(cmsImage);

const cmsOfficeCodes = PropTypes.arrayOf(PropTypes.oneOf(Object.values(OFFICES)));

const cmsVoyageTypes = PropTypes.arrayOf(PropTypes.oneOf(Object.values(VOYAGE_TYPES)));

const cmsForms = PropTypes.arrayOf(
  PropTypes.shape({
    jcrName: PropTypes.string,
    sections: arrayOf(
      PropTypes.shape({
        jcrName: PropTypes.string,
      })
    ),
  })
);

const cmsCard = PropTypes.shape({
  application: cmsApplication,
  description: PropTypes.string,
  callToActionUrl: PropTypes.string,
  mediaSet: cmsMediaSet,
  officeCode: cmsOfficeCodes,
  'open-in-new-window': PropTypes.bool,
  subtitle: PropTypes.string,
  title: PropTypes.string,
  voyageType: cmsVoyageTypes,
});

const cmsGroups = PropTypes.arrayOf(
  PropTypes.shape({
    cards: PropTypes.arrayOf(
      PropTypes.shape({
        card: cmsCard,
        jcrName: PropTypes.string,
        nodeType: PropTypes.string,
      })
    ),
    items: PropTypes.arrayOf(
      PropTypes.shape({
        callToActionTitle: PropTypes.string,
        callToActionUrl: PropTypes.string,
        image: cmsImage,
        jcrName: PropTypes.string,
        longText: PropTypes.string,
        reference: PropTypes.string,
        shortText: PropTypes.string,
        subtitle: PropTypes.string,
        title: PropTypes.string,
      })
    ),
    jcrName: PropTypes.string,
    // TODO: No examples as we're not using

    pages: PropTypes.arrayOf(PropTypes.shape({})),
  })
);

const cmsGroupsAlt = PropTypes.arrayOf(
  PropTypes.shape({
    cards: PropTypes.arrayOf(PropTypes.arrayOf(cmsCard)),
    items: PropTypes.arrayOf(
      PropTypes.shape({
        callToActionTitle: PropTypes.string,
        callToActionUrl: PropTypes.string,
        image: cmsImage,
        jcrName: PropTypes.string,
        longText: PropTypes.string,
        reference: PropTypes.string,
        shortText: PropTypes.string,
        subtitle: PropTypes.string,
        title: PropTypes.string,
      })
    ),
    jcrName: PropTypes.string,
    // TODO: No examples as we're not using

    pages: PropTypes.arrayOf(PropTypes.shape({})),
  })
);

const cmsHeader = PropTypes.shape({
  application: cmsApplication,
  // TODO: No examples as we're not using
  // eslint-disable-next-line react/forbid-prop-types
  footer: PropTypes.any,
  header: PropTypes.shape({
    logoURL: PropTypes.arrayOf(cmsImage),
    phoneNumber: PropTypes.string,
    phoneText: PropTypes.string,
    jcrName: PropTypes.string,
    nodeType: PropTypes.string,
    officeCode: cmsOfficeCodes,
    path: PropTypes.string,
    type: PropTypes.string,
    voyageType: cmsVoyageTypes,
  }),
});

const cmsItems = PropTypes.arrayOf(
  PropTypes.shape({
    application: cmsApplication,
    callToActionTitle: PropTypes.string,
    callToActionUrl: PropTypes.string,
    // TODO: No examples as we're not using
    // eslint-disable-next-line react/forbid-prop-types
    image: PropTypes.any,
    jcrName: PropTypes.string,
    longText: PropTypes.string,
    mediaType: PropTypes.string,
    nodeType: PropTypes.string,
    path: PropTypes.string,
    reference: PropTypes.string,
    shortText: PropTypes.string,
    subtitle: PropTypes.string,
    title: PropTypes.string,
    voyageType: cmsVoyageTypes,
  })
);

const propsStrings = PropTypes.shape({
  alerts: PropTypes.objectOf(PropTypes.string),
  errors: PropTypes.objectOf(PropTypes.objectOf(PropTypes.string)),
  honorifics: PropTypes.shape({
    title: PropTypes.arrayOf(PropTypes.string),
  }),
  labels: PropTypes.objectOf(PropTypes.objectOf(PropTypes.string)),
  validation: PropTypes.objectOf(PropTypes.objectOf(PropTypes.string)),
});

export { cmsCard, cmsForms, cmsGroups, cmsGroupsAlt, cmsHeader, cmsItems, propsStrings };
