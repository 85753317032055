import { setIsRedirectModalEnabled, setRedirectPage } from 'app/slices/redirect/redirectSlice';
import { CMS_REDIRECT_URL_KEYS } from 'constants/API';
import PropTypes from 'prop-types';
import { createContext, useMemo, useRef } from 'react';
import { useDispatch } from 'react-redux';
import RedirectLink from './RedirectLink';

const RedirectContext = createContext({});

const RedirectProvider = ({ children }) => {
  const dispatch = useDispatch();
  const academyButtonRef = useRef();
  const marketingButtonRef = useRef();
  const rewardsButtonRef = useRef();

  const value = useMemo(
    () => ({
      onAcademyClick: () => academyButtonRef?.current?.click(),
      onMarketingClick: () => marketingButtonRef?.current?.click(),
      onRewardsClick: () => rewardsButtonRef?.current?.click(),
      onSetRedirectPage: (payload) => dispatch(setRedirectPage(payload)),
      onSetRedirectModalEnabled: (isEnabled) => dispatch(setIsRedirectModalEnabled(isEnabled)),
    }),
    [academyButtonRef, dispatch, marketingButtonRef, rewardsButtonRef]
  );

  return (
    <RedirectContext.Provider value={value}>
      {children}
      <RedirectLink cmsRedirectUrlKey={CMS_REDIRECT_URL_KEYS.academy} isHidden ref={academyButtonRef} />
      <RedirectLink cmsRedirectUrlKey={CMS_REDIRECT_URL_KEYS.marketing} isHidden ref={marketingButtonRef} />
      <RedirectLink cmsRedirectUrlKey={CMS_REDIRECT_URL_KEYS.rewards} isHidden ref={rewardsButtonRef} />
    </RedirectContext.Provider>
  );
};

RedirectProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default RedirectProvider;

export { RedirectContext };
