import { ENV } from './ENV';
import IMAGE_FALLBACK from './IMAGE_FALLBACK';

const IMAGE_ASPECT_RATIOS = Object.freeze({
  TALL_1x4: '1x4',
  TALL_2x5: '2x5',
  TALL_1x2: '1x2',
  TALL_9x16: '9x16',
  TALL_2x3: '2x3',
  TALL_4x5: '4x5',
  SQUARE_1x1: '1x1',
  WIDE_5x4: '5x4',
  WIDE_3x2: '3x2',
  WIDE_16x9: '16x9',
  WIDE_2x1: '2x1',
  WIDE_5x2: '5x2',
  WIDE_4x1: '4x1',
});

const IMAGE_ASPECT_RATIOS_NUMERIC = Object.freeze({
  [IMAGE_ASPECT_RATIOS.TALL_1x4]: 4,
  [IMAGE_ASPECT_RATIOS.TALL_2x5]: 2.5,
  [IMAGE_ASPECT_RATIOS.TALL_1x2]: 2,
  [IMAGE_ASPECT_RATIOS.TALL_9x16]: 1.7777777777777777,
  [IMAGE_ASPECT_RATIOS.TALL_2x3]: 1.5,
  [IMAGE_ASPECT_RATIOS.TALL_4x5]: 1.25,
  [IMAGE_ASPECT_RATIOS.SQUARE_1x1]: 1,
  [IMAGE_ASPECT_RATIOS.WIDE_5x4]: 0.8,
  [IMAGE_ASPECT_RATIOS.WIDE_3x2]: 0.6666666666666666,
  [IMAGE_ASPECT_RATIOS.WIDE_16x9]: 0.5625,
  [IMAGE_ASPECT_RATIOS.WIDE_2x1]: 0.5,
  [IMAGE_ASPECT_RATIOS.WIDE_5x2]: 0.4,
  [IMAGE_ASPECT_RATIOS.WIDE_4x1]: 0.25,
});

const IMAGE_CMS_ASSET_BASE_URL = 'https://aem-prod-publish.viking.com/content/dam/vikingcruises/en/magnolia-images';
const IMAGE_FALLBACK_CSS_CLASS = 'img-error';
const IMAGE_FALLBACK_SRC_1X1 = './viking_img_error_1x1.svg';
const IMAGE_HANDLER_BASE_URL = ENV.VITE_APP_IMAGE_HANDLER_BASE_URL;
const IMAGE_NONE = '/no-image.jpg';
const IMAGE_VIKING_LOGO_COLOR = `${IMAGE_CMS_ASSET_BASE_URL}/logo/VIKING_COLOR.svg`;
const IMAGE_VIKING_LOGO_PLAIN = `${IMAGE_CMS_ASSET_BASE_URL}/site_elements/vikinglogo.svg`;
const IMAGE_VIKING_LOGO_WHITE = `${IMAGE_CMS_ASSET_BASE_URL}/site_elements/vikinglogowhite.svg`;
const IMAGE_VIKING_LOGO_WHITE_HORIZONTAL = `${IMAGE_CMS_ASSET_BASE_URL}/site_elements/VIKING_WHITE_Horiz.svg`;

export {
  IMAGE_ASPECT_RATIOS,
  IMAGE_ASPECT_RATIOS_NUMERIC,
  IMAGE_CMS_ASSET_BASE_URL,
  IMAGE_FALLBACK,
  IMAGE_FALLBACK_CSS_CLASS,
  IMAGE_FALLBACK_SRC_1X1,
  IMAGE_HANDLER_BASE_URL,
  IMAGE_NONE,
  IMAGE_VIKING_LOGO_COLOR,
  IMAGE_VIKING_LOGO_PLAIN,
  IMAGE_VIKING_LOGO_WHITE,
  IMAGE_VIKING_LOGO_WHITE_HORIZONTAL,
};
