import { BASE_PATH } from 'constants/ROUTES';
import useRoute from 'hooks/useRoute';
import PropTypes from 'prop-types';
import { useEffect } from 'react';

const MaintenanceCheck = ({ children }) => {
  const route = useRoute();
  const { pathname } = window.location;

  useEffect(() => {
    const checkForMaintenanceMode = async () => {
      const maintStatusPath = `${window.location.origin}${BASE_PATH}/maintenance/status`;
      const maintenanceResponse = await fetch(maintStatusPath);
      let response = { isMaintenanceOrigin: false };
      try {
        response = JSON.parse(await maintenanceResponse.text());
      } catch {
        // continue
      }
      if ([true, 'true'].includes(response?.isMaintenanceOrigin)) {
        window.location.replace(`${window.location.origin}${BASE_PATH}/maintenance`);
      }
    };

    checkForMaintenanceMode();
  }, [route, pathname]);

  return children;
};

MaintenanceCheck.propTypes = {
  children: PropTypes.node,
};

export default MaintenanceCheck;
