import { STORAGE_KEYS } from 'constants/CONFIG';
import { HOME_BASE } from 'constants/ROUTES';
import useLocalStorage, { STORAGE_TYPES } from 'hooks/useLocalStorage';
import PropTypes from 'prop-types';
import { createContext, useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { getIsHome } from 'utils/routing';

const HistoryContext = createContext({});

const HistoryProvider = ({ children }) => {
  const { pathname: currentLocationPath = '' } = useLocation() || {};

  const initialHxValues = useMemo(
    () => ({
      hasHistory: false,
      isLastHome: getIsHome(currentLocationPath),
      lastPathName: currentLocationPath,
      list: [currentLocationPath],
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const [history, setHistory] = useLocalStorage({
    key: STORAGE_KEYS.history,
    initialValue: initialHxValues,
    storageType: STORAGE_TYPES.sessionStorage,
  });

  useEffect(() => {
    const lastLocationPath = history.list[history.list.length - 1];
    const isRefreshed = currentLocationPath === lastLocationPath;
    if (!isRefreshed) {
      let updatedList = [];
      if (getIsHome(currentLocationPath)) {
        updatedList = [HOME_BASE];
      } else {
        const twoPathsAgo = history.list?.[history.list.length - 2];
        updatedList =
          twoPathsAgo && twoPathsAgo === currentLocationPath
            ? history.list.slice(0, history.list.length - 1)
            : [...history.list, currentLocationPath];
      }
      const lastPathName = updatedList[updatedList.length - 2] || HOME_BASE;
      setHistory({
        hasHistory: updatedList.length > 1,
        isLastHome: getIsHome(lastPathName),
        lastPathName, // Used for HOME/BACK button functionality
        list: updatedList,
        previousPage: lastLocationPath,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentLocationPath]);

  const resetHistory = () => {
    setHistory({
      hasHistory: false,
      isLastHome: true,
      lastPathName: HOME_BASE,
      list: [HOME_BASE],
      previousPage: HOME_BASE,
    });
  };

  const value = useMemo(
    () => ({
      hasHistory: history.hasHistory,
      isLastHome: history.isLastHome,
      lastPathName: history.lastPathName,
      previousPage: history.previousPage,
      resetHistory,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [history]
  );

  return <HistoryContext.Provider value={value}>{children}</HistoryContext.Provider>;
};

HistoryProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default HistoryProvider;

export { HistoryContext };
