import { createSelector } from '@reduxjs/toolkit';
import { API_PATHS } from 'constants/API';
import { getValidOfferCodeContent } from 'utils/voyageUtils';
import tapApi from './api';

const searchApi = tapApi.injectEndpoints({
  endpoints: (build) => ({
    getSearchData: build.query({
      keepUnusedDataFor: 900,
      providesTags: (res, err, { currency, offerCode }) => [
        { type: 'ItinerariesSummary', id: `${currency}${offerCode ? `-${offerCode}` : ''}` },
      ],
      queryFn: async ({ currency, offerCode }, _api, _extraOptions, baseQuery) => {
        const url = API_PATHS.search({ currency, offerCode });
        const response = await baseQuery(url);
        return response;
      },
    }),
  }),
});

const { useGetSearchDataQuery, useLazyGetSearchDataQuery, usePrefetch: useGetSearchDataPrefetch } = searchApi;

// Itinerary selectors
const emptyArray = []; // maintain a stable reference to avoid excessive callbacks
const selectItineraries = (result) => result.data?.itineraries ?? emptyArray;
const selectVoyageName = (result, filters) => filters.voyageName;
const selectOfferCode = (result, filters) => filters.offerCode;
const selectHasValidOffer = (result) => result.data.hasValidOffer;
const selectOfferCodeContent = (result) => result.data.offerCodeContent;

const selectItineraryData = createSelector(
  [selectItineraries, selectVoyageName, selectOfferCode, selectHasValidOffer, selectOfferCodeContent],
  (itineraryPool, voyageName, offerCode, hasValidOffer, offerCodeContent) => {
    const providedOfferCode = !!offerCode;
    const fullyMatchingItinerary = itineraryPool.find(
      ({ hasOffers, name }) => name === voyageName && (!providedOfferCode || hasOffers)
    );
    const itineraryExists = !!fullyMatchingItinerary || itineraryPool.some(({ name }) => name === voyageName);
    const resultsLimitedByValidOffer = hasValidOffer && !!offerCodeContent && itineraryPool[0]?.hasOffers;
    const offerCodeIsValid = providedOfferCode ? resultsLimitedByValidOffer : undefined;
    const offerCodeIsValidForItinerary = providedOfferCode ? offerCodeIsValid && !!fullyMatchingItinerary : undefined;
    return {
      itineraryDoesNotExist: !itineraryExists && !resultsLimitedByValidOffer,
      itineraryExists,
      itineraryMayOrMayNotExist: !itineraryExists && resultsLimitedByValidOffer,
      matchingItinerary: fullyMatchingItinerary,
      offerCodeContent: offerCodeIsValid ? getValidOfferCodeContent(offerCodeContent, offerCode) : undefined,
      offerCodeIsValid,
      offerCodeIsValidForItinerary,
    };
  }
);

const useSelectItineraryFromResults = ({ currency, offerCode, voyageName }) => {
  const args = offerCode ? { currency, offerCode } : { currency };
  const { error, isError, isFetching, isSuccess, isUninitialized, itineraryData } = useGetSearchDataQuery(args, {
    selectFromResult: (result) => {
      return result.isSuccess && result.data
        ? {
            ...result,
            itineraryData: selectItineraryData(result, { offerCode, voyageName }),
          }
        : result;
    },
  });
  return { error, isError, isFetching, isSuccess, isUninitialized, itineraryData };
};

export { useGetSearchDataPrefetch, useGetSearchDataQuery, useLazyGetSearchDataQuery, useSelectItineraryFromResults };

export default searchApi;
