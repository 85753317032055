import { useGetCmsQueryState } from 'app/services/tap/cms';
import { OFFICE_UNKNOWN } from 'constants/COUNTRIES';
import { useSelector } from 'react-redux';

const useCmsState = ({ cmsPath }) => {
  const { agency } = useSelector((state) => state?.auth) || {};
  const cmsOffice = agency?.cmsOffice || OFFICE_UNKNOWN;
  const { isFetching, data } = useGetCmsQueryState({ cmsPath, office: cmsOffice });
  return isFetching ? null : { [cmsPath.key]: data };
};

export default useCmsState;
