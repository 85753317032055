import { zodResolver } from '@hookform/resolvers/zod';
import { NOT_SET } from 'common/account/ACCOUNT_CONSTANTS';
import {
  CMS_OFFICE_CODE_BY_COUNTRY,
  CMS_OFFICE_CODE_BY_CURRENCY,
  COUNTRY,
  OFFICE_UNKNOWN,
  OFFICES,
  VIKING_OFFICE_BY_COUNTRY,
  VIKING_OFFICE_BY_CURRENCY,
} from 'constants/COUNTRIES';
import { cmsItems } from 'constants/PROP_TYPES_CMS';
import PropTypes from 'prop-types';
import { getItemsLikeJcrName } from 'utils/cms';
import { MIN_LENGTHS, VALIDATION_REGEXES } from 'utils/formValidation';
import { isBoolean, isTrue } from 'utils/string';
import { z } from 'zod';

const getCmsOfficeCode = ({ agent, agency, geoLocationCountry, defaultOffice = OFFICE_UNKNOWN }) => {
  if (agency?.office !== OFFICE_UNKNOWN && agency?.currency && CMS_OFFICE_CODE_BY_CURRENCY[agency.currency]) {
    return CMS_OFFICE_CODE_BY_CURRENCY[agency.currency];
  }
  if (agent?.country !== OFFICE_UNKNOWN && agent?.country && CMS_OFFICE_CODE_BY_COUNTRY[agent.country]) {
    return CMS_OFFICE_CODE_BY_COUNTRY[agent.country];
  }
  if (geoLocationCountry && CMS_OFFICE_CODE_BY_COUNTRY[geoLocationCountry]) {
    return CMS_OFFICE_CODE_BY_COUNTRY[geoLocationCountry];
  }
  return defaultOffice;
};

const getVikingOfficeCode = ({ agent, agency, geoLocationCountry, defaultOffice = OFFICES.US }) => {
  if (agent?.country && VIKING_OFFICE_BY_COUNTRY[agent.country]) {
    return VIKING_OFFICE_BY_COUNTRY[agent.country];
  }
  if (agency?.currency && VIKING_OFFICE_BY_CURRENCY[agency.currency]) {
    return VIKING_OFFICE_BY_CURRENCY[agency.currency];
  }
  if (geoLocationCountry && VIKING_OFFICE_BY_COUNTRY[geoLocationCountry]) {
    return VIKING_OFFICE_BY_COUNTRY[geoLocationCountry];
  }
  return defaultOffice;
};

const formatCmsContent = (data) => {
  const content = getItemsLikeJcrName({
    items: data?.pagesAccount?.items,
    jcrName: 'content-',
    key: 'title',
    transformName: (name) => name.replace('content-', ''),
  });

  const cmsFormsData = data?.pagesAccount?.forms?.find(({ jcrName }) => jcrName === 'account-form');

  const { countries } = data?.common?.props || {};

  const accountLabels = data?.common?.strings?.labels?.account;
  const faqLabels = data?.common?.strings?.labels?.faq;

  const formErrors = {
    ...data?.common?.strings?.errors?.general,
    ...data?.common?.strings?.errors?.account,
    ...getItemsLikeJcrName({
      items: cmsFormsData?.sections?.find(({ jcrName }) => jcrName === 'account-form-validation')?.fields,
      jcrName: 'account-form-validation-',
      key: 'label',
      transformName: (name) => name.replace('account-form-validation-', ''),
    }),
  };

  const formLabels = getItemsLikeJcrName({
    items: cmsFormsData?.sections?.find(({ jcrName }) => jcrName.startsWith('account-form-labels-'))?.fields || [],
    jcrName: 'account-form-labels-',
    key: 'label',
    transformName: (name) => name.replace('account-form-labels-', ''),
  });

  const generalLabels = data?.common?.strings?.labels?.general;

  const myAdvisorBullets = Object.values(
    getItemsLikeJcrName({
      items: data?.pagesAccount?.items,
      jcrName: 'my-advisor-bullets-',
      transformName: (name) => name.replace('my-advisor-bullets-', ''),
    }) || {}
  );

  const relationOptions = Object.values(
    getItemsLikeJcrName({
      items: data?.pagesAccount?.items,
      jcrName: 'relation-options-',
      key: 'title',
      transformName: (name) => name.replace('relation-options-', ''),
    }) || {}
  );

  const whoCanUpdateBullets = Object.values(
    getItemsLikeJcrName({
      items: data?.pagesAccount?.items,
      jcrName: 'who-can-update-bullets-',
      transformName: (name) => name.replace('who-can-update-bullets-', ''),
    }) || {}
  );

  return {
    accountLabels,
    content,
    countries,
    faqLabels,
    formErrors,
    formLabels,
    generalLabels,
    myAdvisorBullets,
    relationOptions,
    whoCanUpdateBullets,
  };
};

const getIsPartiallyCreated = ({ agent }) => agent?.agentRelationship === NOT_SET;

const getDefaultValues = ({ agency, agent }) => {
  const {
    address1 = '',
    address2 = '',
    address3 = '',
    city = '',
    state = '',
    zip = '',
    country = '',
    phone = '',
    agentRelationship,
  } = !getIsPartiallyCreated({ agent }) ? agent : {};

  return {
    address1: address1 || '',
    address2: address2 || '',
    address3: address3 || '',
    agencyNumber: agency.agencyNumber || '',
    city: city || '',
    country: country || agent.office || agency.office || '',
    email1: agent.email || agent.tapEmail || '',
    email2: agent.email2 || '',
    emailSignIn: agent.tapEmail || '',
    firstName: agent.firstName || '',
    lastName: agent.lastName || '',
    middleName: agent.middleName || '',
    phone: phone || '',
    phoneMobile: agent.mobilePhone || agent.phoneMobile || '',
    promoEmail: isBoolean(agent.promoEmail) ? isTrue(agent.promoEmail) : '',
    promoPost: isBoolean(agent.promoPost) ? isTrue(agent.promoPost) : '',
    promoPhone: isBoolean(agent.promoPhone) ? isTrue(agent.promoPhone) : '',
    relation: agentRelationship || '',
    state: state || '',
    useAgencyAddress: false,
    zip: zip === NOT_SET ? '' : zip || '',
  };
};

// Dev Note: By design, error messages do not always reflect the validation rules. See IM-314.
const getResolver = (data) => {
  const { relationOptions } = data?.common?.strings?.labels?.general || [];
  const addressIsValid = (value) => !VALIDATION_REGEXES.ADDRESS.test(value);
  const addressIsValidWithMinLength = (value) => addressIsValid(value) && value.length >= MIN_LENGTHS.ADDRESS;

  return zodResolver(
    z
      .object({
        address1: z.string().min(1, { message: 'address1-required' }).optional(),
        address2: z.string().refine(addressIsValidWithMinLength, 'address-invalid').optional().or(z.literal('')),
        address3: z.string().refine(addressIsValidWithMinLength, 'address-invalid').optional().or(z.literal('')),
        city: z
          .string()
          .min(1, { message: 'city-required' })
          .refine((value) => !VALIDATION_REGEXES.CITY.test(value) && value.length >= MIN_LENGTHS.CITY, 'city-invalid'),
        country: z.string().min(1, { message: 'country-required' }),
        email1: z
          .string()
          .min(1, { message: 'email1-required' })
          .email('email-invalid')
          .refine((value) => VALIDATION_REGEXES.EMAIL.test(value), 'email-invalid'),
        email2: z
          .string()
          .email('email-invalid')
          .refine((value) => VALIDATION_REGEXES.EMAIL.test(value), 'email-invalid')
          .optional()
          .or(z.literal('')),
        emailSignIn: z
          .string()
          .email('email-invalid')
          .refine((value) => VALIDATION_REGEXES.EMAIL.test(value), 'email-invalid')
          .optional()
          .or(z.literal('')),
        firstName: z
          .string()
          .min(1, { message: 'first-name-required' })
          .refine(
            (value) => VALIDATION_REGEXES.NAME.test(value) && value.length >= MIN_LENGTHS.NAME,
            'first-name-invalid'
          ),
        lastName: z
          .string()
          .min(1, { message: 'last-name-required' })
          .refine(
            (value) => VALIDATION_REGEXES.NAME.test(value) && value.length >= MIN_LENGTHS.NAME,
            'last-name-invalid'
          ),
        middleName: z
          .string()
          .refine(
            (value) => VALIDATION_REGEXES.NAME.test(value) && value.length >= MIN_LENGTHS.MIDDLE_NAME,
            'middle-name-invalid'
          )
          .optional()
          .or(z.literal('')),
        phone: z.string().min(1, { message: 'phone-required' }),
        phoneMobile: z.string(),
        promoEmail: z.boolean().optional().or(z.literal('')),
        promoPhone: z.boolean().optional().or(z.literal('')),
        promoPost: z.boolean().optional().or(z.literal('')),
        relation: z
          .string()
          .min(1, { message: 'relationship-required' })
          .refine((value) => relationOptions.includes(value), 'relationship-required'),
        state: z.string().optional(),
        useAgencyAddress: z.boolean(),
        zip: z.string().optional(),
      })
      .refine((formData) => !formData?.email2 || formData?.email1 !== formData?.email2, {
        message: 'email-duplicate-invalid',
        path: ['email2'],
      })
      .superRefine(({ address1, address2, address3, country, state, zip }, refinementContext) => {
        const isCommonCountry = !!data.common.props.countries[COUNTRY[country] || country];
        if (isCommonCountry) {
          if (!addressIsValidWithMinLength(address1)) {
            refinementContext.addIssue({
              message: 'address-invalid',
              path: ['address1'],
            });
          }

          Object.entries({ address2, address3 }).forEach(([key, addressValue]) => {
            if (!addressIsValid(addressValue)) {
              refinementContext.addIssue({
                message: 'address-invalid',
                path: [key],
              });
            }
          });

          if (!state) {
            refinementContext.addIssue({
              message: 'required',
              path: ['state'],
            });
          }

          if (!zip) {
            refinementContext.addIssue({
              message: 'required',
              path: ['zip'],
            });
          }
        } else {
          Object.entries({ address1 }).forEach(([key, addressValue]) => {
            if (addressValue.length < MIN_LENGTHS.ADDRESS) {
              refinementContext.addIssue({
                message: 'address-invalid-uncommon',
                path: [key],
              });
            }
          });
        }
        return undefined;
      })
  );
};

const getText = (labels, labelName) => (labelName ? labels?.[labelName] || `${labelName} (ADD TO LABELS)` : '');

const getTextWithSuffix = (labels, labelName, labelSuffix) => {
  const labelText = getText(labels, labelName).trim();
  return !labelSuffix || labelText.endsWith(labelSuffix) ? labelText : `${labelText}${labelSuffix}`;
};

const MAILING_INFO_FORM_MAP = Object.freeze({
  addressLine1: 'address1',
  addressLine2: 'address2',
  addressLine3: 'address3',
  city: 'city',
  zip: 'zip',
  country: 'country',
  zipCode: 'zip',
});

const propTypes = {
  data: PropTypes.shape({
    pagesAccount: PropTypes.shape({
      items: cmsItems,
    }).isRequired,
  }).isRequired,
  isAccountInEvoOnly: PropTypes.bool,
  isNewTapAccount: PropTypes.bool,
  isTapEmailDisplayed: PropTypes.bool,
  onUpdateComplete: PropTypes.func,
};

export {
  formatCmsContent,
  getCmsOfficeCode,
  getDefaultValues,
  getResolver,
  getText,
  getTextWithSuffix,
  getVikingOfficeCode,
  MAILING_INFO_FORM_MAP,
  propTypes,
};
