import { useIsAuthenticated } from '@azure/msal-react';
import Button from '@viking-eng/button';
import HamburgerMenu from '@viking-eng/icon/lib/HamburgerMenu';
import { getAccessibilityFunctions } from '@viking-eng/utils';
import { ANALYTICS_EVENT_TYPES, triggerLinkEvent } from 'analytics/Analytics';
import classNames from 'classnames';
import Menu from 'common/ui-kit/Menu/Menu';
import { KEY_EVENT_CODES } from 'constants/A11Y';
import ROUTES from 'constants/ROUTES';
import useEventListener from 'hooks/useEventListener';
import useRoute from 'hooks/useRoute';
import PropTypes from 'prop-types';
import { useRef } from 'react';
import './Header.scss';
import HeaderLogoImage from './HeaderLogoImage';
// Header component for showing headers
// Children to show top right header menu

function Header({
  additionalMenus = [],
  children,
  getRedirectLink = () => null,
  header = {},
  headerClass,
  headerTitle,
  isHeaderLogoVisible = true,
  isLeftMenuOpen,
  isPagePublic = false,
  isRightMenuOpen,
  onNavigate,
  preHeaderButton,
  preHeaderText = null,
  setIsLeftMenuOpen,
  setIsRightMenuOpen,
}) {
  const isAuthenticated = useIsAuthenticated();
  const route = useRoute();
  const isNavMenuVisible = route.key !== ROUTES.maintenance.key;
  const { logo, items, country, navigationRoute } = header;

  const headerRef = useRef();

  const onOutsideClick = (event) => {
    if (!headerRef?.current?.contains(event.target)) {
      closeDropDowns();
    }
  };

  const toggleLeftMenu = (e) => {
    setIsRightMenuOpen(false);
    setIsLeftMenuOpen(!isLeftMenuOpen);
    e.preventDefault();
  };

  const closeDropDowns = () => {
    if (setIsLeftMenuOpen) {
      setIsLeftMenuOpen(false);
    }
    if (setIsRightMenuOpen) {
      setIsRightMenuOpen(false);
    }
  };

  useEventListener('click', onOutsideClick);

  const headerIconNavigationRoute = country ? `/${country}` : navigationRoute;
  const hasLeftMenu = items && !!items.length && isNavMenuVisible;

  const onLogoTitleClick = () => {
    triggerLinkEvent({
      event: ANALYTICS_EVENT_TYPES.MAIN_NAVIGATION,
      main_nav_cat: 'homepage',
    });
    closeDropDowns();
    if (onNavigate) {
      onNavigate({ url: headerIconNavigationRoute });
    }
  };

  return (
    <header className="header-wrapper" ref={headerRef}>
      <div className={classNames('header', headerClass)}>
        {preHeaderText && (
          <div className="preheader-container row no-gutters">
            {preHeaderButton && (
              <Button className="col-4 header-link" appearance="link" onButtonClick={preHeaderButton.onClick}>
                {preHeaderButton.text}
              </Button>
            )}
            <div className={classNames('container text-center', { 'col-4': preHeaderButton })}>{preHeaderText}</div>
            {preHeaderButton && <div className="col-4" />}
          </div>
        )}
        <div className={classNames(`header-container ${preHeaderText ? 'has-pre-header' : ''}`)}>
          <div className="container menu">
            <nav className={classNames(`navbar row no-gutters ${isPagePublic ? 'public-nav' : ''}`)}>
              <div className="col-md-3 col-2">
                {hasLeftMenu && (
                  <Button
                    appearance="icon"
                    attributes={{
                      'aria-expanded': isLeftMenuOpen,
                      'aria-haspopup': 'true',
                      'aria-label': 'Toggle navigation',
                      'data-toggle': 'collapse',
                      id: 'hamburger-menu',
                      tabIndex: '0',
                    }}
                    inlineCss={{
                      color: 'White',
                      fontSize: '16px',
                      marginBottom: '4px',
                    }}
                    onButtonClick={toggleLeftMenu}
                  >
                    <HamburgerMenu />
                  </Button>
                )}
              </div>

              <div className="col-md-6 col-8">
                <div className="logo">
                  {isAuthenticated ? (
                    <div
                      className="logo-column"
                      onClick={onLogoTitleClick}
                      onKeyDown={(event) => {
                        if ([KEY_EVENT_CODES.enter].includes(event.key)) {
                          onLogoTitleClick();
                        }
                      }}
                      role="button"
                      tabIndex="0"
                      /* eslint-disable-next-line react/jsx-props-no-spreading */
                      {...getAccessibilityFunctions()}
                    >
                      <HeaderLogoImage
                        headerTitle={headerTitle}
                        isHeaderLogoVisible={isHeaderLogoVisible}
                        logo={logo}
                      />
                    </div>
                  ) : (
                    <HeaderLogoImage headerTitle={headerTitle} isHeaderLogoVisible={isHeaderLogoVisible} logo={logo} />
                  )}
                </div>
              </div>

              <div className="col-md-3 col-2">
                {isNavMenuVisible && <div className="no-gutters header-right">{children}</div>}
              </div>
            </nav>
            {hasLeftMenu && (
              <Menu
                getRedirectLink={getRedirectLink}
                id="leftMenuId"
                isOpen={isLeftMenuOpen}
                items={items}
                onNavigate={onNavigate}
                position="left"
                setIsOpen={setIsLeftMenuOpen}
              />
            )}
            {additionalMenus.map((menu) => (
              <div className="additional-menu" key={menu.id}>
                <Menu
                  getRedirectLink={getRedirectLink}
                  id={menu.id}
                  isOpen={isRightMenuOpen}
                  items={menu.items}
                  onNavigate={onNavigate}
                  position="right"
                  setIsOpen={setIsRightMenuOpen}
                  tabIndex={menu.tabIndex}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </header>
  );
}

// TODO: Fix additionalMenus prop-types in UI Kit
Header.propTypes = {
  additionalMenus: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      items: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string.isRequired,
          isSmallText: PropTypes.bool.isRequired,
          openInNewWindow: PropTypes.bool,
          title: PropTypes.string.isRequired,
          url: PropTypes.string.isRequired,
        })
      ),
    })
  ),
  children: PropTypes.node,
  getRedirectLink: PropTypes.func,
  header: PropTypes.shape({
    country: PropTypes.string,
    items: PropTypes.arrayOf(PropTypes.shape({})),
    logo: PropTypes.string.isRequired,
    navigationRoute: PropTypes.string,
  }),
  headerClass: PropTypes.string,
  headerTitle: PropTypes.string,
  isHeaderLogoVisible: PropTypes.bool,
  isLeftMenuOpen: PropTypes.bool,
  isPagePublic: PropTypes.bool,
  isRightMenuOpen: PropTypes.bool,
  onNavigate: PropTypes.func,
  preHeaderButton: PropTypes.shape({
    onClick: PropTypes.func.isRequired,
    text: PropTypes.string.isRequired,
  }),
  preHeaderText: PropTypes.string,
  setIsLeftMenuOpen: PropTypes.func,
  setIsRightMenuOpen: PropTypes.func,
};

export default Header;
