import Button from '@viking-eng/button';
import { ANALYTICS_EVENT_TYPES, ANALYTICS_LINK_TYPES, triggerLinkEvent } from 'analytics/Analytics';
import useNavigate from 'hooks/useNavigate';
import PropTypes from 'prop-types';
import { keyify } from 'utils/string';

const VikingLink = ({ onMouseEnter, text = '', url = '', onClick = undefined, ...other }) => {
  const navigate = useNavigate();
  const linkContent = other.children || text;
  const appearance = other?.buttonProps?.appearance || 'link';

  if (!url) {
    return null;
  }

  const onLinkClick = (e) => {
    if (onClick) {
      onClick(e);
    } else {
      const linkId =
        typeof linkContent === 'string' ? keyify(linkContent) : e.target.text || e.target.title || e.target.id;
      triggerLinkEvent({
        event: ANALYTICS_EVENT_TYPES.BUTTON_CLICK,
        linkText: keyify(linkId),
        linkType: appearance === 'link' ? ANALYTICS_LINK_TYPES.TEXT : ANALYTICS_LINK_TYPES.BUTTON,
        linkId,
      });
    }

    navigate(url);
  };

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Button {...other} appearance={appearance} attributes={{ onMouseEnter }} onButtonClick={onLinkClick}>
      {linkContent}
    </Button>
  );
};

VikingLink.propTypes = {
  onClick: PropTypes.func,
  onMouseEnter: PropTypes.func,
  text: PropTypes.string,
  url: PropTypes.string,
};

export default VikingLink;
