import Image from '@viking-eng/image';
import classNames from 'classnames';
import { CMS_PATHS } from 'constants/API';
import { IMAGE_VIKING_LOGO_PLAIN } from 'constants/IMAGE';
import ROUTES, { BASE_PATH } from 'constants/ROUTES';
import useCmsState from 'hooks/useCmsState';
import useRoute from 'hooks/useRoute';
import PropTypes from 'prop-types';
import { getCmsPathForRoute } from 'utils/routing';
import './PageTitle.scss';

const PageTitle = ({ fallbackTitle, isForPrintTitle = false }) => {
  const route = useRoute();

  const currentPath = window.location.pathname?.replace(`${BASE_PATH}`, '');
  const pageCmsData = useCmsState({ cmsPath: getCmsPathForRoute(route) }) || {};
  const cmsData = useCmsState({ cmsPath: CMS_PATHS.pages.common });
  const { pageTitle, pageName } = Object.values(pageCmsData)?.[0] || {};
  const pageTitleString =
    currentPath !== ROUTES.notFound.url ? pageName || pageTitle || fallbackTitle : ROUTES.notFound.title;
  return isForPrintTitle ? (
    <div className="print-title">
      <Image className="logo-image" src={IMAGE_VIKING_LOGO_PLAIN} alt="logo" />
      <p className="logo-title">{cmsData?.common?.strings?.labels?.general?.appName}</p>
      <p className="print-page-title">{pageTitleString}</p>
      <hr />
    </div>
  ) : (
    <h1 className={classNames({ 'text-uppercase': route.isTitleUpperCase })} id="page-title">
      {pageTitleString}
    </h1>
  );
};
PageTitle.propTypes = { fallbackTitle: PropTypes.string, isForPrintTitle: PropTypes.bool };
export default PageTitle;
