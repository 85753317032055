import _camelCase from 'lodash.camelcase';
import { getUrlSearchParamsFromObj } from 'utils/httpsUtils';
import { base64Encode } from 'utils/string';
import { CURRENCY_BY_COUNTRY, OFFICE_UNKNOWN } from './COUNTRIES';
import { TAP_API_BASE_URL } from './ENV';
import ROUTES from './ROUTES';

const API_PATH_AUTH = Object.freeze({
  agent: ({ email }) => `agent/${base64Encode(email?.toLowerCase() || '')}`,
  agentBookings: ({ emailBase64 }) => `agent/bookings/${emailBase64}`,
  agentCreate: () => 'agent',
  agentSsoAcademy: (email) => `${TAP_API_BASE_URL}/agent/${base64Encode(email?.toLowerCase() || '')}/academy`,
  agentSsoMarketing: (email) => `${TAP_API_BASE_URL}/agent/${base64Encode(email?.toLowerCase() || '')}/marketingcenter`,
  agentSsoRewards: (email) => `${TAP_API_BASE_URL}/agent/${base64Encode(email?.toLowerCase() || '')}/rewards`,
  myJourneyAuth: ({ accountId }) => `mvj/auth/${accountId}`,
});

const API_PATHS = Object.freeze({
  cms: async ({ path, office }) => {
    office = office || OFFICE_UNKNOWN;
    return `cms/${office}/${path}`;
  },
  cobrandedUrlExists: (urlBase64) => `cobranded/url/${urlBase64}`,
  flags: () => 'flags',
  getValidAgency: ({ agencyIata, email }) =>
    `agency/${agencyIata}${getUrlSearchParamsFromObj({
      emailBase64: email ? base64Encode(email.toLowerCase()) : '',
    })}`,
  meta: () => 'meta',
  offerCode: ({ currency, office, offerCode, voyageNameBase64 }) =>
    `offerCodes/${office}/${currency}/${voyageNameBase64}/${offerCode}`,
  onlineBooking: ({ currency, office, payloadBase64 }) => `olb/${office}/${currency}/${payloadBase64}`,
  search: ({ currency, offerCode }) => `search/${currency}${getUrlSearchParamsFromObj({ offerCode })}`,
  sendInvoice: ({ office, currency, bookingId, invoiceType }) =>
    `invoice/${office}/${currency}/${bookingId}/${invoiceType}`,
  ships: ({ shipId }) => `ships/${shipId}`,
  shipStateroomCategories: ({ shipId }) => `ships/stateroomCategories/${shipId}`,
  staterooms: ({ currency, office, voyageId, offerCode }) =>
    `staterooms/${office}/${currency || CURRENCY_BY_COUNTRY[office].code}/${voyageId}${getUrlSearchParamsFromObj({
      offerCode,
    })}`,
  validateAddress: ({ country }) => `address/${(country || '').toUpperCase()}/validate`,
  versionInfo: () => 'versioninfo',
  voyages: ({ currency, offerCode, office, voyageNameBase64 }) =>
    // eslint-disable-next-line max-len
    `voyages/${office}/${currency || CURRENCY_BY_COUNTRY[office].code}/${voyageNameBase64}/all${getUrlSearchParamsFromObj(
      { offerCode }
    )}`,
  website: (email) => `cobranded/${base64Encode(email?.toLowerCase() || '')}`,
  websiteSave: () => 'cobranded',
});

const CMS_PATHS = Object.freeze({
  contacts: { key: 'contacts', path: 'contacts' },
  faq: { key: 'faq', path: 'faq' },
  pages: {
    common: { key: 'common', path: 'pages/common' },
    homePageModal: { key: 'hp-modal', path: 'pages/hp-modal' },
    ...Object.keys(ROUTES).reduce((acc, key) => {
      acc[key] = { key: _camelCase(`pages-${key}`), path: `pages/${ROUTES[key]?.key || key}` };
      return acc;
    }, {}),
  },
});

const CMS_REDIRECT_URL_KEYS = Object.freeze({
  academy: '[API_PATH]/agent/academy',
  rewards: '[API_PATH]/agent/rewards',
  marketing: '[API_PATH]/agent/marketingcenter',
});

const HTTP_CODES = Object.freeze({
  OK_200: '200',
  CREATED_201: '201',
  NO_CONTENT_204: '204',
  PARTIAL_CONTENT_206: '206',
  REDIRECT_302: '302',
  BAD_REQUEST_400: '400',
  UNAUTHORIZED_401: '401',
  NOT_FOUND_404: '404',
  TIMEOUT_408: '408',
  CONFLICT_409: '409',
  ERROR_CODE_500: '500',
});

const HTTP_VERBS = Object.freeze({
  DELETE: 'DELETE',
  GET: 'GET',
  POST: 'POST',
  PUT: 'PUT',
});

const MAX_RETRIES = 3;

const PREFETCH_ENDPOINTS_BY_ACTION_KEY = Object.freeze({
  hoverViewDates: ['getVoyages'],
  hoverViewPricing: ['getStaterooms'],
  hoverStateroomDetails: ['getShipStateroomCategories'],
});

const PREFETCH_ENDPOINTS_BY_ROUTE_KEY = Object.freeze({
  [ROUTES.bookingCreate.key]: ['getSearchData'],
  [ROUTES.bookings.key]: ['getAgentBookings'],
});

const SSO_MOBILE_ENABLED = Object.freeze([CMS_REDIRECT_URL_KEYS.marketing, CMS_REDIRECT_URL_KEYS.academy]);

const UNHANDLED_ENDPOINTS = [
  'cobrandedUrlExists',
  'createAgent',
  'getAgent',
  'getValidAgency',
  'getVoyages',
  'updateAgent',
  'validateAddress',
];

const USER_CANCELED_STATUSES = ['PARSING_ERROR', 'FETCH_ERROR'];

export {
  API_PATH_AUTH,
  API_PATHS,
  CMS_PATHS,
  CMS_REDIRECT_URL_KEYS,
  HTTP_CODES,
  HTTP_VERBS,
  MAX_RETRIES,
  PREFETCH_ENDPOINTS_BY_ACTION_KEY,
  PREFETCH_ENDPOINTS_BY_ROUTE_KEY,
  SSO_MOBILE_ENABLED,
  UNHANDLED_ENDPOINTS,
  USER_CANCELED_STATUSES,
};
