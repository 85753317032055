// Note: This can only be used as a descendant of redux Provider and react-router BrowserRouter components.
import { useGetCmsQuery } from 'app/services/tap/cms';
import LoadingSpinner from 'common/loadingSpinner/LoadingSpinner';
import { CMS_PATHS } from 'constants/API';
import { SUPPORT_BY_COUNTRY } from 'constants/COUNTRIES';
import { PAGE_ERROR_DESCRIPTION } from 'constants/STRINGS';
import PropTypes from 'prop-types';
import { useLayoutEffect } from 'react';
import { useSelector } from 'react-redux';
import { removeLoader } from 'utils/loader';
import { logError } from 'utils/logging';
import ErrorPrimitive from './ErrorPrimitive';

const LayoutError = ({ error }) => {
  const { cmsOffice: office } = useSelector((state) => state.auth.agent);
  const { data, isFetching } = useGetCmsQuery({
    cmsPath: CMS_PATHS.pages.common,
    office,
  });

  const countryData = data?.props?.countries?.[office];

  const description = (data?.strings?.errors?.general?.pageErrorDescription || PAGE_ERROR_DESCRIPTION)?.replace(
    '[EMAIL_SUPPORT]',
    countryData?.emailSupport || SUPPORT_BY_COUNTRY?.[office]?.email
  );

  useLayoutEffect(() => {
    logError({ error });
    removeLoader();
  }, [error]);

  return isFetching ? <LoadingSpinner /> : <ErrorPrimitive className="pt-5" description={description} />;
};

LayoutError.propTypes = {
  error: PropTypes.shape({
    message: PropTypes.string.isRequired,
  }).isRequired,
};

export default LayoutError;
