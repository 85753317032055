// Note: Preferrably we'd navigate to the maintenance route instead, but AccountContainer and Auth are ancestors to Layout in App. We'd have to do a lot more refactoring otherwise, so this works in the meantime.
// Navigating to maintenance will work in lower components though.

import HistoryProvider from 'common/history/History';
import Layout from 'common/layout/Layout';
import { lazy, Suspense } from 'react';

const Maintenance = lazy(() => import('./Maintenance'));

const MaintenanceWrapper = () => {
  return (
    <HistoryProvider>
      <Layout isPublic>
        <Suspense fallback={null}>
          <Maintenance />
        </Suspense>
      </Layout>
    </HistoryProvider>
  );
};

export default MaintenanceWrapper;
