import { BOOKING_STATUS } from 'constants/BOOKING';
import { unparse } from 'papaparse';
import { addYears, getDateFromISOString, isDateInRange } from 'utils/dateUtils';
import { createDownloadLinkCompressed } from './file';
import { VALIDATION_REGEXES } from './formValidation';
import { addDecimals } from './numberUtils';

const BOOKING_STATUS_SORT_ORDER = Object.freeze({
  [BOOKING_STATUS.CNF.code]: 0,
  [BOOKING_STATUS.CXL.code]: 2,
  [BOOKING_STATUS.FNL.code]: 0,
  [BOOKING_STATUS.OFR.code]: 1,
  [BOOKING_STATUS.PST.code]: 3,
  [BOOKING_STATUS.WL.code]: 4,
});

const getBookingPropValIsWithinDateRange = ({ booking = {}, bookingProp = '', selectionValues = [] }) => {
  return isDateInRange({
    inputDate: getDateFromISOString(booking[bookingProp]),
    minDate: getDateFromISOString(selectionValues[0] || new Date()),
    maxDate: getDateFromISOString(selectionValues[1] || addYears(new Date(), 3)),
  });
};

const FILTER_FNS_BY_SELECTION_KEY = {
  selectedBookingDateRange: {
    filterFn: getBookingPropValIsWithinDateRange,
    bookingProp: 'creationDate',
  },
  selectedSailingDateRange: {
    filterFn: getBookingPropValIsWithinDateRange,
    bookingProp: 'embarkDate',
  },
};

const BOOKING_STATUS_SORT_ORDER_DEFAULT = 10;

const bookingsFilter = ({ bookings, isFilteredByBalanceDue, search, statuses = [] }) => {
  return bookings?.filter((booking) => {
    const bookingStatus = booking.priceDetails?.[0]?.bookingStatus;
    const hasStatus = statuses.length > 0 ? statuses.map(({ code }) => code).includes(bookingStatus) : true;

    const isBalanceDueFiltered = isFilteredByBalanceDue ? booking.priceDetails[0].balanceAmt > 0 : true;
    const isFinalConfirmFiltered =
      bookingStatus === BOOKING_STATUS.FNL.code ? new Date(booking.embarkDate) > new Date() : true;
    let isSearchFiltered = true;

    if (search) {
      const isBookingNumberFiltered =
        VALIDATION_REGEXES.BOOKING_NUMBER.test(search) && booking.bookingNo.toString() === search;
      const isGroupNoFiltered = (booking.groupNo || '').toUpperCase().trim() === search;
      const searchParts = search.split(' ').map((v) => (v || '').toUpperCase());
      const isPassengerFiltered = (booking?.priceDetails?.[0]?.passengers || []).some((pax) =>
        searchParts.every(
          (part) =>
            pax.middleName.toUpperCase().includes(part) ||
            `${pax.firstName || ''} ${pax.lastName || ''}`.toUpperCase().includes(part)
        )
      );
      const isPastPaxNumberFiltered =
        VALIDATION_REGEXES.MOCK_PAST_PASSENGER.test(search) &&
        (booking.passengers || []).some((pax) => pax.cchid === search);
      isSearchFiltered = isBookingNumberFiltered || isGroupNoFiltered || isPastPaxNumberFiltered || isPassengerFiltered;
    }

    return isBalanceDueFiltered && isFinalConfirmFiltered && hasStatus && isSearchFiltered;
  });
};

const getBookingCsv = ({ bookings }) => {
  const csvFriendlyJson = bookings.map((booking) => {
    return {
      'Booking Number': booking.bookingNo,
      'Group Number': booking.groupNo.trim(),
      Status: BOOKING_STATUS[booking.priceDetails[0].bookingStatus].name,
      'Guest 1 First Name': booking.priceDetails[0].passengers[0].firstName,
      'Guest 1 Middle Name': booking.priceDetails[0].passengers[0].middleName,
      'Guest 1 Last Name': booking.priceDetails[0].passengers[0].lastName,
      'Guest 2 First Name': booking.priceDetails[0].passengers?.[1]?.firstName,
      'Guest 2 Middle Name': booking.priceDetails[0].passengers?.[1]?.middleName,
      'Guest 2 Last Name': booking.priceDetails[0].passengers?.[1]?.lastName,
      'Guest Form Complete': booking.completedGIF === 'Y',
      'Cruise Name': booking.voyageName,
      From: booking.embarkPort.name,
      To: booking.disembarkPort.name,
      'Sail Date': booking.embarkDate,
      'Deposit Due': booking.priceDetails[0].depositDue,
      'Deposit Due Date': booking.priceDetails[0].depDueDate,
      'Balance Due': booking.priceDetails[0].balanceAmt,
      'Balance Due Date': booking.priceDetails[0].balDueDate,
      Currency: booking.priceDetails[0].currency,
      Commission: booking.priceDetails[0].commission,
      'Gross Price': booking.priceDetails[0].grossPrice,
      'Paid To Date': booking.priceDetails[0].paidToDate,
    };
  });

  const csv = unparse(csvFriendlyJson);
  createDownloadLinkCompressed({
    data: csv,
    extension: 'csv',
    title: `Bookings-${new Date().toISOString().substring(0, 10)}`,
  });
};

const getFilteredKey = (filter) => `${filter.status}${filter.search ? `_${filter.search}` : ''}`;

const transformPriceDetail = (priceDetail) => {
  const bookingStatusSortOrder =
    BOOKING_STATUS_SORT_ORDER?.[priceDetail.bookingStatus] === undefined
      ? BOOKING_STATUS_SORT_ORDER_DEFAULT
      : BOOKING_STATUS_SORT_ORDER?.[priceDetail.bookingStatus];
  return {
    balanceAmt: priceDetail.balanceAmt,
    balDueDate: priceDetail.balDueDate,
    bookingStatus: priceDetail.bookingStatus,
    bookingStatusSortOrder,
    commission: priceDetail.commission,
    currency: priceDetail.currency,
    depDueDate: priceDetail.depDueDate,
    depositDue: priceDetail.depositDue,
    grossPrice: priceDetail.grossPrice,
    insuranceTotal: priceDetail.insuranceTotal,
    // Properties to help sort deposit due and balance due
    hasBalanceAmt: !!priceDetail.balanceAmt,
    isNotOption: priceDetail.bookingStatus !== BOOKING_STATUS.OFR.code,
    office: priceDetail.office,
    paidToDate: priceDetail.paidToDate,
    passengers:
      priceDetail?.passengers?.map((passenger) => ({
        firstName: passenger.firstName,
        lastName: passenger.lastName,
        middleName: passenger.middleName,
      })) || [],
  };
};

const transformAndSummarizeData = ({ data = { dashboardDetails: [] } }) => {
  const dashboardData = (data.dashboardDetails || []).reduce(
    (acc, value) => {
      let priceDetails = value?.priceDetails || [];
      const priceDetail = { ...priceDetails?.[0] };
      if (priceDetail) {
        // Calculate Dashboard Details
        switch (priceDetail.bookingStatus) {
          case BOOKING_STATUS.CNF.code: // Confirmed
            acc.activeBookings += 1;
            acc.totalCommission = addDecimals(acc.totalCommission, priceDetail.commission);
            priceDetail.depositDue = 0;
            break;
          case BOOKING_STATUS.CXL.code: // Canceled
            priceDetail.balanceAmt = 0;
            priceDetail.commission = 0;
            priceDetail.depositDue = 0;
            priceDetail.grossPrice = 0;
            priceDetail.paidToDate = 0;
            break;
          case BOOKING_STATUS.PST.code: // Past
            priceDetail.balanceAmt = 0;
            priceDetail.depositDue = 0;
            break;
          case BOOKING_STATUS.FNL.code: // Final
            if (new Date(value.embarkDate) > new Date()) {
              acc.activeBookings += 1;
            }
            acc.totalCommission = addDecimals(acc.totalCommission, priceDetail.commission);
            priceDetail.depositDue = 0;
            break;
          case BOOKING_STATUS.OFR.code: // Option
            acc.activeBookings += 1;
            acc.optionBookings += 1;
            acc.optionCommission = addDecimals(acc.optionCommission, priceDetail.commission);
            acc.totalOptions += 1;
            break;
          default:
            break;
        }
        if (priceDetail.balanceAmt > 0) {
          acc.balanceDueBookings += 1;
        }
        if (priceDetail.balanceAmt < 0) {
          priceDetail.overpay = -1 * priceDetail.balanceAmt;
          priceDetail.balanceAmt = 0;
        }
        if (priceDetail.bookingStatus !== BOOKING_STATUS.OFR.code) {
          acc.totalGrossPrice = addDecimals(acc.totalGrossPrice, priceDetail.grossPrice);
        }
        priceDetails = priceDetails.map(transformPriceDetail);
      }

      acc.dashboardDetails.push({
        bookingNo: value.bookingNo,
        completedGIF: value.completedGIF,
        creationDate: value.creationDate,
        disembarkPortName: value.disembarkPort?.name || '',
        embarkDate: value.embarkDate,
        embarkPortName: value.embarkPort?.name || '',
        groupNo: value.groupNo,
        priceDetails,
        voyageID: value.voyageID,
        voyageName: value.voyageName,
      });
      return acc;
    },
    {
      ...data,
      activeBookings: 0,
      balanceDueBookings: 0,
      dashboardDetails: [],
      optionBookings: 0,
      optionCommission: 0,
      totalCommission: 0,
      totalGrossPrice: 0,
      totalOptions: 0,
    }
  );
  return dashboardData;
};

export { bookingsFilter, FILTER_FNS_BY_SELECTION_KEY, getBookingCsv, getFilteredKey, transformAndSummarizeData };
