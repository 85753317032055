import Button from '@viking-eng/button';
import Close from '@viking-eng/icon/lib/Close';
import InputText from '@viking-eng/input-text';
import PropTypes from 'prop-types';
import './SearchInput.scss';

const DEFAULT_PROPS = Object.freeze({
  button: {
    attributes: { 'aria-label': 'clear search' },
    className: '',
    disabled: false,
    onClick: () => null,
  },
  container: { className: '' },
  input: {
    ariaLabel: 'search',
    dataId: 'searchInputText',
    disabled: false,
    hasError: false,
    id: 'search',
    isErrorVisible: false,
    name: 'search',
    onChange: () => null,
    onKeyDown: () => null,
    onFocus: () => null,
    placeholder: 'Enter',
    ref: null,
    required: false,
    value: undefined,
  },
});

const SearchInput = ({ button, container, input } = {}) => {
  button = {
    ...DEFAULT_PROPS.button,
    ...button,
  };
  container = {
    ...DEFAULT_PROPS.container,
    ...container,
  };
  input = {
    ...DEFAULT_PROPS.input,
    ...input,
  };

  return (
    <div className={`search-input text-field ${container.className}`}>
      <InputText
        className={`text-capitalize ${input.hasError ? 'validation-error' : ''}`}
        dataId={input.dataId}
        forwardRef={input.ref}
        input={{
          'aria-label': input.ariaLabel,
          disabled: input.disabled,
          id: input.id,
          name: input.name,
          onFocus: input.onFocus,
          onKeyDown: input.onKeyDown,
          onChange: input.onChange,
          value: input.value,
        }}
        isErrorVisible={input.isErrorVisible}
        placeholder={input.placeholder}
        required={input.required}
        type="text"
      />

      {!!input.value && (
        <Button
          className={`close-wrapper ${button.className}`}
          disabled={button.disabled}
          onButtonClick={button.onClick}
          attributes={button.attributes}
        >
          <Close />
        </Button>
      )}
    </div>
  );
};

SearchInput.propTypes = {
  button: PropTypes.shape({
    attributes: PropTypes.shape({}),
    className: PropTypes.string,
    disabled: PropTypes.bool,
    onClick: PropTypes.func.isRequired,
  }),
  container: PropTypes.shape({
    className: PropTypes.string,
  }),
  input: PropTypes.shape({
    ariaLabel: PropTypes.string,
    dataId: PropTypes.string,
    disabled: PropTypes.bool,
    hasError: PropTypes.bool,
    id: PropTypes.string,
    isErrorVisible: PropTypes.bool,
    name: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    onKeyDown: PropTypes.func,
    onFocus: PropTypes.func,
    placeholder: PropTypes.string,
    ref: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.instanceOf(Element) })]),
    required: PropTypes.bool,
    value: PropTypes.string,
  }),
};

export default SearchInput;
