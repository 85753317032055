import { decompress } from 'compress-json';
import { API_PATH_AUTH, HTTP_VERBS } from 'constants/API';
import { base64Encode } from 'utils/string';
import tapApi from './api';

const agentApi = tapApi.injectEndpoints({
  endpoints: (build) => ({
    getAgent: build.query({
      providesTags: ['Agent'],
      queryFn: async ({ email }, _api, _extraOptions, baseQuery) => {
        const url = API_PATH_AUTH.agent({ email });
        const response = await baseQuery({
          method: 'GET',
          url,
        });
        return response;
      },
      extraOptions: { maxRetries: 0 },
    }),
    getAgentBookings: build.query({
      async queryFn({ agentRoleToken, email }, _api, _extraOptions, baseQuery) {
        const url = API_PATH_AUTH.agentBookings({
          emailBase64: base64Encode(email?.toLowerCase()),
          agentRole: agentRoleToken,
        });
        const initialResult = await baseQuery({
          method: 'GET',
          url,
        });
        if (initialResult.error) {
          return { error: initialResult.error };
        }
        const { data, ...rest } = initialResult;
        const decompressedData = Array.isArray(data?.data) ? decompress(data.data) : data;
        return {
          ...rest,
          data: decompressedData,
        };
      },
    }),
    createAgent: build.mutation({
      invalidatesTags: ['Agent'],
      queryFn: async ({ body, email }, _api, _extraOptions, baseQuery) => {
        const url = API_PATH_AUTH.agentCreate();
        const formattedBody = { ...body, email: email?.toLowerCase() };
        const response = await baseQuery({
          body: formattedBody,
          method: HTTP_VERBS.POST,
          url,
        });
        return response;
      },
      extraOptions: { maxRetries: 0 },
    }),
    updateAgent: build.mutation({
      invalidatesTags: ['Agent'],
      queryFn: async ({ agencyIata, body, tapEmail }, _api, _extraOptions, baseQuery) => {
        const url = API_PATH_AUTH.agent({ email: tapEmail });
        const formattedBody = {
          ...body,
          tapEmail,
          agencyNumber: agencyIata,
        };
        const response = await baseQuery({
          body: formattedBody,
          method: HTTP_VERBS.PUT,
          url,
        });
        return response;
      },
    }),
  }),
});

const {
  useCreateAgentMutation,
  useGetAgentBookingsQuery,
  useLazyGetAgentQuery,
  usePrefetch: useGetAgentBookingsPrefetch,
  useUpdateAgentMutation,
} = agentApi;

export default agentApi;

export {
  useCreateAgentMutation,
  useGetAgentBookingsPrefetch,
  useGetAgentBookingsQuery,
  useLazyGetAgentQuery,
  useUpdateAgentMutation,
};
