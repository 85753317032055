import { IS_PERMISSIONS_ENABLED } from 'constants/ENV';
import { ROLES } from 'constants/PERMISSIONS';
import { useSelector } from 'react-redux';
import { hasPermission } from 'utils/auth';

const usePermissions = () => {
  const agent = useSelector((state) => state.auth.agent);
  const permissions = IS_PERMISSIONS_ENABLED ? agent?.permissions : undefined;
  const role = ROLES?.[agent?.agentRole];

  const isAdvisor = permissions ? role.value === ROLES.AG.value : false;
  const isManager = permissions ? role.value === ROLES.MG.value : false;
  const isOwner = permissions ? role.value === ROLES.OW.value : false;

  return {
    hasPermission: ({ permission, verb }) => hasPermission({ permission, permissions, verb }),
    permissions,
    role: {
      ...role,
      isAdvisor,
      isManager,
      isOwner,
    },
  };
};

export default usePermissions;
