// TAP specific wrapper for common text, aria-labels, and printing.
import Modal, { ModalBody, ModalFooter, ModalTitle, propTypes } from '@viking-eng/modal/lib/v2';
import {
  ANALYTICS_EVENT_TYPES,
  ANALYTICS_LINK_TYPES,
  ANALYTICS_NAMES_AND_TYPES,
  triggerLinkEvent,
} from 'analytics/Analytics';
import 'common/modal/ModalWrapper.scss';
import { IMAGE_VIKING_LOGO_WHITE_HORIZONTAL } from 'constants/IMAGE';
import useEventListener from 'hooks/useEventListener';
import PropTypes from 'prop-types';

const ModalWrapperByOffice = ({
  children,
  commonLabels,
  hasPrint = false,
  isClosedOnEscOrOverlayClick = true,
  isFullSize,
  onAfterClose,
  onAfterOpen,
  onPrintClick,
  printAriaLabel,
  printLabel,
  ...rest
}) => {
  useEventListener('keydown', (event) => {
    if (hasPrint && onPrintClick && (event.ctrlKey || event.metaKey) && event.key === 'p') {
      event.preventDefault();
      onPrintClick();
    }
  });

  useEventListener('click', (event) => {
    const source = event.target || event.srcElement;
    const analyticsCloseEventName =
      source.nodeName.toString().indexOf('path') > -1 || source.nodeName.toString().indexOf('svg') > -1
        ? 'close'
        : 'close_background';
    triggerLinkEvent({
      event: ANALYTICS_EVENT_TYPES.HOMEPAGE_MODAL_CLOSE,
      linkType: ANALYTICS_LINK_TYPES.HOMEPAGE_MODAL_CLOSE,
      modal_name: ANALYTICS_NAMES_AND_TYPES.HOMEPAGE_MODAL_NAME,
      modal_type: ANALYTICS_NAMES_AND_TYPES.HOMEPAGE_MODAL_TYPE,
      cta_name: analyticsCloseEventName,
      linkText: analyticsCloseEventName,
    });
  });

  return (
    <Modal
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
      closeAriaLabel={commonLabels?.general['modal-close-aria']}
      forceAction={!isClosedOnEscOrOverlayClick}
      isFullSize
      onAfterClose={(args) => {
        document.body.classList.remove('modal-scroll-not-allowed');
        if (onAfterClose) {
          onAfterClose(args);
        }
      }}
      onAfterOpen={(args) => {
        document.body.classList.add('modal-scroll-not-allowed');
        if (onAfterOpen) {
          onAfterOpen(args);
        }
      }}
      onPrint={hasPrint ? onPrintClick : undefined}
      printAriaLabel={hasPrint ? printAriaLabel || commonLabels?.general['print-aria'] || 'PRINT' : undefined}
      printHeaderImageUrl={IMAGE_VIKING_LOGO_WHITE_HORIZONTAL}
      printLabel={hasPrint ? printLabel || commonLabels?.general.print || 'PRINT' : undefined}
    >
      <div>{children}</div>
    </Modal>
  );
};

ModalWrapperByOffice.propTypes = {
  commonLabels: PropTypes.shape({}).isRequired,
  hasPrint: PropTypes.bool,
  isClosedOnEscOrOverlayClick: PropTypes.bool,
  isFullSize: PropTypes.bool,
  ...propTypes,
};

export default ModalWrapperByOffice;

export { ModalBody, ModalFooter, ModalTitle };
