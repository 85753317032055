import Button from '@viking-eng/button';
import Content from 'common/a11y/Content';
import useEventListener from 'hooks/useEventListener';
import useLayoutPrint from 'hooks/useLayoutPrint';
import PropTypes from 'prop-types';

const PrintButton = ({ contentToPrintSelector = 'main', documentTitle, id = 'main-content', printLabel = 'Print' }) => {
  const { onPrintClick } = useLayoutPrint({ contentToPrintSelector, documentTitle, id, removeAfterPrint: true });

  useEventListener('keydown', (event) => {
    if (onPrintClick && (event.ctrlKey || event.metaKey) && event.key === 'p') {
      event.preventDefault();
      onPrintClick();
    }
  });

  return (
    <div className="float-right">
      <Button className="text-viking-black text-uppercase" id="print" onButtonClick={onPrintClick}>
        <Content config={{ html: printLabel }} type="span" />
      </Button>
    </div>
  );
};

PrintButton.propTypes = {
  contentToPrintSelector: PropTypes.string,
  documentTitle: PropTypes.string,
  id: PropTypes.string,
  printLabel: PropTypes.string,
};

export default PrintButton;
