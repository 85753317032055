import { OFFICES } from 'constants/COUNTRIES';
import { getCmsOfficeCode } from 'pages/account/accountHelper';

const getOneTrust = async () => {
  return new Promise((resolve) => {
    const MAX_TRIES = 20;
    const INTERVAL_MILLISECONDS = 200;
    let oneTrust = window?.OneTrust;
    if (oneTrust === undefined) {
      let count = 0;
      const otCancelToken = setInterval(() => {
        oneTrust = window?.OneTrust;
        count += 1;
        if (oneTrust || MAX_TRIES === count) {
          clearInterval(otCancelToken);
          resolve(oneTrust);
        }
      }, INTERVAL_MILLISECONDS);
    } else {
      resolve(oneTrust);
    }
  });
};

const getOneTrustOffice = async () => {
  const oneTrust = await getOneTrust();
  const geoLocationData =
    typeof oneTrust?.getGeolocationData === 'function' ? oneTrust?.getGeolocationData() : { country: OFFICES.US };
  return getCmsOfficeCode({
    geoLocationCountry: geoLocationData?.country?.toUpperCase(),
    defaultOffice: OFFICES.US,
  });
};

export { getOneTrust, getOneTrustOffice };
