import { useGetAgentBookingsPrefetch } from 'app/services/tap/agent';
import { useGetCmsPrefetch } from 'app/services/tap/cms';
import { useGetSearchDataPrefetch } from 'app/services/tap/search';
import { useGetShipStateroomCategoriesPrefetch } from 'app/services/tap/ships';
import { useGetStateroomsPrefetch } from 'app/services/tap/staterooms';
import { useGetVoyagesPrefetch } from 'app/services/tap/voyages';
import { CMS_PATHS, PREFETCH_ENDPOINTS_BY_ACTION_KEY, PREFETCH_ENDPOINTS_BY_ROUTE_KEY } from 'constants/API';
import { CMS_PATHS_BY_ROUTE_KEY } from 'constants/CMS';
import { CURRENCY_BY_COUNTRY, OFFICE_UNKNOWN } from 'constants/COUNTRIES';
import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';

const usePrefetch = () => {
  const { agency, agent, token } = useSelector((state) => state?.auth) || {};
  const { currency, office } = agency || { currency: CURRENCY_BY_COUNTRY.US.code, office: OFFICE_UNKNOWN };
  const { agentRoleToken, email } = agent || {};
  const cmsOffice = agency?.cmsOffice || OFFICE_UNKNOWN;

  const prefetchAgentBookings = useGetAgentBookingsPrefetch('getAgentBookings');
  const prefetchCmsContent = useGetCmsPrefetch('getCms');
  const prefetchSearchData = useGetSearchDataPrefetch('getSearchData');
  const prefetchShipStaterooms = useGetShipStateroomCategoriesPrefetch('getShipStateroomCategories');
  const prefetchStaterooms = useGetStateroomsPrefetch('getStaterooms');
  const prefetchVoyages = useGetVoyagesPrefetch('getVoyages');

  const onAgentBookingsPrefetch = useCallback(
    () => email && prefetchAgentBookings({ agentRoleToken, email, token }),
    [agentRoleToken, email, prefetchAgentBookings, token]
  );

  const onSearchDataPrefetch = useCallback(
    () => currency && prefetchSearchData({ currency }),
    [currency, prefetchSearchData]
  );

  const onShipStateroomsPrefetch = useCallback(
    ({ shipId }) => shipId && prefetchShipStaterooms({ shipId }),
    [prefetchShipStaterooms]
  );

  const onStateroomsPrefetch = useCallback(
    ({ offerCode, voyageId }) => voyageId && prefetchStaterooms({ currency, offerCode, office, voyageId }),
    [currency, office, prefetchStaterooms]
  );

  const onVoyagesPrefetch = useCallback(
    ({ offerCode, voyageNameBase64 }) =>
      voyageNameBase64 && prefetchVoyages({ currency, offerCode, office, voyageNameBase64 }),
    [currency, office, prefetchVoyages]
  );

  const prefetchCmsData = useCallback(
    (routeKey) => {
      if (CMS_PATHS_BY_ROUTE_KEY[routeKey]?.length) {
        CMS_PATHS_BY_ROUTE_KEY[routeKey].forEach((cmsPath) => prefetchCmsContent({ cmsPath, office: cmsOffice }));
      } else {
        const cmsPath = CMS_PATHS?.pages?.[routeKey];
        if (cmsPath) {
          prefetchCmsContent({ cmsPath, office: cmsOffice });
        }
      }
    },
    [cmsOffice, prefetchCmsContent]
  );

  const PREFETCH_FUNCTIONS = useMemo(
    () => ({
      getAgentBookings: onAgentBookingsPrefetch,
      getSearchData: onSearchDataPrefetch,
      getShipStateroomCategories: onShipStateroomsPrefetch,
      getStaterooms: onStateroomsPrefetch,
      getVoyages: onVoyagesPrefetch,
    }),
    [onAgentBookingsPrefetch, onSearchDataPrefetch, onShipStateroomsPrefetch, onStateroomsPrefetch, onVoyagesPrefetch]
  );

  const prefetchDataForEndpoint = useCallback(
    (endpointName) => PREFETCH_FUNCTIONS[endpointName] || (() => {}),
    [PREFETCH_FUNCTIONS]
  );

  const prefetchDataForEndpoints = useCallback(
    (endpointNames = []) =>
      (props) =>
        endpointNames.forEach((endpointName) => prefetchDataForEndpoint(endpointName)(props)),
    [prefetchDataForEndpoint]
  );

  const prefetchData = useMemo(
    () => ({
      prefetchStateroomDetails: (props) =>
        prefetchDataForEndpoints(PREFETCH_ENDPOINTS_BY_ACTION_KEY.hoverStateroomDetails)(props),
      prefetchItineraryData: (props) =>
        prefetchDataForEndpoints(PREFETCH_ENDPOINTS_BY_ACTION_KEY.hoverViewDates)(props),
      prefetchPricingData: (props) =>
        prefetchDataForEndpoints(PREFETCH_ENDPOINTS_BY_ACTION_KEY.hoverViewPricing)(props),
      prefetchDataByRouteKey: (routeKey, props) => {
        prefetchCmsData(routeKey);
        if (PREFETCH_ENDPOINTS_BY_ROUTE_KEY[routeKey]?.length) {
          prefetchDataForEndpoints(PREFETCH_ENDPOINTS_BY_ROUTE_KEY[routeKey])(props);
        }
      },
    }),
    [prefetchCmsData, prefetchDataForEndpoints]
  );

  return { ...prefetchData };
};

export default usePrefetch;
