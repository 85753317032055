import accounting from 'accounting';

const base64Decode = (base64String) => atob(base64String);

const base64Encode = (string) => btoa(string).replace(/=+$/, '');

const codify = (value) => {
  if (typeof value === 'undefined') {
    return '';
  }
  if (value == null) {
    return '';
  }
  return value
    .replace(/[^a-zA-Z0-9À-ž-_\d\s]/gi, '')
    .replace(/[^a-zA-Z0-9À-ž-_\d]/gi, '_')
    .replace('__', '_')
    .toLowerCase();
};

const deriveTokenMap = ({ sourceStrings = {}, sourceTokenMap = {}, outputKeys = {} }) => {
  const tokenMap = {};
  const replaceTokens = getTokenReplacer(sourceTokenMap);
  Object.entries(sourceStrings).forEach(([key, value]) => {
    tokenMap[outputKeys[key] || key] = replaceTokens({ baseString: value });
  });
  return tokenMap;
};

const formatCurrency = (amt, symbol, decimal = 2) => accounting.formatMoney(amt, symbol, decimal);

const getTokenReplacer = (tokenMap = {}) => {
  return ({ baseString = '' }) => {
    Object.entries(tokenMap).forEach(([token, tokenValue]) => {
      baseString = baseString.replaceAll(token, tokenValue);
    });
    return baseString;
  };
};

const isBoolean = (val) => [true, 1, 'true', '1', 'Y', 'yes', false, 0, 'false', '0', 'N', 'no'].includes(val);
const isTrue = (val) => [true, 1, 'true', '1', 'Y', 'yes'].includes(val);

const keyify = (inputString) =>
  codify(inputString || '')
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '');

const removePTags = (inputText) => {
  return typeof inputText === 'string' ? (inputText || '').replace('<p>', '').replace('</p>', '') : inputText;
};

const replaceToken = (source, key, value) => {
  return source.replace(`\${${key}}`, value);
};

const replaceAllTokens = ({ content = '', tokens = {} } = {}) => {
  Object.entries(tokens).forEach(([key, value]) => {
    content = content.replaceAll(`{{${key}}}`, value);
  });
  return content;
};

const stringCompare = (string1, string2) =>
  (string1 || '').trim().toUpperCase() === (string2 || '').trim().toUpperCase();

const stripHtml = (html) => html?.replace(/(<([^>]+)>)/gi, '');

const stripOuterHtml = (html) => {
  if (html) {
    const element = document.createElement('div');
    element.innerHTML = html;
    return element.firstChild.textContent;
  }
  return html;
};

export {
  base64Decode,
  base64Encode,
  deriveTokenMap,
  formatCurrency,
  getTokenReplacer,
  isBoolean,
  isTrue,
  keyify,
  removePTags,
  replaceAllTokens,
  replaceToken,
  stringCompare,
  stripHtml,
  stripOuterHtml,
};
