import ChevronLeft from '@viking-eng/icon/lib/ChevronLeft';
import { setIsRedirectModalEnabled, setRedirectPage } from 'app/slices/redirect/redirectSlice';
import classNames from 'classnames';
import Content from 'common/a11y/Content';
import AnalyticsRoute from 'common/analytics/AnalyticsRoute';
import ErrorBoundary from 'common/error/ErrorBoundary';
import LayoutError from 'common/error/LayoutError';
import RootError from 'common/error/RootError';
import Footer from 'common/layout/Footer';
import Header from 'common/layout/Header';
import LayoutPrintProvider from 'common/layout/LayoutPrint';
import PrintButton from 'common/layout/PrintButton';
import Link from 'common/link/Link';
import RedirectProvider from 'common/link/RedirectContext';
import LoadingSpinner from 'common/loadingSpinner/LoadingSpinner';
import MaintenanceCheck from 'common/maintenanceCheck/MaintenanceCheck';
import PageTitle from 'common/pageTitle/PageTitle';
import ScrollReset from 'common/scrollReset/ScrollReset';
import { CMS_PATHS } from 'constants/API';
import ROUTES, { BASE_PATH, HOME_BASE, HOME_KEY } from 'constants/ROUTES';
import useCmsState from 'hooks/useCmsState';
import useHistory from 'hooks/useHistory';
import useNavigate from 'hooks/useNavigate';
import useRoute from 'hooks/useRoute';
import PropTypes from 'prop-types';
import { Suspense, useEffect, useLayoutEffect, useState } from 'react';
import { ErrorBoundary as ReactErrorBoundary } from 'react-error-boundary';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { integrateRouting } from 'utils/auth';
import lazyWithReload from 'utils/lazyWithReload';
import { removeLoaderWithTimer } from 'utils/loader';
import { logError } from 'utils/logging';
import { getRouteCommonData } from 'utils/routing';
import './Layout.scss';
import Main from './Main';
import PreChecksPrivate from './PreChecksPrivate';

const BannerCarousel = lazyWithReload(() => import('common/bannerCarousel/BannerCarousel'));
const DocumentVisibilityHandler = lazyWithReload(() => import('./DocumentVisibilityHandler'));
const LivePerson = lazyWithReload(() => import('common/livePerson/LivePerson'));

const Layout = ({ children, isPublic = false }) => {
  const { hasHistory, isLastHome, lastPathName } = useHistory();
  const { isRedirectModalEnabled, redirectToPage } = useSelector((state) => state.redirectToPage);
  const dispatch = useDispatch();
  const [documentTitle, setDocumentTitle] = useState('');
  const location = useLocation();
  const navigate = useNavigate();
  const route = useRoute();

  const data = useCmsState({ cmsPath: CMS_PATHS.pages.common });
  const { longText, title } = getRouteCommonData({ route, commonData: data?.common });
  const { pageName, pageTitle, pageType } = data?.common?.cms?.homePageModal || {};
  const hpModalKey = [pageName, pageTitle, pageType].join('-');

  integrateRouting(navigate);

  useEffect(() => {
    // HACK: Ensures modal won't appear after navigating between MVJ
    // Should work with getMVJTokenAndRedirect in BookingsTableRow, but intermittently it doesn't work.
    dispatch(setIsRedirectModalEnabled(false));
    dispatch(setRedirectPage(''));
  }, [dispatch]);

  useEffect(() => {
    if (route) {
      const siteTitle = data?.common?.strings?.labels?.general?.appName || 'Travel Advisor Portal';
      const docTitle = `${siteTitle}${longText ? ` - ${longText}` : ''}`;

      setDocumentTitle(docTitle);
      document.title = docTitle;
    }
  }, [data, longText, route]);

  useLayoutEffect(() => {
    removeLoaderWithTimer();
  }, []);

  if (!route) {
    return <Navigate to={`${BASE_PATH}${ROUTES.notFound.url}`} state={{ from: location }} replace />;
  }

  const isHomeLink = isLastHome || !hasHistory || route.key === ROUTES.bookings.key;

  return (
    <ReactErrorBoundary FallbackComponent={RootError} onError={(error, info) => logError('App', { error, info })}>
      <ErrorBoundary errorSource="Layout" FallbackComponent={LayoutError}>
        <LayoutPrintProvider>
          <Suspense fallback={null}>
            <DocumentVisibilityHandler />
            <LivePerson />
            <ScrollReset />
            <MaintenanceCheck>
              <AnalyticsRoute>
                <RedirectProvider>
                  {route.isHeaderVisible && (
                    <span className={classNames({ invisible: !route.isLayoutHeaderVisible })}>
                      <Header>{route.key === HOME_KEY && <BannerCarousel />}</Header>
                    </span>
                  )}
                  <div className={`layout-container ${route?.key} ${route.isHeaderVisible ? '' : ' header-invisible'}`}>
                    {!!redirectToPage && isRedirectModalEnabled ? (
                      <LoadingSpinner />
                    ) : (
                      <Main isPublic={isPublic}>
                        {route && route.key !== HOME_KEY && (
                          <div className={classNames('bread-crumbs', { invisible: !route.isHeaderVisible })}>
                            <div className="row align-items-center h-100">
                              <div className="col-2 mx-auto">
                                {route.isBreadcrumbsVisible && (
                                  <Link
                                    className="text-viking-black text-uppercase bread-crumb-links"
                                    to={isHomeLink ? HOME_BASE : lastPathName}
                                  >
                                    <ChevronLeft />
                                    <span className="bread-crumbs-home-btn">
                                      <Content
                                        config={{
                                          html: isHomeLink
                                            ? data?.common?.strings?.labels?.general?.home
                                            : data?.common?.strings?.labels?.general?.back,
                                        }}
                                        type="span"
                                      />
                                    </span>
                                  </Link>
                                )}
                              </div>
                              {route.isPageTitleVisible && (
                                <div className="col-8 text-center">
                                  <PageTitle fallbackTitle={longText || title} />
                                </div>
                              )}
                              <div className="col-2 mx-auto">
                                {route.isPrintLinkVisible && (
                                  <PrintButton
                                    contentToPrintSelector=".main"
                                    documentTitle={documentTitle}
                                    printLabel={data?.common?.strings?.labels?.general?.printLabel}
                                  />
                                )}
                              </div>
                            </div>
                            {route.isPageTitleVisible && <hr />}
                          </div>
                        )}
                        <div className="main text-break">
                          <PageTitle fallbackTitle={longText || title} isForPrintTitle />
                          {children || <Outlet />}
                        </div>
                      </Main>
                    )}
                  </div>
                  <PreChecksPrivate hpModalKey={hpModalKey} />
                </RedirectProvider>
                {route.isFooterVisible && <Footer />}
              </AnalyticsRoute>
            </MaintenanceCheck>
          </Suspense>
        </LayoutPrintProvider>
      </ErrorBoundary>
    </ReactErrorBoundary>
  );
};

Layout.propTypes = {
  children: PropTypes.node,
  isPublic: PropTypes.bool,
};

export default Layout;
