import { useIsAuthenticated } from '@azure/msal-react';
import { prepareHtml } from '@viking-eng/utils/lib';
import { ANALYTICS_EVENT_TYPES, ANALYTICS_LINK_TYPES, triggerLinkEvent } from 'analytics/Analytics';
import { handleRedirectLinks } from 'common/link/redirectLinkHelper';
import { SSO_MOBILE_ENABLED } from 'constants/API';
import { BASE_PATH } from 'constants/ROUTES';
import useRedirectClick from 'hooks/useRedirectClick';
import PropTypes from 'prop-types';
import { createElement, memo, useCallback, useEffect, useId, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { logWarning } from 'utils/logging';
import { isLinkUrl, isRedirectUrl, onNavigate } from 'utils/routing';
import { keyify } from 'utils/string';

const buttonLinkStyleClasses = 'viking-button btn btn-secondary-blue';
const anchorLinkStyleClasses = 'viking-button btn btn-link';

const getNormalizedUrl = (href = '') => {
  const redirectIndex = href.indexOf('[API_PATH]');
  if (redirectIndex !== -1) {
    return href.slice(redirectIndex);
  }
  const relativeSeparator = redirectIndex > -1 ? '' : '/';
  const normalizedUrl = href.replace(`${window.location.origin}${BASE_PATH}/`, relativeSeparator);
  return normalizedUrl?.endsWith('/') ? normalizedUrl.slice(0, -1) : normalizedUrl;
};

const Content = ({
  anchorClassName = '',
  ariaLabel = '',
  buttonClassName = '',
  className,
  config,
  id = '',
  isAnchorStyleAdded = true,
  shouldPrepareHtml = false,
  type,
  ...rest
}) => {
  const useRedirectClickValues = useRedirectClick();
  const isAuthenticated = useIsAuthenticated();
  const navigate = useNavigate();
  const parentRef = useRef();
  const reactId = useId();
  const key = keyify(ariaLabel || id || reactId) || '';

  // eslint-disable-next-line no-underscore-dangle
  const htmlString = typeof config.html === 'string' ? config.html : config.html?.__html;
  const htmlObj = htmlString && shouldPrepareHtml ? prepareHtml(htmlString) : { __html: htmlString };

  const getClassesByNodeType = useCallback(
    (nodeType) => {
      if (!['a', 'button'].includes(nodeType?.toLowerCase())) {
        return '';
      }
      const linkStyleClasses = nodeType === 'button' ? buttonLinkStyleClasses : anchorLinkStyleClasses;
      const additionalClasses = nodeType === 'button' ? buttonClassName : anchorClassName;
      return `${isAnchorStyleAdded ? linkStyleClasses : ''} ${additionalClasses}`;
    },
    [anchorClassName, buttonClassName, isAnchorStyleAdded]
  );

  const getOnRedirectClickListener = useCallback(
    ({ url }) => {
      return (event) => {
        handleRedirectLinks({ isRedirectModalEnabled: true, redirectUrl: url, useRedirectClickValues });
        event.preventDefault();
      };
    },
    [useRedirectClickValues]
  );

  const getOnLinkClickListener = useCallback(
    ({ anchorText = '', url }) => {
      return (event) => {
        triggerLinkEvent({
          event: ANALYTICS_EVENT_TYPES.BUTTON_CLICK,
          linkText: keyify(anchorText),
          linkType: ANALYTICS_LINK_TYPES.LINK,
        });
        onNavigate({ evaluateAuthStatus: true, isAuthenticated, navigate, url });
        event.preventDefault();
        return false;
      };
    },
    [isAuthenticated, navigate]
  );

  const processLinkContent = useCallback(
    (currentRefValue) => {
      currentRefValue.querySelectorAll('a, button').forEach((element) => {
        if (!element.dataset?.isProcessed || element.dataset?.isProcessed === 'false') {
          element.setAttribute('data-is-processed', true);
          element.setAttribute(
            'aria-label',
            element.hasAttribute('aria-label') ? element.getAttribute('aria-label') : element.textContent
          );
          element.setAttribute(
            'rel',
            element.hasAttribute('rel') ? element.getAttribute('rel') : 'noopener noreferrer'
          );

          if (element.nodeName === 'A') {
            const url = getNormalizedUrl(element.href);
            element.setAttribute('data-has-click-listener', true);
            element.setAttribute('data-normalized-url', url);
            if (isRedirectUrl(url)) {
              element.href = null;
              element.addEventListener('click', getOnRedirectClickListener({ url }));
              element.setAttribute('data-is-redirect-url', true);
              if (!SSO_MOBILE_ENABLED.includes(url)) {
                element.classList.add('unlink');
              }
            } else if (isLinkUrl(url)) {
              element.setAttribute('data-link-text', element.textContent);
              element.addEventListener('click', getOnLinkClickListener({ anchorText: element.textContent, url }));
            }
          }
          element.classList.add(...getClassesByNodeType(element.nodeName).trim().split(' '));
        }
      });
    },
    [getClassesByNodeType, getOnLinkClickListener, getOnRedirectClickListener]
  );

  useEffect(() => {
    if (parentRef.current) {
      processLinkContent(parentRef.current);
    }
  }, [parentRef, processLinkContent]);

  if (!config?.html) {
    logWarning('Content component - config.html is empty.', {
      anchorClassName,
      ariaLabel,
      buttonClassName,
      className,
      config,
      type,
      ...rest,
    });
    return null;
  }

  if (!type) {
    logWarning('Content component - type is empty.', config);
    return config.html;
  }

  return createElement(type, {
    'aria-label': ariaLabel,
    className,
    dangerouslySetInnerHTML: htmlObj,
    ref: parentRef,
    key,
    ...rest,
  });
};

Content.propTypes = {
  anchorClassName: PropTypes.string,
  ariaLabel: PropTypes.string,
  buttonClassName: PropTypes.string,
  className: PropTypes.string,
  config: PropTypes.shape({
    html: PropTypes.string,
  }).isRequired,
  id: PropTypes.string,
  isAnchorStyleAdded: PropTypes.bool,
  shouldPrepareHtml: PropTypes.bool,
  type: PropTypes.oneOf([
    'abbr',
    'article',
    'aside',
    'blockquote',
    'div',
    'h1',
    'h2',
    'h3',
    'h4',
    'h5',
    'h6',
    'i',
    'label',
    'li',
    'p',
    'span',
    'strong',
    'td',
    'th',
  ]),
};

export default memo(Content);
