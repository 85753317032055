import { ANALYTICS_EVENT_TYPES, ANALYTICS_LINK_TYPES, triggerLinkEvent } from 'analytics/Analytics';
import { LayoutPrintContext } from 'common/layout/LayoutPrint';
import { useContext, useEffect, useRef, useState } from 'react';
import { useReactToPrint } from 'react-to-print';

const useLayoutPrint = ({ beforeEventHandler, contentToPrintSelector, documentTitle, id, ...rest } = {}) => {
  if (!id) {
    throw new Error('useLayoutPrint - id is required');
  }

  const { beforePrintHandlers, setBeforePrintHandlers, printConfigs, setPrintConfigs, onPrintStore, setOnPrintStore } =
    useContext(LayoutPrintContext);
  const [isPrinting, setIsPrinting] = useState(false);
  const promiseResolveRef = useRef(null);

  useEffect(() => {
    if (beforeEventHandler) {
      setBeforePrintHandlers({
        ...beforePrintHandlers,
        [id]: beforeEventHandler,
      });
    }

    if (contentToPrintSelector) {
      setPrintConfigs({
        ...printConfigs,
        [id]: {
          content: () => document.querySelector(contentToPrintSelector),
          documentTitle: documentTitle || document.title || '',
          onBeforeGetContent: async () => {
            if (beforePrintHandlers) {
              const promises = Object.values(beforePrintHandlers || {}).map((handler) => handler());
              await Promise.all(promises);
            }
          },
          onBeforePrint: () => {
            return new Promise((resolve) => {
              promiseResolveRef.current = resolve;
              setIsPrinting(true);
            });
          },
          onAfterPrint: () => {
            promiseResolveRef.current = null;
            setIsPrinting(false);
          },
          ...rest,
        },
      });
    }

    return () => {
      if (beforeEventHandler && beforePrintHandlers) {
        const { [id]: _, ...restPrintHandlers } = beforePrintHandlers;
        setBeforePrintHandlers(restPrintHandlers);
      }

      if (contentToPrintSelector && printConfigs) {
        const { [id]: _, ...restPrintConfigs } = printConfigs;
        setPrintConfigs(restPrintConfigs);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isPrinting && promiseResolveRef.current) {
      promiseResolveRef.current();
    }
  }, [isPrinting]);

  const onPrint = useReactToPrint(printConfigs[id]);

  useEffect(() => {
    if (printConfigs[id]) {
      setOnPrintStore({
        ...onPrintStore,
        [id]: () => {
          triggerLinkEvent({
            event: ANALYTICS_EVENT_TYPES.BUTTON_CLICK,
            linkType: ANALYTICS_LINK_TYPES.TEXT,
            cta_name: 'print',
          });
          onPrint();
        },
      });
    }

    return () => {
      if (onPrint && onPrintStore) {
        const { [id]: _, ...restOnPrintStore } = onPrintStore;
        setOnPrintStore(restOnPrintStore);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onPrint, printConfigs[id]]);

  return { onPrintClick: onPrintStore[id] };
};

export default useLayoutPrint;
