import PropTypes from 'prop-types';
import './FooterPrivacyModal.scss';
import ModalWrapperByOffice from './ModalWrapperByOffice';

const FooterPrivacyModal = ({
  commonLabels,
  footerPrivacyModalData,
  isPrivacyModalOpen,
  onClosePrivacyModal,
  title,
}) => {
  return (
    <div className="footer-privacy-modal-container">
      <ModalWrapperByOffice
        className="footer-privacy-modal"
        commonLabels={commonLabels}
        forceAction
        isOpen={isPrivacyModalOpen}
        onClose={onClosePrivacyModal}
      >
        <div className="privacy-modal-title">
          <h2 dangerouslySetInnerHTML={{ __html: title }} />
        </div>
        {footerPrivacyModalData?.map((item) => (
          <div className="section" key={item.id || item.title || item.description}>
            {item.title && <div className="privacy-item-title" dangerouslySetInnerHTML={{ __html: item.title }} />}
            {item.description && (
              <div className="privacy-item-text" dangerouslySetInnerHTML={{ __html: item.description }} />
            )}
          </div>
        ))}
      </ModalWrapperByOffice>
    </div>
  );
};

FooterPrivacyModal.propTypes = {
  commonLabels: PropTypes.shape({}).isRequired,
  footerPrivacyModalData: PropTypes.arrayOf(
    PropTypes.shape({
      description: PropTypes.string,
      id: PropTypes.string,
      title: PropTypes.string,
    })
  ),
  isPrivacyModalOpen: PropTypes.bool,
  onClosePrivacyModal: PropTypes.func.isRequired,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

export default FooterPrivacyModal;
