const KEY_EVENT_CODES = Object.freeze({
  arrowDown: 'ArrowDown',
  arrowLeft: 'ArrowLeft',
  arrowRight: 'ArrowRight',
  arrowUp: 'ArrowUp',
  backspace: 'Backspace',
  delete: 'Delete',
  enter: 'Enter',
  space: 'Space',
});

const FOCUS_KEY_EVENT_CODES = Object.freeze([KEY_EVENT_CODES.enter, KEY_EVENT_CODES.space]);

export { FOCUS_KEY_EVENT_CODES, KEY_EVENT_CODES };
