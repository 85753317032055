import { InteractionRequiredAuthError } from '@azure/msal-browser';
import { setAuth } from 'app/slices/auth/authSlice';
import PageLoading from 'common/pageLoading/PageLoading';
import { b2cPolicies, msalConfig } from 'configs/authConfig';
import { BROADCAST_CHANNEL_VALUES } from 'constants/CONFIG';
import { IS_LOCAL_AUTH_LOGGING_ENABLED } from 'constants/LOGGING';
import ROUTES, { BASE_PATH } from 'constants/ROUTES';
import useAuth from 'hooks/useAuth';
import useEventListener from 'hooks/useEventListener';
import MaintenanceWrapper from 'pages/maintenance/MaintenanceWrapper';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAccount, getToken } from 'utils/auth';
import { localLogger, logError, logInfo } from 'utils/logging';
import { getRouteFromPathname } from 'utils/routing';
import { setUpInterval } from 'utils/timer';
import {
  AUTH_ERRORS,
  AUTH_LOGIN_MAX_AUTO_TRIES,
  AUTH_STATUS,
  AUTH_TOKEN_INTERVAL_MILLISECONDS,
} from './AUTH_CONSTANTS';

const AuthContainer = ({ children }) => {
  const auth = useSelector((state) => state.auth);
  const [authStatus, setAuthStatus] = useState(AUTH_STATUS.gettingAuth);
  const [error, setError] = useState();
  const { getLoginTries, login, logout } = useAuth();
  const cancelTokenRef = useRef('');
  const dispatch = useDispatch();
  const route = getRouteFromPathname(window.location.pathname);
  const broadcastAccountRemoved = useMemo(() => new BroadcastChannel(BROADCAST_CHANNEL_VALUES.accountRemoved), []);
  const broadcastBecameVisible = useMemo(() => new BroadcastChannel(BROADCAST_CHANNEL_VALUES.becameVisible), []);

  useEffect(() => {
    return () => {
      if (cancelTokenRef.current) {
        clearInterval(cancelTokenRef.current);
      }
    };
  }, []);

  useEffect(() => {
    try {
      setAuthError({ callingFunction: 'useEffect' });
      if (authStatus === AUTH_STATUS.gettingAuth) {
        dispatch(setAuth({ callingFunction: 'AuthContainer - useEffect' }));
        getMsalAuth();
        logInfo('AuthContainer - useEffect2 - success - gettingAuth');
      } else if (authStatus === AUTH_STATUS.gettingToken) {
        handleToken();
        logInfo('AuthContainer - useEffect2 - success - gettingToken');
      } else {
        throw new Error('AuthContainer - useEffect2 - authStatus must always be an AUTH_STATUS value', { authStatus });
      }
    } catch (err) {
      logError('AuthContainer - useEffect2', error);
      setAuthError({ callingFunction: 'useEffect (catch)', err });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authStatus, dispatch, route]);

  useEffect(() => {
    const accountRemoved = (event) => {
      if (event.data.name === BROADCAST_CHANNEL_VALUES.accountRemoved) {
        clearInterval(cancelTokenRef.current);
      }
    };
    broadcastAccountRemoved.addEventListener('message', accountRemoved);
    return () => {
      broadcastAccountRemoved.removeEventListener('message', accountRemoved);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const becameVisible = async (event) => {
      if (event.data.name === BROADCAST_CHANNEL_VALUES.becameVisible) {
        let tokenResponse;
        try {
          tokenResponse = await getToken();
        } catch (err) {
          if (err.message !== AUTH_ERRORS.IS_USER_NOT_SIGNED_IN) {
            logError('AuthContainer - becameVisible', err);
          } else {
            logInfo('AuthContainer - becameVisible', err);
          }
        }
        if (!tokenResponse?.accessToken) {
          logout({ callingFunction: 'getMsalAuth - token refresh failure' });
        }
      }
    };
    broadcastBecameVisible.addEventListener('message', becameVisible);
    return () => {
      broadcastBecameVisible.removeEventListener('message', becameVisible);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEventListener('storage', (evt) => {
    try {
      if (evt.key === 'logout-event' && evt.newValue === 'started') {
        localStorage.removeItem('logout-event');
        clearInterval(cancelTokenRef.current);
        if (route.isPublic) {
          logInfo('AuthContainer - useEventListener - reloading');
          window.location.reload();
        } else {
          logInfo('AuthContainer - useEventListener - replacing');
          window.location.replace(`${BASE_PATH}${ROUTES.signedOut.url}`);
        }
      }
    } catch (err) {
      logError('AuthContainer - useEventListener', err);
      setAuthError({ callingFunction: 'useEventListener (catch)', err });
    }
  });

  const setAuthError = useCallback(
    ({ callingFunction = '', err } = {}) => {
      if (err) {
        localLogger(`AuthContainer error caused by ${callingFunction}`, IS_LOCAL_AUTH_LOGGING_ENABLED);
        logError(authStatus, err);
        setError({ authStatus, error: err });
      } else {
        setError();
      }
    },
    [authStatus, setError]
  );

  const getMsalAuth = useCallback(async () => {
    try {
      const account = getAccount();
      if (account?.idTokenClaims?.email) {
        account.tenantProfiles = Object.fromEntries(account.tenantProfiles);
        dispatch(
          setAuth({
            agent: {
              email: account.idTokenClaims.email.toLowerCase(),
              firstName: account.idTokenClaims.given_name,
              lastName: account.idTokenClaims.family_name,
              tapEmail: account.idTokenClaims.email.toLowerCase(),
            },
            agency: {
              agencyNumber: account.idTokenClaims.agencyNumber,
            },
            callingFunction: 'AuthContainer - getMsalAuth',
            msal: account,
          })
        );
        setAuthStatus(AUTH_STATUS.gettingToken);
      } else {
        let tokenResponse;
        try {
          tokenResponse = await getToken();
        } catch (err) {
          if (err.message !== AUTH_ERRORS.IS_USER_NOT_SIGNED_IN) {
            logError('AuthContainer - getMsalAuth', err);
          } else {
            logInfo('AuthContainer - getMsalAuth', err);
          }
        }
        if (tokenResponse?.accessToken) {
          window.location.reload(true);
        } else {
          logout({ callingFunction: 'getMsalAuth - token refresh failure' });
        }
      }
      logInfo('AuthContainer - useEventListener - success');
    } catch (e) {
      logError('AuthContainer - useEventListener', e);
      setAuthError({ callingFunction: 'getMsalAuth (catch)', err: e });
    }
  }, [dispatch, logout, setAuthError]);

  const refreshToken = useCallback(async () => {
    let tokenResponse;
    try {
      tokenResponse = await getToken();
      if (tokenResponse?.accessToken) {
        dispatch(setAuth({ callingFunction: 'AuthContainer - refreshToken', token: tokenResponse?.accessToken }));
        return tokenResponse;
      }
      await login({ callingFunction: 'AuthContainer - refreshToken', loginRequest: msalConfig });
    } catch (tokenErr) {
      if (tokenErr instanceof InteractionRequiredAuthError) {
        try {
          tokenResponse = await getToken({
            authority: b2cPolicies.authorities.signUpSignInWithPwd,
          });
          // eslint-disable-next-line no-empty
        } catch {}
        if (tokenResponse?.accessToken) {
          dispatch(
            setAuth({
              callingFunction: 'AuthContainer - refreshToken (catch)',
              token: tokenResponse?.accessToken,
            })
          );
          return tokenResponse;
        }
      } else if (tokenErr.message !== AUTH_ERRORS.IS_USER_NOT_SIGNED_IN) {
        logError('AuthContainer - refreshToken', tokenErr);
      } else {
        logInfo('AuthContainer - refreshToken', tokenErr);
      }
      setAuthError({ callingFunction: 'refreshToken (catch)', err: tokenErr });
      await login({ callingFunction: 'AuthContainer - refreshToken (catch)', loginRequest: msalConfig });
    }
    return null;
  }, [dispatch, login, setAuthError]);

  const handleToken = useCallback(async () => {
    try {
      const tokenResponse = await refreshToken();
      dispatch(setAuth({ callingFunction: 'AuthContainer - handleToken', token: tokenResponse?.accessToken }));
      logInfo('AuthContainer - handleToken - success');
    } catch (tokenErr) {
      logError('AuthContainer - handleToken', tokenErr);
      setAuthError({ callingFunction: 'handleToken (catch)', err: tokenErr });
    }

    if (cancelTokenRef.current) {
      clearInterval(cancelTokenRef.current);
    }

    const cancelId = setUpInterval({
      delay: AUTH_TOKEN_INTERVAL_MILLISECONDS,
      fn: async () => {
        await refreshToken();
      },
      refreshId: 0,
    });
    cancelTokenRef.current = cancelId;
  }, [dispatch, refreshToken, setAuthError]);

  useEffect(() => {
    return () => {
      if (cancelTokenRef.current) {
        clearInterval(cancelTokenRef.current);
      }
    };
  }, []);

  useEffect(() => {
    setAuthError({ callingFunction: 'useEffect' });
    try {
      if (authStatus === AUTH_STATUS.gettingAuth) {
        dispatch(setAuth({ callingFunction: 'AuthContainer.jsx - useEffect' }));
        getMsalAuth();
      } else if (authStatus === AUTH_STATUS.gettingToken) {
        handleToken();
      } else {
        throw new Error('Auth - useEffect - authStatus must always be an AUTH_STATUS value', { authStatus });
      }
    } catch (err) {
      setAuthError({ callingFunction: 'useEffect (catch)', err });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authStatus, dispatch]);

  useEffect(() => {
    const accountRemoved = (event) => {
      if (event.data.name === BROADCAST_CHANNEL_VALUES.accountRemoved) {
        clearInterval(cancelTokenRef.current);
      }
    };
    broadcastAccountRemoved.addEventListener('message', accountRemoved);
    return () => {
      broadcastAccountRemoved.removeEventListener('message', accountRemoved);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEventListener('storage', (evt) => {
    try {
      if (evt.key === 'logout-event' && evt.newValue === 'started') {
        localStorage.removeItem('logout-event');
        clearInterval(cancelTokenRef.current);
        if (route.isPublic) {
          window.location.reload();
        } else {
          window.location.replace(`${BASE_PATH}${ROUTES.signedOut.url}`);
        }
      }
    } catch (err) {
      setAuthError({ callingFunction: 'useEventListener (catch)', err });
    }
  });

  if (error && getLoginTries() > AUTH_LOGIN_MAX_AUTO_TRIES) {
    return <MaintenanceWrapper />;
  }

  return authStatus !== AUTH_STATUS.gettingAuth && !!auth.token ? children : <PageLoading />;
};

AuthContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AuthContainer;
