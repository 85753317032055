import { initializeTagData, insertAdobeDtmScript, updateAdobeTargetSettings } from 'analytics/Analytics';
import { useGetMetaQuery } from 'app/services/tap/meta';
import { OFFICES } from 'constants/COUNTRIES';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { insertCspMetaTag } from 'utils/meta';
import { getRouteFromPathname } from 'utils/routing';

const AnalyticsSite = ({ children }) => {
  const { data, isFetching } = useGetMetaQuery() || {};
  const { nonce } = data || {};
  const [cspWasUpdated, setCspWasUpdated] = useState(false);
  const route = getRouteFromPathname(window.location.pathname);

  useEffect(() => {
    if (!cspWasUpdated && !isFetching) {
      insertCspMetaTag(nonce);
      updateAdobeTargetSettings(nonce);
      setCspWasUpdated(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nonce, isFetching]);

  useEffect(() => {
    if (cspWasUpdated) {
      insertAdobeDtmScript(nonce);
      initializeTagData(
        {
          cspNonce: nonce,
          country: OFFICES.US,
          isLoggedIn: false,
          pageId: route.id,
          pageName: route.analyticsTitle || route.title,
          path: window.location.pathname,
          queryParams: window.location.search,
        },
        OFFICES.US,
        false
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cspWasUpdated]);

  return children;
};

AnalyticsSite.propTypes = {
  children: PropTypes.node,
};

export default AnalyticsSite;
