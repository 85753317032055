const fixMockCMSData = ({ cmsData, officeCode, voyageType, application }) => {
  const arrayExcludes = ({ array, value }) =>
    value && Array.isArray(array) && array.length > 0 && !array.includes(value);

  const fixMvjPageGroupCards = (cmsCardData) => {
    const { card } = cmsCardData;
    return { ...cmsCardData, card: fixCardData(card) };
  };

  const fixCardData = (card) => {
    // expects shape { jcrName: 'x', card: { obj } }
    if (
      !card ||
      arrayExcludes({ array: card.officeCode, value: officeCode }) ||
      arrayExcludes({ array: card.voyageType, value: voyageType }) ||
      arrayExcludes({ array: card.application, value: application })
    ) {
      return undefined; // this fixes the jcrName issue
    }

    const isSSOLink = (ctaUrl, sso) =>
      (sso.baseUri && ctaUrl.toLowerCase().includes(sso.baseUri.toLowerCase())) ||
      (sso.altUri && ctaUrl.toLowerCase().includes(sso.altUri.toLowerCase()));

    if (card.callToActionUrl) {
      if (isSSOLink(card.callToActionUrl, { baseUri: 'aspirerewards' })) {
        card.callToActionUrl = '[API_PATH]/agent/rewards';
      } else if (isSSOLink(card.callToActionUrl, { baseUri: 'gage' })) {
        card.callToActionUrl = '[API_PATH]/agent/marketingcenter';
      } else if (isSSOLink(card.callToActionUrl, { baseUri: 'northstar' })) {
        card.callToActionUrl = '[API_PATH]/agent/academy';
      }
    }
    return card;
  };
  const cmsTypeFixMapping = {
    mvjPageGroupCards: fixMvjPageGroupCards,
    cards: fixCardData,
  };
  const addProp = (obj, key, value) => {
    if (!JUNK_CMS.includes(key)) {
      obj[key] = value;
    }
  };
  const JUNK_CMS = ['nodeType', 'path', 'application', 'officeCode', 'voyageType'];
  if (!cmsData) {
    return undefined;
  }
  if (Array.isArray(cmsData)) {
    const ret = [];
    cmsData.forEach((elem) => {
      const fixed = fixMockCMSData({ cmsData: elem, officeCode, voyageType, application });
      if (fixed) {
        ret.push(fixed);
      }
    });
    return ret.filter((elem) => !!elem);
  }

  if (typeof cmsData === 'object') {
    if (officeCode && cmsData?.officeCode?.length && !cmsData.officeCode.includes(officeCode)) {
      return undefined;
    }
    if (voyageType && cmsData?.voyageType?.length && !cmsData.voyageType.includes(voyageType)) {
      return undefined;
    }
    if (cmsTypeFixMapping[cmsData?.nodeType]) {
      const ret = cmsTypeFixMapping[cmsData.nodeType](cmsData);
      return ret;
    }
    const ret = {};
    Object.keys(cmsData).forEach((key) => {
      const prop = cmsData[key];
      if (prop) {
        if (typeof prop === 'object') {
          const fixed = fixMockCMSData({ cmsData: prop, officeCode, voyageType, application });
          if (fixed) {
            addProp(ret, key, fixed);
          }
        } else {
          addProp(ret, key, prop);
        }
      }
    });
    return ret;
  }
  return cmsData;
};

const getCommonData = ({
  commonData,
  evoCountry,
  homePageModalData,
  officeCode,
  propsData,
  stringsData,
  tokenData,
  voyageType,
}) => {
  return {
    cms: {
      common: fixMockCMSData({ cmsData: commonData, officeCode, voyageType }),
      homePageModal: fixMockCMSData({ cmsData: homePageModalData, officeCode, voyageType }),
      token: fixMockCMSData({ cmsData: tokenData, officeCode, voyageType }),
    },
    evoCountry,
    props: { ...propsData },
    strings: stringsData,
  };
};

export { fixMockCMSData, getCommonData };
