import { triggerPageView } from 'analytics/Analytics';
import { OFFICES } from 'constants/COUNTRIES';
import useRoute from 'hooks/useRoute';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { logInfo } from 'utils/logging';

const AnalyticsRoute = ({ children }) => {
  const route = useRoute();
  const { country } = useSelector((state) => state.auth.agency);
  const { pathname } = window.location;

  const delayedPageView = (eventData) => {
    setTimeout(() => {
      logInfo('AnalyticsRoute - delayedPageView - start');
      triggerPageView(eventData);
    }, 1000);
  };

  useEffect(() => {
    if (route) {
      logInfo('----------------------------------');
      logInfo('AnalyticsRoute - useEffect - start', window.location.pathname);
      delayedPageView({
        country: country || OFFICES.US,
        domain_country_code: country || OFFICES.US,
        isLoggedIn: true,
        pageId: route.id,
        pageName: route.analyticsTitle || route.title,
        path: pathname,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [route, pathname]);

  return children;
};

AnalyticsRoute.propTypes = {
  children: PropTypes.node,
};

export default AnalyticsRoute;
