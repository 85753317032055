import { CMS_PATHS } from 'constants/API';
import ROUTES from 'constants/ROUTES';

const CMS_PATHS_BY_ROUTE_KEY = Object.freeze({
  [ROUTES.agencyRegistration.key]: [CMS_PATHS.pages.agencyRegistration],
  [ROUTES.bestBusinessPartner.key]: [CMS_PATHS.pages.bestBusinessPartner],
  [ROUTES.bookingCreate.key]: [CMS_PATHS.pages.bookingCreate],
  [ROUTES.contact.key]: [CMS_PATHS.contacts, CMS_PATHS.pages.contact],
  [ROUTES.faq.key]: [CMS_PATHS.faq, CMS_PATHS.pages.faq],
  [ROUTES.formsGuides.key]: [CMS_PATHS.pages.formsGuides],
  [ROUTES.home.key]: [CMS_PATHS.pages.home, CMS_PATHS.pages.homePageModal],
  [ROUTES.loginFaqs.key]: [CMS_PATHS.faq, CMS_PATHS.pages.faq],
  [ROUTES.platinumClub.key]: [CMS_PATHS.pages.platinumClub],
  [ROUTES.training.key]: [CMS_PATHS.pages.training],
  [ROUTES.whySellViking.key]: [CMS_PATHS.pages.whySellViking],
});

export { CMS_PATHS_BY_ROUTE_KEY };
