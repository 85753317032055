import PropTypes from 'prop-types';

const Main = ({ children, isPublic = false }) => {
  return (
    <main className={`flex-shrink-0${isPublic ? ' h-100' : ''}`}>
      <div className={`container pb-3${isPublic ? ' h-100' : ''}`}>{children}</div>
    </main>
  );
};

Main.propTypes = {
  children: PropTypes.node.isRequired,
  isPublic: PropTypes.bool,
};

export default Main;
