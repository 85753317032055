import Image from '@viking-eng/image';
import PropTypes from '@viking-eng/prop-types';
import { getAccessibilityFunctions } from '@viking-eng/utils';
import { ANALYTICS_EVENT_TYPES, ANALYTICS_LINK_TYPES, triggerLinkEvent } from 'analytics/Analytics';
import classNames from 'classnames';
import RedirectLink from 'common/link/RedirectLink';
import VikingLink from 'common/ui-kit/VikingLink/VikingLink';
import { CMS_REDIRECT_URL_KEYS } from 'constants/API';
import usePrefetch from 'hooks/usePrefetch';
import { useRef } from 'react';
import { getImageHandlerProps } from 'utils/imageUtils';
import { keyify } from 'utils/string';
import './HomeCard.scss';

function HomeCard({
  card: { title, thumbnailUrl, openInNewWindow, routeKey, url },
  noBackground = false,
  sizes: { xs = 12, sm = 12, md = 12, lg = 12 },
  tabIndex = 0,
}) {
  const { prefetchDataByRouteKey } = usePrefetch();
  const redirectLinkRef = useRef();

  const image = getImageHandlerProps({
    image: { src: thumbnailUrl, alt: title },
  });

  const isRedirectLink = Object.values(CMS_REDIRECT_URL_KEYS).includes(url);

  const Link = isRedirectLink ? RedirectLink : VikingLink;

  const onClick = () => {
    triggerLinkEvent({
      event: ANALYTICS_EVENT_TYPES.TILE_CLICK,
      tile_name: keyify(title),
      linkType: ANALYTICS_LINK_TYPES.PHOTO,
      cta_name: keyify(title),
    });
  };

  const linkProps = isRedirectLink
    ? {
        cmsRedirectUrlKey: url,
        buttonProps: { appearance: 'link', onButtonClick: onClick },
        buttonType: 'image',
        ref: redirectLinkRef,
      }
    : {
        ...getAccessibilityFunctions(),
        role: 'button',
        tabIndex,
        target: openInNewWindow ? '_blank' : '',
        url,
        onClick,
      };

  return (
    <div className="home-card">
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <Link {...linkProps} onMouseEnter={() => prefetchDataByRouteKey(routeKey)}>
        <div className="home-card-content row no-gutters">
          <div className={classNames('home-card-image', `col-lg-${lg}`, `col-md-${md}`, `col-sm-${sm}`, `col-${xs}`)}>
            {/* eslint-disable-next-line react/jsx-props-no-spreading */}
            <Image {...image} className="home-card-img img-fluid" />
          </div>
          <div
            className={classNames(
              noBackground ? 'no-background' : '',
              'home-card-text',
              `col-lg-${lg >= 12 ? 12 : 12 - lg}`,
              `col-md-${md >= 12 ? 12 : 12 - md}`,
              `col-sm-${sm >= 12 ? 12 : 12 - sm}`,
              `col-${xs >= 12 ? 12 : 12 - xs}`
            )}
          >
            {title}
          </div>
        </div>
      </Link>
    </div>
  );
}

HomeCard.propTypes = {
  card: PropTypes.card.isRequired,
  noBackground: PropTypes.bool,
  sizes: PropTypes.shape({
    lg: PropTypes.number,
    md: PropTypes.number,
    sm: PropTypes.number,
    xs: PropTypes.number,
  }),
  tabIndex: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default HomeCard;
