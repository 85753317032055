import { useNavigate as reactRouterDomUseNavigate } from 'react-router-dom';
import { onNavigate } from 'utils/routing';

const useNavigate = () => {
  const reactRouterDomNavigate = reactRouterDomUseNavigate();
  const navigate = (url, target, callback) => onNavigate({ callback, navigate: reactRouterDomNavigate, target, url });
  return navigate;
};

export default useNavigate;
