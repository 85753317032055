import { prepareHtml } from '@viking-eng/utils/lib';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import './FieldValidationError.scss';

const FieldValidationError = ({ className, errorMessage }) => (
  <span
    role="alert"
    className={classNames(
      'field-validation-error w-100 d-block text-viking-red xsmall-body-copy validation-error-text',
      className
    )}
    tabIndex={-1}
    dangerouslySetInnerHTML={prepareHtml(errorMessage)}
  />
);

FieldValidationError.propTypes = {
  className: PropTypes.string,
  errorMessage: PropTypes.string.isRequired,
};

export default FieldValidationError;
