const setUpInterval = async ({ cancelId, delay, maxRefreshCount = Infinity, refreshId, fn }) => {
  fn();
  if (cancelId) {
    clearInterval(cancelId);
  }
  if (refreshId) {
    const autoRefreshCount = 0;
    refreshId = autoRefreshCount;
  }
  const intervalId = setInterval(() => {
    const autoRefreshCount = refreshId + 1;
    refreshId = autoRefreshCount;
    if (autoRefreshCount < maxRefreshCount) {
      (async () => {
        await fn();
      })();
    } else {
      clearInterval(cancelId);
    }
  }, delay);
  cancelId = intervalId;
  return cancelId;
};

export { setUpInterval };
