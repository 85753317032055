import { TAP_API_BASE_URL } from 'constants/ENV';
import { DELAY, SEARCH_DATA_ENABLED, SEARCH_DATA_ERROR_ENABLED } from 'constants/MOCK_CONFIG';
import { HttpResponse, delay, http } from 'msw';

const handlers = [];

if (SEARCH_DATA_ENABLED) {
  const url = `${TAP_API_BASE_URL}/search/*`;

  handlers.push(
    http.get(url, async () => {
      const json = await import('mocks/data/search/usd.json');
      await delay(DELAY);
      return HttpResponse.json(json);
    })
  );
}

if (SEARCH_DATA_ERROR_ENABLED) {
  const url = `${TAP_API_BASE_URL}/search/USD?offerCode=ABC`;
  handlers.push(
    http.get(url, async () => {
      await delay(DELAY);
      return new HttpResponse(null, {
        status: 408,
        statusText: 'Timeout',
      });
    })
  );
}

export default handlers;
