import PropTypes from 'prop-types';
import { useLayoutEffect } from 'react';
import { ErrorBoundary as ReactErrorBoundary, useErrorBoundary } from 'react-error-boundary';
import { useLocation } from 'react-router-dom';
import { logError } from 'utils/logging';

const ErrorBoundaryInner = ({ children }) => {
  const { resetBoundary } = useErrorBoundary();
  const location = useLocation();

  useLayoutEffect(() => {
    resetBoundary();
  }, [location, resetBoundary]);

  return children;
};

ErrorBoundaryInner.propTypes = {
  children: PropTypes.node.isRequired,
};

const ErrorBoundary = ({ children, errorSource, FallbackComponent, ...otherReactErrorBoundaryProps }) => {
  return (
    <ReactErrorBoundary
      FallbackComponent={FallbackComponent}
      onError={(error, info) => logError(errorSource, { error, info })}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...otherReactErrorBoundaryProps}
    >
      <ErrorBoundaryInner>{children}</ErrorBoundaryInner>
    </ReactErrorBoundary>
  );
};

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
  errorSource: PropTypes.string.isRequired,
  FallbackComponent: PropTypes.func.isRequired,
};

export default ErrorBoundary;
