import ROUTES, { BASE_PATH } from 'constants/ROUTES';
import PropTypes from 'prop-types';
import { cloneElement } from 'react';
import { Navigate, Outlet, useLoaderData, useLocation } from 'react-router-dom';
import { isEmpty } from 'utils/cms';

const WithCmsData = ({ children }) => {
  const location = useLocation();
  const data = useLoaderData();

  const hasData = data && Object.values(data)?.length && !isEmpty(Object.values(data)?.[0]);
  if (!hasData && !location?.pathname?.includes(ROUTES.notFound.url)) {
    return <Navigate to={`${BASE_PATH}${ROUTES.notFound.url}`} state={{ from: location }} replace />;
  }
  return children ? cloneElement(children, { data }) : <Outlet />;
};

WithCmsData.propTypes = {
  children: PropTypes.node.isRequired,
};

export default WithCmsData;
