import { format as dateFnsFormat, formatISO, parseISO } from 'date-fns';

const DATE_FORMATS = Object.freeze({
  MONTH_DAY_SHORT: 'MMM dd', // Jun 23
  MONTH_DAY_YEAR_SHORT: 'MMM dd, yyyy', // Jun 23, 2024
  DAY_MONTH_YEAR_SHORT: 'dd MMM, yyyy', // 23 Jun, 2024
});

const addDays = (date, days) => {
  if (date instanceof Date) {
    const ret = new Date(date);
    ret.setDate(date.getDate() + days);
    ret.setHours(0, 0, 0);
    return new Date(ret.valueOf() - 24 * 60 * 60 * 1000);
  }
  return date;
};

const addMonths = (date, mons) => {
  if (date instanceof Date) {
    const ret = new Date(date);
    ret.setMonth(date.getMonth() + mons);
    ret.setHours(0, 0, 0);
    return new Date(ret.valueOf() - 24 * 60 * 60 * 1000);
  }
  return date;
};

const addYears = (date, yrs) => {
  if (date instanceof Date) {
    const ret = new Date(date);
    ret.setYear(date.getFullYear() + yrs);
    ret.setHours(0, 0, 0);
    return new Date(ret.valueOf() - 24 * 60 * 60 * 1000);
  }
  return date;
};

const dateDiff = (date1, date2) => {
  const d1 = date1 instanceof Date ? date1 : new Date(date1);
  const d2 = date2 instanceof Date ? date2 : new Date(date2);

  return ((d1 - d2) / (24 * 60 * 60 * 1000)).toFixed(1);
};

const formatDate = ({ date, format = DATE_FORMATS.MONTH_DAY_YEAR_SHORT }) => {
  let ret = '';
  try {
    if (typeof date === 'string') {
      const regexToCheckDateHasTimeString =
        /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d+)?([Zz]|([+-])([01]\d|2[0-3]):?([0-5]\d)?)?/;
      const dateWithTimeString = regexToCheckDateHasTimeString.test(date) ? date : `${date}T00:00:00.000`;
      ret = dateFnsFormat(new Date(dateWithTimeString), format);
    } else {
      ret = dateFnsFormat(date, format);
    }
  } catch {
    ret = '';
  }
  return ret;
};

const getTZOffsetDate = (dateYYYYMMDD) => {
  const timezoneOffsetMinutes = new Date().getTimezoneOffset();
  const timezoneOffset = `${timezoneOffsetMinutes > 0 ? '-' : '+'}${(timezoneOffsetMinutes / 60)
    .toString()
    .padStart(2, '0')}:${(timezoneOffsetMinutes % 60).toString().padStart(2, '0')}`;
  return new Date(`${dateYYYYMMDD}T00:00:00.000${timezoneOffset}`);
};

const getDateFromISOString = (inputDate, options = {}) => {
  return inputDate && !(inputDate instanceof Date) ? parseISO(inputDate, options) : inputDate;
};

const getISOStringFromDate = (inputDate, options = {}) => {
  return inputDate && inputDate instanceof Date ? formatISO(inputDate, options) : inputDate;
};

const getLastDayOfMonth = (date) => {
  if (date instanceof Date) {
    const ret = new Date(date.getFullYear(), date.getMonth() + 1, 1, 0, 0, 0, 0);
    return new Date(ret.valueOf() - 24 * 60 * 60 * 1000);
  }
  return date;
};

const getDateString = (inputDate, locale = 'en-US') => {
  if (inputDate && !(inputDate instanceof Date)) {
    inputDate = getDateFromISOString(inputDate);
  }
  if (inputDate && inputDate instanceof Date) {
    const options = {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    };
    return inputDate?.toLocaleDateString(locale, options);
  }
  return '';
};

const getMaxDate = (date1, date2) => (date1 > date2 ? date1 : date2);
const getMinDate = (date1, date2) => (date1 < date2 ? date1 : date2);

const clampToLowerBound = (inputDate, minDate, maxDate) => getMinDate(getMaxDate(inputDate, minDate), maxDate);
const clampToUpperBound = (inputDate, minDate, maxDate) => getMaxDate(getMinDate(inputDate, maxDate), minDate);

const getMonthCode = (monthNumber) => {
  const idx = Number.parseInt(monthNumber, 10) - 1;
  const MONTHS = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  return MONTHS[idx];
};

const isDateInRange = ({ inputDate, minDate, maxDate }) => {
  if (!minDate && !maxDate) {
    return true;
  }
  if (minDate && inputDate < minDate) {
    return false;
  }
  if (maxDate && inputDate > maxDate) {
    return false;
  }
  return true;
};

const isDateRangeWithinAnother = (range1, range2) => range1[0] >= range2[0] && range1[1] <= range2[1];

const isDateRangeOverlapping = ({ range1, range2 }) => {
  const [min1, max1] = range1;
  const [min2, max2] = range2;
  // trivial reject bad ranges
  if (min1 > max1 || min2 > max2) {
    return false;
  }

  // 1 inside of 2 or 2 inside of 1
  if (isDateRangeWithinAnother(range1, range2) || isDateRangeWithinAnother(range2, range1)) {
    return true;
  }

  // partial overlap
  if ((min1 >= min2 && min1 <= max2) || (max1 >= min2 && max1 <= max2)) {
    return true;
  }
  return false;
};

export {
  addDays,
  addMonths,
  addYears,
  clampToLowerBound,
  clampToUpperBound,
  DATE_FORMATS,
  dateDiff,
  formatDate,
  getDateFromISOString,
  getDateString,
  getISOStringFromDate,
  getLastDayOfMonth,
  getMaxDate,
  getMinDate,
  getMonthCode,
  getTZOffsetDate,
  isDateInRange,
  isDateRangeOverlapping,
};
