import Button from '@viking-eng/button';
import Search from '@viking-eng/icon/lib/Search';
import { ANALYTICS_EVENT_TYPES, triggerLinkEvent } from 'analytics/Analytics';
import FieldValidationError from 'common/ui-kit/FieldValidationError/FieldValidationError';
import { KEY_EVENT_CODES } from 'constants/A11Y';
import useBreakpoint from 'hooks/useBreakpoint';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { onKeyPressRegex } from 'utils/formNormalization';
import { VALIDATION_REGEXES } from 'utils/formValidation';
import { logError } from 'utils/logging';
import { keyify } from 'utils/string';
import './SearchBookingsInput.scss';
import SearchInput from './SearchInput';

const normalizeBookingsSearch = (inputValue = '') => {
  return inputValue.toUpperCase().replace(/[^A-Z0-9 ]/g, ' ');
};

const SearchBookingsInput = ({
  commonStrings,
  initialValue = '',
  isDisabled = false,
  onClearSearch = () => {},
  onFocus = () => {},
  onSuccessfulSubmit,
}) => {
  const { dimensions } = useBreakpoint();
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    if (isDisabled) {
      setValue('');
    }
  }, [isDisabled]);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  const onClear = () => {
    setErrorMessage('');
    setValue('');
    onClearSearch();
  };

  const onSearch = () => {
    try {
      const trimmedValue = value.trim();
      setValue(trimmedValue);
      triggerLinkEvent({
        event: ANALYTICS_EVENT_TYPES.SEARCH,
        search_term: keyify(trimmedValue),
      });
      if (!trimmedValue) {
        setErrorMessage(commonStrings.validation.bookingSearch.enterSearchTerm);
      } else if (trimmedValue.length < 3) {
        setErrorMessage(commonStrings.validation.bookingSearch.min3Search);
      } else {
        setErrorMessage('');
        onSuccessfulSubmit(trimmedValue);
      }
    } catch (error) {
      logError('Search Bookings Input - Error', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="search">
      <div className="search-form clearfix">
        <SearchInput
          button={{
            disabled: isDisabled,
            onClick: onClear,
          }}
          input={{
            disabled: isDisabled,
            hasError: !!errorMessage,
            isErrorVisible: !!errorMessage,
            onClick: onClear,
            onChange: (evt) => {
              setErrorMessage('');
              setValue(normalizeBookingsSearch(evt?.target?.value));
            },
            onFocus: () => {
              setErrorMessage('');
              onFocus();
            },
            onKeyDown: (evt) => {
              if (
                evt.metaKey ||
                evt.ctrlKey ||
                [evt.key, evt.code].some((keyCode) => Object.values(KEY_EVENT_CODES).includes(keyCode))
              ) {
                if (KEY_EVENT_CODES.enter === evt.key) {
                  evt.preventDefault();
                  setIsLoading(true);
                  onSearch();
                }
              } else {
                onKeyPressRegex(evt, VALIDATION_REGEXES.ALPHA_NUMERIC_SPACES_KEY_PRESS);
              }
            },
            placeholder:
              dimensions.innerWidth > 991
                ? commonStrings.bookingsManage.searchBy
                : commonStrings.bookingsManage.searchByShort,
            value,
          }}
        />
        <div className="form-submit">
          <Button
            appearance="secondary-blue"
            loading={isLoading}
            onButtonClick={() => {
              setIsLoading(true);
              onSearch();
            }}
            disabled={isDisabled}
            attributes={{ 'aria-label': commonStrings.labels.bookingSearch.placeholderText }}
          >
            <Search />
          </Button>
        </div>
      </div>
      {errorMessage && <FieldValidationError errorCode="" errorMessage={errorMessage} />}
    </div>
  );
};

SearchBookingsInput.propTypes = {
  commonStrings: PropTypes.shape({
    bookingsManage: PropTypes.objectOf(PropTypes.string).isRequired,
    labels: PropTypes.shape({
      bookingSearch: PropTypes.shape({
        placeholderText: PropTypes.string,
      }),
    }).isRequired,
    validation: PropTypes.shape({
      bookingSearch: PropTypes.shape({ enterSearchTerm: PropTypes.string, min3Search: PropTypes.string }),
    }).isRequired,
  }).isRequired,
  initialValue: PropTypes.string,
  isDisabled: PropTypes.bool,
  onClearSearch: PropTypes.func,
  onFocus: PropTypes.func,
  onSuccessfulSubmit: PropTypes.func,
};

export default SearchBookingsInput;
