import Button from '@viking-eng/button';
import { ANALYTICS_EVENT_TYPES, ANALYTICS_LINK_TYPES, triggerLinkEvent } from 'analytics/Analytics';
import SearchBookingsInput from 'common/search/SearchBookingsInput';
import HomeCardSection from 'common/ui-kit/HomeCardSection/HomeCardSection';
import { CMS_PATHS } from 'constants/API';
import { IMAGE_ASPECT_RATIOS } from 'constants/IMAGE';
import ROUTES, { BASE_PATH } from 'constants/ROUTES';
import useCmsState from 'hooks/useCmsState';
import useNavigate from 'hooks/useNavigate';
import usePermissions from 'hooks/usePermissions';
import usePrefetch from 'hooks/usePrefetch';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { getRouteFromLink, isLinkExternal } from 'utils/routing';
import './Home.scss';

const Home = ({ data }) => {
  const { role } = usePermissions();
  const { prefetchDataByRouteKey } = usePrefetch();
  const { common: commonData } = useCmsState({ cmsPath: CMS_PATHS.pages.common }) || {};
  const navigate = useNavigate();
  const { office } = useSelector((state) => state.auth.agency);
  const homeCards = data?.pagesHome?.variableContent?.[0]?.cards
    ?.filter((cardLayout) => cardLayout.card && cardLayout?.layout?.name === 'tile-image-cta-subtle')
    ?.map((cardValue) => {
      const card = { ...cardValue.card };
      // TODO: This may change based on the outcome of this ticket.
      // If added to CMS, we can likely remove this condition and pass the role to CMS call.
      // https://vikingtravel.atlassian.net/browse/TAP-3707
      if ((role.isManager || role.isOwner) && card.jcrName === 'manage-my-bookings') {
        card.callToActionUrl = `${BASE_PATH}${ROUTES.manageAgency.url}`;
        card.title = ROUTES.manageAgency.title;
      }
      const openInNewWindow = isLinkExternal(card.callToActionUrl);
      const route = getRouteFromLink(card.callToActionUrl);
      return {
        id: card.jcrName,
        openInNewWindow,
        routeKey: route.key,
        thumbnailUrl: card.mediaSet?.find(({ type }) => type === IMAGE_ASPECT_RATIOS.WIDE_16x9)?.url,
        title: card.title,
        url: card.callToActionUrl,
      };
    });

  const countryDetails = commonData?.props?.countries?.[office];
  const faqLabels = commonData?.strings?.labels?.faq;

  const homeFAQsCard = {
    title: faqLabels?.homeFAQTitle,
    callToActionUrl: faqLabels?.homeFAQCTAUrl,
    callToActionTitle: faqLabels?.homeFAQCTATitle,
  };

  const cardSizes = {
    xs: 4,
    sm: 12,
    md: 12,
    lg: 12,
  };

  return (
    <>
      {commonData && (
        <div className="search-container row p-4 p-lg-3">
          <div className="col-lg-6 col-12 pr-lg-0">
            <div className="search-title">{commonData.strings?.labels?.bookingSearch?.fieldLabelText}</div>
          </div>
          <div className="col-lg-6 col-12">
            <SearchBookingsInput
              commonStrings={commonData.strings}
              onFocus={() => prefetchDataByRouteKey(ROUTES.bookings.key)}
              onSuccessfulSubmit={(searchTerm) => navigate(`${ROUTES.bookings.url}/all/${searchTerm}`)}
            />
          </div>
        </div>
      )}
      {homeCards?.length > 0 ? <HomeCardSection cards={homeCards} cardSizes={cardSizes} /> : null}
      {countryDetails?.hasFAQs && (
        <div className="faq-container">
          <div className="d-flex justify-content-center faq-text">{homeFAQsCard.title}</div>
          {homeFAQsCard && (
            <div className="d-flex justify-content-center">
              <Button
                attributes={{
                  onMouseEnter: () => prefetchDataByRouteKey(ROUTES.faq.key),
                  tabIndex: 0,
                  role: 'link',
                }}
                onButtonClick={() => {
                  triggerLinkEvent({
                    event: ANALYTICS_EVENT_TYPES.BUTTON_CLICK,
                    linkText: homeFAQsCard.callToActionTitle,
                    linkType: ANALYTICS_LINK_TYPES.BUTTON,
                    linkId: 'home-faq-link',
                  });
                  navigate(ROUTES.faq.url);
                }}
                appearance="primary"
              >
                {homeFAQsCard.callToActionTitle}
              </Button>
            </div>
          )}
        </div>
      )}
    </>
  );
};

Home.propTypes = {
  data: PropTypes.shape({
    pagesHome: PropTypes.shape({
      variableContent: PropTypes.arrayOf(
        PropTypes.shape({
          cards: PropTypes.arrayOf(
            PropTypes.shape({
              card: PropTypes.shape({
                callToActionUrl: PropTypes.string,
                jcrName: PropTypes.string.isRequired,
                mediaSet: PropTypes.arrayOf(
                  PropTypes.shape({
                    url: PropTypes.string.isRequired,
                  })
                ),
              }),
              layout: PropTypes.shape({
                name: PropTypes.string.isRequired,
              }).isRequired,
            }).isRequired
          ).isRequired,
        }).isRequired
      ).isRequired,
    }).isRequired,
  }),
};

export default Home;
