import { useEffect, useRef } from 'react';
import { useErrorBoundary } from 'react-error-boundary';

const useEventListener = (eventName, handler, element = window) => {
  const { showBoundary } = useErrorBoundary();
  const savedHandler = useRef();
  useEffect(() => {
    savedHandler.current = handler;
  }, [handler]);

  useEffect(() => {
    const isSupported = element && element.addEventListener;
    if (!isSupported) {
      showBoundary(
        new Error('useEventListener', {
          message: 'Unable to add event handler',
          eventName,
          handler,
          element,
        })
      );
      return;
    }
    const eventListener = (event) => savedHandler.current(event);
    try {
      element.addEventListener(eventName, eventListener);
    } catch (error) {
      showBoundary(
        new Error('useEventListener', {
          message: 'Unable to add event handler',
          error,
          eventName,
          handler,
          element,
        })
      );
    }
    // eslint-disable-next-line consistent-return
    return () => {
      try {
        element.removeEventListener(eventName, eventListener);
      } catch (error) {
        showBoundary(
          new Error('useEventListener', {
            message: 'Unable to remove event handler',
            error,
            eventName,
            handler,
            element,
          })
        );
      }
    };
  }, [eventName, element, handler, showBoundary]);
};

export default useEventListener;
