import throttle from 'lodash.throttle';
import { useState } from 'react';
import { BREAKPOINTS, BREAKPOINTS_MIN_VALUES } from 'utils/responsive';
import useEventListener from './useEventListener';

const getBreakpointFromWidth = (width) => {
  if (width < BREAKPOINTS_MIN_VALUES.sm) {
    return BREAKPOINTS.xs;
  }
  if (width >= BREAKPOINTS_MIN_VALUES.sm && width < BREAKPOINTS_MIN_VALUES.md) {
    return BREAKPOINTS.sm;
  }
  if (width >= BREAKPOINTS_MIN_VALUES.md && width < BREAKPOINTS_MIN_VALUES.lg) {
    return BREAKPOINTS.md;
  }
  if (width >= BREAKPOINTS_MIN_VALUES.lg && width < BREAKPOINTS_MIN_VALUES.xl) {
    return BREAKPOINTS.lg;
  }
  if (width >= BREAKPOINTS_MIN_VALUES.xl && width < BREAKPOINTS_MIN_VALUES.xxl) {
    return BREAKPOINTS.xl;
  }
  return BREAKPOINTS.xxl;
};

const useBreakpoint = () => {
  const dimensions = {
    innerHeight: window.innerHeight,
    innerWidth: window.innerWidth,
    outerHeight: window.outerHeight,
    outerWidth: window.outerWidth,
  };

  const [details, setDetails] = useState({ breakpoint: getBreakpointFromWidth(dimensions.innerWidth), dimensions });

  const calcInnerWidth = throttle((event) => {
    setDetails({
      breakpoint: getBreakpointFromWidth(event.target.innerWidth),
      dimensions: {
        innerHeight: event.target.innerHeight,
        innerWidth: event.target.innerWidth,
        outerHeight: event.target.outerHeight,
        outerWidth: event.target.outerWidth,
      },
    });
  }, 200);

  useEventListener('resize', calcInnerWidth);
  return details;
};

export default useBreakpoint;
