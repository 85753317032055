import { BUILD, VERSION } from './ENV';

const BROADCAST_CHANNEL_VALUES = Object.freeze({
  accountModalTabRefresh: 'accountModalTabRefresh',
  accountRemoved: 'accountRemoved',
  becameVisible: 'becameVisible',
});

const GLOBAL_URL_SEARCH_PARAMS = Object.freeze({ _v: `${VERSION}.${BUILD}` });

const STORAGE_KEYS = Object.freeze({
  appInsightsInfo: 'APP_INSIGHTS_INFO',
  history: 'history',
  homePageModal: 'homePageModal',
});

export { BROADCAST_CHANNEL_VALUES, GLOBAL_URL_SEARCH_PARAMS, STORAGE_KEYS };
