import { TAP_API_BASE_URL } from 'constants/ENV';
import { CMS_PAGES_PATHS_ENABLED, DELAY } from 'constants/MOCK_CONFIG';
import { EVO_COUNTRY } from 'mocks/data/cms/evoCountry';
import { HttpResponse, delay, http } from 'msw';
import { fixMockCMSData, getCommonData } from 'utils/mock';

const handlers = [];

Object.entries(CMS_PAGES_PATHS_ENABLED).forEach(([office, officePaths]) => {
  Object.entries(officePaths).forEach(([key, value]) => {
    if (value.isEnabled) {
      const url = `${TAP_API_BASE_URL}/cms/${office}/${value.cmsPath.path}`;
      switch (key) {
        case 'common':
          handlers.push(
            http.get(url, async () => {
              let formattedJson = {};
              const promises = [
                await import('mocks/data/cms/tap.cms.pages.common.json'),
                await import('mocks/data/cms/tap.cms.pages.hp-modal.json'),
                await import('mocks/data/cms/token.json'),
                await import('mocks/data/properties/tapProperties.json'),
                await import('mocks/data/properties/tapStrings.json'),
              ];
              const [commonData, homePageModalData, tokenData, propsData, stringsData] = await Promise.all(promises);
              formattedJson = getCommonData({
                commonData: commonData.results[0],
                evoCountry: EVO_COUNTRY,
                homePageModalData: homePageModalData.results[0],
                officeCode: office,
                propsData,
                stringsData,
                tokenData: tokenData.results[0],
              });
              await delay(DELAY);
              return HttpResponse.json(formattedJson);
            })
          );
          break;
        case 'manageAgency':
          handlers.push(
            http.get(url, async () => {
              const manageAgency = await import('mocks/data/cms/tap.cms.pages.manageAgency.json');
              await delay(DELAY);
              return HttpResponse.json(manageAgency?.results?.[0] || {});
            })
          );
          break;
        case 'website':
          handlers.push(
            http.get(url, async () => {
              const website = await import('mocks/data/cms/tap.cms.pages.website.json');
              const fixed = fixMockCMSData({ cmsData: website?.results?.[0], officeCode: office });
              await delay(DELAY);
              return HttpResponse.json(fixed || {});
            })
          );
          break;
        default:
          break;
      }
    }
  });
});

export default handlers;
