/*
  Note: Setting nonce dynamically with Vite doesn't work locally
  https://github.com/vitejs/vite/issues/11862
*/
import { IS_LOCAL } from 'constants/ENV';

const DELIMITERS = Object.freeze({ CARRIAGE_RETURN: '\n', SPACE: ' ' });
const delimiter = DELIMITERS.SPACE;

const fallbackScriptSrcCspWhitelist = [
  '*.liveperson.net',
  '*.privacymanager.io',
  '*.vimeo.com',
  '*.vimeocdn.com',
  'assets.adobedtm.com',
  'cdn.cookielaw.org',
  'cdn.lpsnmedia.net',
  'cdn.pdst.fm',
  'connect.facebook.net',
  'ct.pinterest.com',
  'googleads.g.doubleclick.net',
  's.pinimg.com',
  's.yimg.com',
  'solutions.invocacdn.com',
  'www.googletagmanager.com',
].join(delimiter);

const insertCspMetaTag = (nonce) => {
  try {
    const scriptSrcContent =
      nonce && !IS_LOCAL
        ? `'self'${delimiter}'nonce-${nonce}'${delimiter}'strict-dynamic'${delimiter}${fallbackScriptSrcCspWhitelist}`
        : `'self'${delimiter}${fallbackScriptSrcCspWhitelist}`;
    let newCspMetaTag = document.getElementById('metaCsp');
    if (!newCspMetaTag) {
      newCspMetaTag = document.createElement('meta');
      newCspMetaTag.id = 'metaCsp';
      newCspMetaTag.httpEquiv = 'Content-Security-Policy';
      // eslint-disable-next-line max-len
      newCspMetaTag.content = `object-src 'none';${delimiter}script-src ${scriptSrcContent};${delimiter}base-uri 'self'`;
      document.head.prepend(newCspMetaTag);
    }
  } catch (ex) {
    // eslint-disable-next-line no-console
    console.warn(ex);
  }
};

export { insertCspMetaTag };
