import { BrowserUtils, InteractionRequiredAuthError, PublicClientApplication } from '@azure/msal-browser';
import { ACCOUNT_STATUS } from 'common/account/ACCOUNT_CONSTANTS';
import { AUTH_ERRORS } from 'common/auth/AUTH_CONSTANTS';
import NavigationClient from 'common/auth/NavigationClient';
import { b2cPolicies, msalConfig } from 'configs/authConfig';
import { OFFICE_UNKNOWN } from 'constants/COUNTRIES';
import { B2C_DOMAIN } from 'constants/ENV';
import { HOME_BASE, ROUTE_AUTH_KEYS } from 'constants/ROUTES';
import { logError, logInfo, logWarning } from './logging';
import { getRouteFromPathname } from './routing';

const msalInstance = new PublicClientApplication(msalConfig);

const addEventCallback = (callback) => {
  try {
    msalInstance.addEventCallback(callback);
    logInfo('utils/auth - addEventCallback - success');
  } catch (error) {
    logError('utils/auth - addEventCallback', error);
  }
};

const changeEmail = async () => {
  try {
    await msalInstance.acquireTokenRedirect(b2cPolicies.authorities.changeUserName);
    logInfo('utils/auth - changeEmail - success');
  } catch (error) {
    logError('utils/auth - changeEmail', error);
  }
};

const changePassword = async () => {
  try {
    await msalInstance.acquireTokenRedirect(b2cPolicies.authorities.changePassword);
    logInfo('utils/auth - changePassword - success');
  } catch (error) {
    logError('utils/auth - changePassword', error);
  }
};

const clearCache = async () => {
  logInfo('utils/auth - clearCache - start');
  try {
    msalInstance.setActiveAccount(null);
    await msalInstance.clearCache();
    logInfo('utils/auth - clearCache - success');
  } catch (error) {
    logError('utils/auth - clearCache', error);
  }
};

const enableAccountStorageEvents = () => {
  try {
    msalInstance.enableAccountStorageEvents();
    logInfo('utils/auth - enableAccountStorageEvents - success');
  } catch (error) {
    logError('utils/auth - enableAccountStorageEvents', error);
  }
};

const getActiveAccount = () => {
  let account;
  try {
    account = msalInstance.getActiveAccount();
    logInfo('utils/auth - getActiveAccount - success');
  } catch (error) {
    logError('utils/auth - getActiveAccount', error);
  }
  return account;
};

const getAccount = () => {
  let account;
  logInfo('utils/auth - getAccount - start');
  try {
    const activeAccount = msalInstance.getActiveAccount();
    if (activeAccount?.environment === B2C_DOMAIN) {
      account = activeAccount;
    } else {
      account = msalInstance.getAllAccounts()?.find(({ environment }) => environment === B2C_DOMAIN);
    }
    logInfo('utils/auth - getAccount - success');
  } catch (error) {
    logError('utils/auth - getAccount', error);
  }
  return account;
};

const getIsRouteAuthorized = ({ auth, route = getRouteFromPathname() }) => {
  const isAccountAuthorized = ROUTE_AUTH_KEYS.includes(route.key)
    ? auth?.agent?.permissions?.[route.key]?.view === true
    : true;
  const isOfficeAuthorized = route.auth.offices.length ? route.auth.offices.includes(auth.agency.office) : false;
  const bypassOfficeVerification =
    !isOfficeAuthorized &&
    auth.agency.office === OFFICE_UNKNOWN &&
    auth.accountStatus === ACCOUNT_STATUS.accountFoundMissingInfo;
  return isAccountAuthorized && (isOfficeAuthorized || bypassOfficeVerification);
};

const getToken = async ({ authority } = {}) => {
  logInfo('utils/auth - getToken - start');
  const activeAccount = msalInstance.getActiveAccount();
  const accounts = msalInstance.getAllAccounts();
  if (!activeAccount && accounts.length === 0) {
    throw new Error(AUTH_ERRORS.IS_USER_NOT_SIGNED_IN);
  }
  const request = {
    ...msalConfig,
    account: activeAccount || accounts?.[0],
  };
  if (authority) {
    request.authority = authority;
  }
  const tokenResponse = await msalInstance.acquireTokenSilent(request);
  logInfo('utils/auth - getToken - success');
  return tokenResponse;
};

const handleRedirectPromise = async () => {
  logInfo('utils/auth - handleRedirectPromise - start');
  try {
    await msalInstance.handleRedirectPromise();
    logInfo('utils/auth - handleRedirectPromise - success');
  } catch (error) {
    logError('utils/auth - handleRedirectPromise', error);
  }
};

const hasPermission = ({ permission, permissions, verb }) => {
  return permissions[permission]?.[verb];
};

const initialize = async () => {
  logInfo('utils/auth - initialize - start');
  try {
    await msalInstance.initialize();
    logInfo('utils/auth - initialize - success');
  } catch (error) {
    logError('utils/auth - initialize', error);
  }
  return msalInstance;
};

const integrateRouting = (navigate) => {
  logInfo('utils/auth - integrateRouting - start');
  try {
    const navigationClient = new NavigationClient(navigate);
    msalInstance.setNavigationClient(navigationClient);
    logInfo('utils/auth - integrateRouting - success');
  } catch (error) {
    logError('utils/auth - integrateRouting', error);
  }
};

const loginRedirect = async (config = msalConfig, redirectStartPage = HOME_BASE) => {
  logInfo('utils/auth - loginRedirect - start');
  try {
    await msalInstance.loginRedirect({ ...config, redirectStartPage });
    logInfo('utils/auth - loginRedirect - success');
  } catch (error) {
    logError('utils/auth - loginRedirect', error);
  }
};

const logoutRedirect = async ({ preventRedirect = true, redirectUri }) => {
  logInfo('utils/auth - logoutRedirect - start');
  try {
    await msalInstance.logoutRedirect({
      account: getActiveAccount(),
      redirectUri,
      redirectStartPage: redirectUri,
      onRedirectNavigate: () => {
        if (preventRedirect) {
          return false;
        }
        return !BrowserUtils.isInIframe();
      },
    });
    logInfo('utils/auth - logoutRedirect - success');
  } catch (error) {
    logError('utils/auth - logoutRedirect', error);
  }
};

const setActiveAccount = ({ account }) => {
  logInfo('utils/auth - getActiveAccount - start');
  try {
    msalInstance.setActiveAccount(account);
    logInfo('utils/auth - getActiveAccount - success');
  } catch (error) {
    logError('utils/auth - getActiveAccount', error);
  }
};

const ssoSilent = async () => {
  logInfo('utils/auth - ssoSilent - start');
  try {
    await msalInstance.ssoSilent(msalConfig);
    logInfo('utils/auth - ssoSilent - success');
  } catch (error) {
    if (error instanceof InteractionRequiredAuthError) {
      logWarning('utils/auth - ssoSilent - expected error', error);
    } else {
      logError('utils/auth - ssoSilent', error);
    }
  }
};

export {
  addEventCallback,
  changeEmail,
  changePassword,
  clearCache,
  enableAccountStorageEvents,
  getAccount,
  getActiveAccount,
  getIsRouteAuthorized,
  getToken,
  handleRedirectPromise,
  hasPermission,
  initialize,
  integrateRouting,
  loginRedirect,
  logoutRedirect,
  setActiveAccount,
  ssoSilent,
};
