import PropTypes from '@viking-eng/prop-types';
import Spinner from '@viking-eng/spinner';
import classNames from 'classnames';
import HomeCard from 'common/ui-kit/HomeCard/HomeCard';
import './HomeCardSection.scss';

function HomeCardSection({
  cardGridLayout: { xs, sm, md, lg } = { xs: 12, sm: 12, md: 4, lg: 4 },
  cards = [],
  cardSizes = { xs: 12, sm: 12, md: 12, lg: 12 },
  isLoading = false,
  tabindex = 0,
}) {
  if (isLoading) {
    return (
      <div className="loading-spinner">
        <Spinner color="black" />
      </div>
    );
  }
  if (!isLoading && cards.length === 0) {
    return <div className="row card-grid">Something is wrong. Please refresh or try again later.</div>;
  }

  return (
    <div className="row card-grid">
      {cards.map((card) => (
        <div
          className={classNames('card-item', `col-lg-${lg}`, `col-md-${md}`, `col-sm-${sm}`, `col-${xs}`)}
          key={card.id}
        >
          <HomeCard card={card} onCardClick={card.onCardClick} sizes={cardSizes} tabIndex={tabindex} />
        </div>
      ))}
    </div>
  );
}

HomeCardSection.propTypes = {
  cards: PropTypes.cardGrid.isRequired,
  cardGridLayout: PropTypes.shape({
    lg: PropTypes.number,
    md: PropTypes.number,
    sm: PropTypes.number,
    xs: PropTypes.number,
  }),
  cardSizes: PropTypes.shape({
    lg: PropTypes.number,
    md: PropTypes.number,
    sm: PropTypes.number,
    xs: PropTypes.number,
  }),
  isLoading: PropTypes.bool,
  tabindex: PropTypes.number,
};

export default HomeCardSection;
