import { OFFICE_UNKNOWN } from 'constants/COUNTRIES';
import { BASE_PATH } from 'constants/ROUTES';
import { getCmsDataByCmsPaths } from 'utils/cms';
import WithCmsData from './WithCmsData';
import WithOptionalCmsData from './WithOptionalCmsData';

const getRouterElement = ({ dispatch, isDataOptional = false, office = OFFICE_UNKNOWN, route }) => {
  const { cmsPaths, Child, routePath, ...rest } = route;
  return {
    ...rest,
    element: isDataOptional ? (
      <WithOptionalCmsData>
        <Child />
      </WithOptionalCmsData>
    ) : (
      <WithCmsData>
        <Child />
      </WithCmsData>
    ),
    HydrateFallback: () => null,
    loader: async () => {
      let data = {};
      if (cmsPaths) {
        data = await getCmsDataByCmsPaths({ cmsPaths, dispatch, office });
      }
      return data || {};
    },
    path: `${BASE_PATH}${routePath}`,
    shouldRevalidate: () => (route.shouldRevalidate !== undefined ? route.shouldRevalidate : false),
  };
};

const withCmsData = (props) => getRouterElement({ ...props, isDataOptional: false });

const withOptionalCmsData = (props) => getRouterElement({ ...props, isDataOptional: true });

export { withCmsData, withOptionalCmsData };
