import { TAP_API_BASE_URL } from 'constants/ENV';
import { COBRANDED_RESTRICTION_ENABLED, DELAY } from 'constants/MOCK_CONFIG';
import { HttpResponse, delay, http } from 'msw';

const handlers = [];

if (COBRANDED_RESTRICTION_ENABLED) {
  const url = `${TAP_API_BASE_URL}/cobranded/*`;
  const mockJsonResult = {
    errorType: 'string',
    errorMessage: '{"errorDescription":"Cobranded sites are restricted","errorCode":"204","statusCode":"204"}',
  };
  handlers.push(
    http.get(url, async () => {
      await delay(DELAY);
      return HttpResponse.json(mockJsonResult);
    })
  );
}

export default handlers;
