// Note: Git Hooks (using by husky and lint-staged) will change all false instances to false every commit using scripts/resetMocks.mjs.
// This ensures developers always start off pointing at live APIs and not mocks.
import { ENVIRONMENT_CODE } from 'constants/ENV';
import { CMS_PATHS } from './API';

const ACCOUNT_ERROR_ENABLED = false;
const AUTH_ERROR_ENABLED = false;
const BOOKINGS_ENABLED = false;
const COBRANDED_RESTRICTION_ENABLED = false;
const DELAY = 0;
const SEARCH_DATA_ENABLED = false;
const SEARCH_DATA_ERROR_ENABLED = false;
const VOYAGE_UNDER_14_DAYS_ENABLED = false;

const CMS_PAGES_PATHS_ENABLED = Object.freeze({
  manageAgency: { cmsPath: CMS_PATHS.pages.manageAgency, isEnabled: false },
  common: { cmsPath: CMS_PATHS.pages.common, isEnabled: false },
  website: { cmsPath: CMS_PATHS.pages.website, isEnabled: false },
});

const isEnabled =
  ENVIRONMENT_CODE !== 'prod' &&
  (ACCOUNT_ERROR_ENABLED ||
    AUTH_ERROR_ENABLED ||
    BOOKINGS_ENABLED ||
    COBRANDED_RESTRICTION_ENABLED ||
    SEARCH_DATA_ENABLED ||
    VOYAGE_UNDER_14_DAYS_ENABLED ||
    Object.values(CMS_PAGES_PATHS_ENABLED).some((v) => v.isEnabled));

export {
  ACCOUNT_ERROR_ENABLED,
  AUTH_ERROR_ENABLED,
  BOOKINGS_ENABLED,
  CMS_PAGES_PATHS_ENABLED,
  COBRANDED_RESTRICTION_ENABLED,
  DELAY,
  isEnabled,
  SEARCH_DATA_ENABLED,
  SEARCH_DATA_ERROR_ENABLED,
  VOYAGE_UNDER_14_DAYS_ENABLED,
};
