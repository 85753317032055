import {
  ANALYTICS_EVENT_TYPES,
  ANALYTICS_LINK_TYPES,
  ANALYTICS_NAMES_AND_TYPES,
  triggerLinkEvent,
} from 'analytics/Analytics';
import { ACCOUNT_STATUS, NOT_SET } from 'common/account/ACCOUNT_CONSTANTS';
import { STORAGE_KEYS } from 'constants/CONFIG';
import { ENVIRONMENT_CODE } from 'constants/ENV';
import ROUTES from 'constants/ROUTES';
import useLocalStorage, { STORAGE_TYPES } from 'hooks/useLocalStorage';
import useRoute from 'hooks/useRoute';
import PropTypes from 'prop-types';
import { Suspense, useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { addDays } from 'utils/dateUtils';
import lazyWithReload from 'utils/lazyWithReload';
import { base64Encode } from 'utils/string';

const AccountModal = lazyWithReload(() => import('pages/account/AccountModal'));
const AuthAgencyMissingModal = lazyWithReload(() => import('common/auth/AuthAgencyMissingModal'));
const HomePageModal = lazyWithReload(() => import('common/modal/HomePageModal'));

const PRE_CHECK_STATE = Object.freeze({
  agencyMissingModal: 'agencyMissingModal',
  accountModal: 'accountModal',
  homeModal: 'homeModal',
  none: 'none',
});

const PreChecksPrivate = ({ hpModalKey }) => {
  const auth = useSelector((state) => state.auth);
  const { accountStatus, agent, msal } = auth;
  const route = useRoute();
  const [status, setStatus] = useState();
  const hpModalStorageKey = useMemo(
    () =>
      agent?.tapEmail && hpModalKey && base64Encode(`${ENVIRONMENT_CODE}-${agent.tapEmail}-${hpModalKey}`.normalize()),
    [agent?.tapEmail, hpModalKey]
  );

  const [isHomeModalRequired, setIsHomeModalRequired] = useLocalStorage({
    key: STORAGE_KEYS.homePageModal,
    initialValue: { default: true },
    isInitialValueMerged: true,
    storageType: STORAGE_TYPES.localStorage,
  });

  const checkIsAgencyMissingModalRequired = useCallback(
    async () => accountStatus === ACCOUNT_STATUS.accountFoundMissingInfo,
    [accountStatus]
  );

  const checkIsAccountModalRequired = useCallback(async () => {
    let isAccountModalRequired = false;
    if (agent) {
      const {
        academyPID,
        address1,
        agentCode,
        agentRelationship,
        city,
        firstName,
        isNewAgent,
        lastName,
        phone,
        secondaryPID,
        tapEmail,
      } = agent;
      const agentNumber = msal?.idTokenClaims?.agentNumber;
      const areAnyRequireFieldsEmpty =
        isNewAgent ||
        !academyPID ||
        !address1 ||
        !(agentCode || '').trim() ||
        !(city || '').trim() ||
        !(tapEmail || '').trim() ||
        !(firstName || '').trim() ||
        !(lastName || '').trim() ||
        !phone ||
        !secondaryPID ||
        !agentRelationship ||
        agentRelationship === NOT_SET;
      const hasFirstTimeUserSeenModal = agentNumber ? isAccountModalRequired === false : true;
      isAccountModalRequired = areAnyRequireFieldsEmpty || !hasFirstTimeUserSeenModal;
    } else {
      isAccountModalRequired = true;
    }
    return isAccountModalRequired;
  }, [agent, msal?.idTokenClaims?.agentNumber]);

  const checkIsHomeModalRequired = useCallback(async () => {
    if (!hpModalStorageKey || typeof isHomeModalRequired !== 'object') {
      return null;
    }
    if (route.id !== ROUTES.home.id) {
      return false;
    }
    const storedValue = isHomeModalRequired[hpModalStorageKey];
    return typeof storedValue !== 'object' || (storedValue?.expiration && storedValue.expiration <= Date.now())
      ? isHomeModalRequired.default
      : storedValue.data;
  }, [hpModalStorageKey, isHomeModalRequired, route.id]);

  const getNextStatus = useCallback(
    async (prevStatus) => {
      let newStatus = null;
      switch (prevStatus) {
        case PRE_CHECK_STATE.agencyMissingModal:
          if (await checkIsAgencyMissingModalRequired()) {
            newStatus = PRE_CHECK_STATE.agencyMissingModal;
          } else {
            newStatus = await getNextStatus(PRE_CHECK_STATE.accountModal);
          }
          break;
        case PRE_CHECK_STATE.accountModal:
          if (await checkIsAccountModalRequired()) {
            newStatus = PRE_CHECK_STATE.accountModal;
          } else {
            newStatus = await getNextStatus(PRE_CHECK_STATE.homeModal);
          }
          break;
        case PRE_CHECK_STATE.homeModal:
          if (await checkIsHomeModalRequired()) {
            newStatus = PRE_CHECK_STATE.homeModal;
          } else {
            newStatus = await getNextStatus();
          }
          break;
        default:
          newStatus = PRE_CHECK_STATE.none;
          break;
      }
      return newStatus;
    },
    [checkIsAccountModalRequired, checkIsAgencyMissingModalRequired, checkIsHomeModalRequired]
  );

  const setNextStatus = useCallback(
    async (prevStatus) => {
      const nextStatus = await getNextStatus(prevStatus);
      setStatus(nextStatus);
    },
    [getNextStatus]
  );

  useEffect(() => {
    (async () => {
      if (!route.isPublic) {
        await setNextStatus(PRE_CHECK_STATE.agencyMissingModal);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (status === PRE_CHECK_STATE.homeModal) {
      triggerLinkEvent({
        event: ANALYTICS_EVENT_TYPES.HOMEPAGE_MODAL_OPEN,
        linkType: ANALYTICS_LINK_TYPES.HOMEPAGE_MODAL_OPEN,
        linkText: ANALYTICS_EVENT_TYPES.HOMEPAGE_MODAL_OPEN,
        modal_name: ANALYTICS_NAMES_AND_TYPES.HOMEPAGE_MODAL_NAME,
        modal_type: ANALYTICS_NAMES_AND_TYPES.HOMEPAGE_MODAL_TYPE,
      });
    }
  }, [status]);

  return route.isPublic ? null : (
    <Suspense fallback={null}>
      {status === PRE_CHECK_STATE.agencyMissingModal && (
        <AuthAgencyMissingModal
          isOpen={status === PRE_CHECK_STATE.agencyMissingModal}
          setIsOpen={async () => {
            await setNextStatus(PRE_CHECK_STATE.accountModal);
          }}
        />
      )}
      {status === PRE_CHECK_STATE.accountModal && (
        <AccountModal
          isOpenDefault={status === PRE_CHECK_STATE.accountModal}
          setIsOpenDefault={async () => {
            await setNextStatus(PRE_CHECK_STATE.homeModal);
          }}
        />
      )}
      <HomePageModal
        isOpen={status === PRE_CHECK_STATE.homeModal}
        setIsOpen={async () => {
          const fallbackExpiration = addDays(new Date(), 30)?.getTime();
          setIsHomeModalRequired((existingData) => ({
            ...existingData,
            [hpModalStorageKey]: { data: false, expiration: fallbackExpiration },
          }));
          setStatus(PRE_CHECK_STATE.none);
        }}
      />
    </Suspense>
  );
};

PreChecksPrivate.propTypes = {
  hpModalKey: PropTypes.string,
};

export default PreChecksPrivate;
