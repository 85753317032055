import { isEnabled } from 'constants/MOCK_CONFIG';
import { ENV } from './ENV';

const APP_INSIGHTS = Object.freeze({
  CONNECTION_STRING: ENV.VITE_APP_APP_INSIGHTS_CONNECTION_STRING,
  INSTANCE_NAME: ENV.VITE_APP_APP_INSIGHTS_INSTANCE_NAME,
  NAME: 'tap-client',
  RESOURCE_GROUP: ENV.VITE_APP_APP_INSIGHTS_RESOURCE_GROUP,
  SUBSCRIPTION_ID: ENV.VITE_APP_APP_INSIGHTS_SUBSCRIPTION_ID,
  TENANT_PRIMARY_DOMAIN: ENV.VITE_APP_APP_INSIGHTS_TENANT_PRIMARY_DOMAIN,
});

const APP_INSIGHTS_IS_ENABLED = navigator.cookieEnabled && !isEnabled;

const LOGGING_LEVELS = Object.freeze({
  DEBUG: 'DEBUG',
  INFO: 'INFO',
  WARN: 'WARN',
  ERROR: 'ERROR',
  SILENT: 'SILENT',
});

const LOG_LEVEL = LOGGING_LEVELS[ENV.VITE_APP_LOG_LEVEL] || LOGGING_LEVELS.ERROR;

const IS_LOCAL_AUTH_LOGGING_ENABLED = false;

const IS_DEBUG_LOG_LEVEL_ENABLED = [LOGGING_LEVELS.DEBUG].includes(LOG_LEVEL);
const IS_INFO_LOG_LEVEL_ENABLED = [LOGGING_LEVELS.DEBUG, LOGGING_LEVELS.INFO].includes(LOG_LEVEL);
const IS_WARN_LOG_LEVEL_ENABLED = [LOGGING_LEVELS.DEBUG, LOGGING_LEVELS.INFO, LOGGING_LEVELS.WARN].includes(LOG_LEVEL);
const IS_ERROR_LOG_LEVEL_ENABLED = [
  LOGGING_LEVELS.DEBUG,
  LOGGING_LEVELS.INFO,
  LOGGING_LEVELS.WARN,
  LOGGING_LEVELS.ERROR,
].includes(LOG_LEVEL);

export {
  APP_INSIGHTS,
  APP_INSIGHTS_IS_ENABLED,
  IS_DEBUG_LOG_LEVEL_ENABLED,
  IS_ERROR_LOG_LEVEL_ENABLED,
  IS_INFO_LOG_LEVEL_ENABLED,
  IS_LOCAL_AUTH_LOGGING_ENABLED,
  IS_WARN_LOG_LEVEL_ENABLED,
  LOG_LEVEL,
  LOGGING_LEVELS,
};
