import { API_PATHS } from 'constants/API';
import tapApi from './api';

const voyagesApi = tapApi.enhanceEndpoints({ addTagTypes: ['VoyagesSummary'] }).injectEndpoints({
  endpoints: (build) => ({
    getVoyages: build.query({
      keepUnusedDataFor: 180,
      providesTags: (res, err, { offerCode, voyageNameBase64 }) => [
        { type: 'VoyagesSummary', id: `${voyageNameBase64}${offerCode ? `-${offerCode}` : ''}` },
      ],
      queryFn: async ({ currency, offerCode, office, voyageNameBase64 }, _api, _extraOptions, baseQuery) => {
        const url = API_PATHS.voyages({ currency, offerCode, office, voyageNameBase64 });
        const response = await baseQuery(url);
        return response;
      },
    }),
  }),
});

const { useGetVoyagesQuery, usePrefetch: useGetVoyagesPrefetch } = voyagesApi;

export { useGetVoyagesPrefetch, useGetVoyagesQuery };
