const ALL_MONTHS = Object.freeze({
  1: { display: 'JAN', displayLong: 'January', isDisabled: true, value: 1 },
  2: { display: 'FEB', displayLong: 'February', isDisabled: true, value: 2 },
  3: { display: 'MAR', displayLong: 'March', isDisabled: true, value: 3 },
  4: { display: 'APR', displayLong: 'April', isDisabled: true, value: 4 },
  5: { display: 'MAY', displayLong: 'May', isDisabled: true, value: 5 },
  6: { display: 'JUN', displayLong: 'June', isDisabled: true, value: 6 },
  7: { display: 'JUL', displayLong: 'July', isDisabled: true, value: 7 },
  8: { display: 'AUG', displayLong: 'August', isDisabled: true, value: 8 },
  9: { display: 'SEP', displayLong: 'September', isDisabled: true, value: 9 },
  10: { display: 'OCT', displayLong: 'October', isDisabled: true, value: 10 },
  11: { display: 'NOV', displayLong: 'November', isDisabled: true, value: 11 },
  12: { display: 'DEC', displayLong: 'December', isDisabled: true, value: 12 },
});

const AVAILABLE_LENGTHS = [
  { label: 'Up to 8 Days', min: 1, max: 8 },
  { label: '9 - 11 Days', min: 9, max: 11 },
  { label: '12 - 17 Days', min: 12, max: 17 },
  { label: '18 - 23 Days', min: 18, max: 23 },
  { label: '24+ Days', min: 24, max: 999 },
];

const AVAILABLE_LENGTHS_KEY = Object.fromEntries(AVAILABLE_LENGTHS.map((avlen) => [avlen.min, avlen]));

const AVAILABLE_DURATION_OPTIONS = AVAILABLE_LENGTHS.map((avlen) => ({ label: avlen.label, value: avlen }));

const DEFAULT_FILTER_FN = () => false;

const DEFAULT_FILTER_SELECTIONS = {
  selectedCountries: [],
  selectedDateRange: [],
  selectedDurationRanges: [],
  selectedEndCities: [],
  selectedPortCities: [],
  selectedShips: [],
  selectedStartCities: [],
  selectedTabCategories: [],
  selectedVoyageNames: [],
  selectedVoyageTypes: [],
};

const DEFAULT_SUBCATEGORY_KEY = 'subCategories';

const SORT_TYPE = {
  FEATURED: 'Featured',
  PRICE: 'Price',
};

const VOYAGE_TYPE_TAB_LABELS = {
  ocean: 'Oceans',
  waterway: 'Waterways',
  river: 'Rivers',
};

const TABS = {
  regions: {
    apiProp: 'regions',
    fallbackDisplayName: 'Regions',
    fallbackItineraryProp: 'countries',
    fallbackItineraryPropMapper: null,
    filterProp: 'regions',
    itineraryProp: 'regionIDs',
    itineraryPropMapper: null,
    key: 'regions',
    mabStringsKey: 'regions',
    subCategoriesKey: 'subRegions',
  },
  waterways: {
    apiProp: 'waterways',
    fallbackDisplayName: 'Waterways',
    fallbackItineraryProp: 'voyageType',
    fallbackItineraryPropMapper: (itinValue) => [VOYAGE_TYPE_TAB_LABELS[itinValue]],
    filterProp: 'waterways',
    itineraryProp: 'waterways',
    itineraryPropMapper: null,
    key: 'waterways',
    mabStringsKey: 'waterways',
    subCategoriesKey: 'waterways',
  },
  bespokeCategories: {
    apiProp: 'bespokeCategories',
    fallbackDisplayName: 'Seasonal & Combination',
    fallbackItineraryProp: 'voyageType',
    fallbackItineraryPropMapper: (itinValue) => [VOYAGE_TYPE_TAB_LABELS[itinValue]],
    filterProp: 'bespokeCategories',
    itineraryProp: 'cruiseCategories',
    itineraryPropMapper: null,
    key: 'bespokeCategories',
    mabStringsKey: 'seasonalAndCombos',
    subCategoriesKey: 'bespokeCategories',
  },
};

const DEFAULT_VIEW_STATE = {
  activeTabKey: TABS.regions.key,
  isSoldOutVisible: false,
  showResults: false,
  sortBy: SORT_TYPE.FEATURED,
};

export {
  ALL_MONTHS,
  AVAILABLE_DURATION_OPTIONS,
  AVAILABLE_LENGTHS,
  AVAILABLE_LENGTHS_KEY,
  DEFAULT_FILTER_FN,
  DEFAULT_FILTER_SELECTIONS,
  DEFAULT_SUBCATEGORY_KEY,
  DEFAULT_VIEW_STATE,
  SORT_TYPE,
  TABS,
};
