import { TAP_API_BASE_URL } from 'constants/ENV';
import { ACCOUNT_ERROR_ENABLED, BOOKINGS_ENABLED, DELAY } from 'constants/MOCK_CONFIG';
import { HttpResponse, delay, http } from 'msw';

const handlers = [];

if (ACCOUNT_ERROR_ENABLED) {
  const agencyUrl = `${TAP_API_BASE_URL}/agency/*`;

  handlers.push(
    http.get(agencyUrl, async () => {
      await delay(DELAY);
      return new HttpResponse(null, {
        status: 409,
        statusText: 'Conflict',
      });
    })
  );
}

if (BOOKINGS_ENABLED) {
  const url = `${TAP_API_BASE_URL}/agent/bookings/*`;
  handlers.push(
    http.get(url, async () => {
      await delay(DELAY);
      const json = await import('mocks/data/bookings/bookings.json');
      return HttpResponse.json(json);
    })
  );
}

export default handlers;
