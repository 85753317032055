import Image from '@viking-eng/image';
import PropTypes from 'prop-types';
import { memo } from 'react';

const HeaderLogoImage = memo(({ isHeaderLogoVisible, logo, headerTitle }) => {
  return isHeaderLogoVisible ? (
    <div className="logo-column">{logo && <Image className="logo-image" src={logo} alt="logo" />}</div>
  ) : (
    <div className="public-page-header-title">{headerTitle}</div>
  );
});

HeaderLogoImage.propTypes = {
  isHeaderLogoVisible: PropTypes.bool,
  logo: PropTypes.string,
  headerTitle: PropTypes.string,
};

export default HeaderLogoImage;
