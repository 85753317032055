import { API_PATHS } from 'constants/API';
import tapApi from './api';

const shipStateroomCategoriesApi = tapApi.injectEndpoints({
  endpoints: (build) => ({
    getShipStateroomCategories: build.query({
      keepUnusedDataFor: 300,
      providesTags: (res, err, { shipId }) => [{ type: 'ShipStateroomCategories', id: shipId }],
      queryFn: async ({ shipId }, _api, _extraOptions, baseQuery) => {
        const url = API_PATHS.shipStateroomCategories({ shipId });
        const response = await baseQuery(url);
        return response;
      },
    }),
  }),
});

const { useLazyGetShipStateroomCategoriesQuery, usePrefetch: useGetShipStateroomCategoriesPrefetch } =
  shipStateroomCategoriesApi;

export { useGetShipStateroomCategoriesPrefetch, useLazyGetShipStateroomCategoriesQuery };
