import ROUTES, { BASE_PATH } from 'constants/ROUTES';
import { useSelector } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';
import { getIsRouteAuthorized } from 'utils/auth';
import Layout from './Layout';

const AuthRoute = () => {
  const auth = useSelector((state) => state.auth);
  const location = useLocation();
  const isAuthorized = getIsRouteAuthorized({ auth });
  return isAuthorized ? (
    <Layout />
  ) : (
    <Navigate to={`${BASE_PATH}${ROUTES.notFound.url}`} replace state={{ from: location }} />
  );
};

export default AuthRoute;
