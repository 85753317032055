import { EventType, InteractionRequiredAuthError, InteractionStatus } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';
import api from 'app/services/tap/api';
import { clearAuth, setAuth } from 'app/slices/auth/authSlice';
import {
  AUTH_ERROR_CODES,
  AUTH_LOGIN_MAX_AUTO_TRIES,
  AUTH_USER_TYPES,
  B2C_AUTH_ERROR_CODES,
} from 'common/auth/AUTH_CONSTANTS';
import { loginRequest as authConfigLoginRequest, b2cPolicies, msalConfig } from 'configs/authConfig';
import { BROADCAST_CHANNEL_VALUES, STORAGE_KEYS } from 'constants/CONFIG';
import { IS_LOCAL_AUTH_LOGGING_ENABLED } from 'constants/LOGGING';
import ROUTES, { BASE_PATH } from 'constants/ROUTES';
import { MYJOURNEY_PATH } from 'constants/STRINGS';
import { useMemo, useRef } from 'react';
import { useDispatch } from 'react-redux';
import {
  addEventCallback,
  clearCache,
  enableAccountStorageEvents,
  getAccount,
  getActiveAccount,
  handleRedirectPromise,
  loginRedirect,
  logoutRedirect,
  setActiveAccount,
  ssoSilent,
} from 'utils/auth';
import { removeAllCookies } from 'utils/cookies';
import { addLoader, removeLoaderWithTimer } from 'utils/loader';
import { localLogger, logError, logInfo, logWarning } from 'utils/logging';
import { getRedirectStartPage, getRouteFromPathname, onNavigate } from 'utils/routing';

const PERSIST_POST_LOGOUT = [STORAGE_KEYS.homePageModal];

const clearLocalStorageWithExceptions = (exceptedKeys = []) => {
  if (window?.localStorage) {
    try {
      const tempStore = {};
      exceptedKeys.forEach((exceptedKey) => {
        tempStore[exceptedKey] = window.localStorage.getItem(exceptedKey);
      });
      window.localStorage.clear();
      Object.entries(tempStore).forEach(([persistedKey, persistedValue]) => {
        window.localStorage.setItem(persistedKey, persistedValue);
      });
    } catch (error) {
      logError('useAuth - clearLocalStorageWithExceptions', error);
    }
  }
};

const useAuth = () => {
  const { inProgress } = useMsal();
  const dispatch = useDispatch();
  const isInitialLogin = useRef(false);
  const isLoggingIn = useRef(false);
  const loginTries = useRef(0);
  const broadcastChannel = useMemo(() => new BroadcastChannel(BROADCAST_CHANNEL_VALUES.accountRemoved), []);

  const getLoginTries = () => loginTries.current;

  const msalEventCallbackHandleError = async ({ event } = {}) => {
    try {
      const { error } = event;
      const { errorCode, errorMessage } = error;
      logWarning('useAuth - msalEventCallbackHandleError - expected error', JSON.stringify(event));
      if (
        error instanceof InteractionRequiredAuthError ||
        (errorCode === AUTH_ERROR_CODES.ACCESS_DENIED && errorMessage.includes(B2C_AUTH_ERROR_CODES.CANCEL_ACTION))
      ) {
        const route = getRouteFromPathname();
        if (route?.isPublic) {
          // Do not redirect
          logInfo('useAuth - msalEventCallbackHandleError - Skip auth on public page');
          await Promise.allSettled([
            logoutRedirect({
              preventRedirect: route?.isPublic,
              redirectUri: route?.url ? `${BASE_PATH}${route?.url}` : getRedirectStartPage(),
            }),
            clearCache(),
            updateAgent({ agent: {}, agency: {} }),
            () => localStorage.removeItem('logout-event'),
          ]);
        } else if (!isLoggingIn.current) {
          isLoggingIn.current = true;
          try {
            const redirectStartPage = getRedirectStartPage();
            await loginRedirect(msalConfig, redirectStartPage);
          } catch (err) {
            logError('useAuth - msalEventCallbackHandleError', err);
          }
          isLoggingIn.current = false;
        }
      } else if (
        errorCode === AUTH_ERROR_CODES.INVALID_GRANT &&
        errorMessage.includes(B2C_AUTH_ERROR_CODES.INCORRECT_POLICY)
      ) {
        const matches = errorMessage.match(/Expected Value : (B2C_[a-zA-Z0-9_]*)/i);
        if (matches.length > 1) {
          const requestedPolicy = matches[1].toUpperCase();
          const authorityWithPolicy = Object.values(b2cPolicies.authorities).find((authority) =>
            authority.authority.toUpperCase().includes(requestedPolicy)
          );
          if (!isLoggingIn.current) {
            isLoggingIn.current = true;
            await loginRedirect({ ...msalConfig, authority: authorityWithPolicy?.authority });
            isLoggingIn.current = false;
          }
        } else {
          logError('useAuth - msalEventCallbackHandleError - Event logged above was unhandled');
        }
      } else if (errorCode === AUTH_ERROR_CODES.AUTHORITY_MISMATCH) {
        broadcastChannel.postMessage({ name: BROADCAST_CHANNEL_VALUES.accountRemoved });
        await logout({ callingFunction: 'useAuth.jsx - msalEventCallbackHandleError 3' });
      } else {
        logInfo('useAuth - msalEventCallbackHandleError - Unknown error', event);
      }
      logInfo('useAuth - msalEventCallbackHandleError - success');
    } catch (error) {
      logError('useAuth - msalEventCallbackHandleError', error);
    }
  };

  const msalEventCallbackHandleSuccess = async ({ event, initializeCallback } = {}) => {
    try {
      if (event.eventType === EventType.ACCOUNT_REMOVED) {
        broadcastChannel.postMessage({ name: BROADCAST_CHANNEL_VALUES.accountRemoved });
        await logout({ callingFunction: 'useAuth.jsx - msalEventCallbackHandleSuccess' });
        logInfo('useAuth - msalEventCallbackHandleSuccess - logout success');
      } else if (
        [EventType.LOGIN_SUCCESS, EventType.ACQUIRE_TOKEN_SUCCESS, EventType.SSO_SILENT_SUCCESS].includes(
          event.eventType
        )
      ) {
        const loginAccount = event?.payload?.account || {};
        if (loginAccount?.idTokenClaims?.UserType && loginAccount?.idTokenClaims?.UserType !== AUTH_USER_TYPES.TA) {
          logInfo('useAuth - msalEventCallbackHandleSuccess - MVJ path success');
          window.location.replace(MYJOURNEY_PATH);
        }
        if (loginAccount) {
          setActiveAccount({ account: loginAccount });
          initializeCallback({ account: loginAccount });
          logInfo('useAuth - msalEventCallbackHandleSuccess - success - intitializing with loginAccount');
        } else {
          initializeCallback({ account: getAccount() });
          logInfo('useAuth - msalEventCallbackHandleSuccess - success - intitializing with getAccount');
        }
      } else {
        initializeCallback({ account: getAccount() });
        logInfo('useAuth - msalEventCallbackHandleSuccess - success - intitializing with getAccount');
      }
      logInfo('useAuth - msalEventCallbackHandleSuccess - success');
    } catch (error) {
      logError('useAuth - msalEventCallbackHandleSuccess', error);
    }
  };

  const msalEventCallback = ({ initializeCallback } = {}) => {
    return async (event) => {
      if (event.error) {
        msalEventCallbackHandleError({ event });
      } else {
        msalEventCallbackHandleSuccess({ event, initializeCallback });
      }
    };
  };

  const initialize = async (initializeCallback) => {
    try {
      const routeFromPath = getRouteFromPathname();
      enableAccountStorageEvents();
      addEventCallback(
        msalEventCallback({
          initializeCallback,
        })
      );
      await handleRedirectPromise();
      const currentAccount = getAccount();
      if (currentAccount) {
        setActiveAccount({ currentAccount });
      }
      if (!currentAccount && !routeFromPath.isPublic) {
        if (!isInitialLogin.current) {
          isInitialLogin.current = true;
          await ssoSilent(msalConfig);
        }
      }
      logInfo('useAuth - initialize - success');
    } catch (error) {
      if ([AUTH_ERROR_CODES.INVALID_GRANT].includes(error.errorCode)) {
        logWarning('useAuth - initialize - expected error', error);
        // Expected if user doing anything other than password login. Handled in the msalEventCallback.
      } else {
        logError('useAuth - initialize - Unhandled on initialize', error);
      }
    }
  };

  const login = async ({
    callingFunction = '',
    isPreCheckRequired = true,
    loginRequest = authConfigLoginRequest,
  } = {}) => {
    try {
      localLogger(`useAuth.js - Logging in from ${callingFunction}`, IS_LOCAL_AUTH_LOGGING_ENABLED);
      if (isPreCheckRequired) {
        const isMaxLoginReached = loginTries.current >= AUTH_LOGIN_MAX_AUTO_TRIES;
        loginTries.current += 1;
        if (!isMaxLoginReached) {
          if (inProgress === InteractionStatus.None) {
            await handleRedirectPromise();
            const account = getActiveAccount();
            if (!account) {
              await loginRedirect(loginRequest);
            }
          }
        } else {
          loginTries.current = 0;
          await logout({ callingFunction: 'useAuth.js - login 1' });
        }
      } else if (inProgress === InteractionStatus.None) {
        await loginRedirect(loginRequest);
      } else {
        loginTries.current = 0;
        await logout({ callingFunction: 'useAuth.js - login 2' });
      }
      logInfo('useAuth - login - success');
    } catch (error) {
      logError('useAuth - login', error);
    }
  };

  const logout = async ({ callingFunction = '' } = {}) => {
    try {
      localLogger(`useAuth.js - Logging out from ${callingFunction}`, IS_LOCAL_AUTH_LOGGING_ENABLED);
      addLoader();
      try {
        dispatch(clearAuth());
        dispatch(api.util.resetApiState());
        removeAllCookies();
        clearLocalStorageWithExceptions(PERSIST_POST_LOGOUT);
        localStorage.setItem('logout-event', 'started');
        const route = getRouteFromPathname();
        await Promise.allSettled([
          logoutRedirect({
            preventRedirect: route?.isPublic,
            redirectUri: route?.url ? `${BASE_PATH}${route?.url}` : getRedirectStartPage(),
          }),
          clearCache(),
          updateAgent({ agent: {}, agency: {} }),
          () => localStorage.removeItem('logout-event'),
        ]);
      } catch (error) {
        logError('useAuth - logout', { error });
        onNavigate(ROUTES.maintenance.url);
      } finally {
        await removeLoaderWithTimer();
      }
      logInfo('useAuth - logout - success');
    } catch (error) {
      logError('useAuth - logout', error);
    }
  };

  const updateAgent = ({ agent, agency }) => {
    dispatch(setAuth({ agency, agent, callingFunction: 'useAuth.js - updateAgent' }));
  };

  return {
    getLoginTries,
    initialize,
    login,
    logout,
    updateAgent,
  };
};

export default useAuth;
