import { CMS_REDIRECT_URL_KEYS } from 'constants/API';

const handleRedirectLinks = ({ redirectUrl, isRedirectModalEnabled = true, useRedirectClickValues }) => {
  if (redirectUrl && useRedirectClickValues) {
    useRedirectClickValues.onSetRedirectModalEnabled(isRedirectModalEnabled);
    switch (redirectUrl) {
      case CMS_REDIRECT_URL_KEYS.academy:
        useRedirectClickValues.onAcademyClick();
        break;
      case CMS_REDIRECT_URL_KEYS.marketing:
        useRedirectClickValues.onMarketingClick();
        break;
      case CMS_REDIRECT_URL_KEYS.rewards:
        useRedirectClickValues.onRewardsClick();
        break;
      default:
        break;
    }
  }
};

export { handleRedirectLinks };
