import { VIKING_OFFICE_BY_COUNTRY } from 'constants/COUNTRIES';
import { ADOBE_DTM_KEY, ENVIRONMENT_CODE } from 'constants/ENV';
import { keyify } from 'utils/string';

const ANALYTICS_EVENT_TYPES = Object.freeze({
  ACCOUNT_CREATION: 'account_creation',
  BANNER_CLICK: 'banner_click',
  BANNER_NEXT: 'banner_next',
  BANNER_PREVIOUS: 'banner_prev',
  BUTTON_CLICK: 'cta_button_click',
  DP_FILTER: 'dp_filter',
  FAC_FILTER: 'fac_filter',
  FOOTER_CLICK: 'footer_link_click',
  FORM_START: 'form_start',
  MAIN_NAVIGATION: 'main_navigation',
  PAGE_INIT: 'page_data_initialized',
  PAGE_VIEW: 'spa_page_view',
  SEARCH: 'internal_site_search',
  TILE_CLICK: 'tile_clicked',
  VIDEO_START: 'video_start',
  HOMEPAGE_MODAL_OPEN: 'modal_view',
  HOMEPAGE_MODAL_CLOSE: 'modal_close',
});
const ANALYTICS_NAMES_AND_TYPES = Object.freeze({
  HOMEPAGE_MODAL_NAME: 'explorers_sale ',
  HOMEPAGE_MODAL_TYPE: 'tap_homepage',
});

const ANALYTICS_LINK_TYPES = Object.freeze({
  BUTTON: 'button',
  TEXT: 'text',
  PHOTO: 'photo',
  LINK: 'link',
  BANNER: 'banner',
  CHECKBOX: 'checkbox',
});

const initializeTagData = (initialData, country, isRefresh) => {
  const { cspNonce, ...tagData } = initialData || {};
  const adobeEvent = cleanData({
    ...tagData,
    acdl_event: ANALYTICS_EVENT_TYPES.PAGE_INIT,
    csp_nonce: cspNonce,
    domain_country_code: VIKING_OFFICE_BY_COUNTRY[country] || 'US',
    environment_code: ENVIRONMENT_CODE || '',
    event: ANALYTICS_EVENT_TYPES.PAGE_INIT,
    origin_country_code: window?.OneTrust?.getGeolocationData()?.country || country || 'US',
    refresh: isRefresh,
    site_code: 'tap',
  });

  if (!Array.isArray(window.adobeDataLayer)) {
    window.adobeDataLayer = [];
  }
  window.adobeDataLayer.push(adobeEvent);
};

const insertAdobeDtmScript = (nonce) => {
  try {
    let adobeDtmScriptElement = document.getElementById('adobeDtmScript');
    if (!adobeDtmScriptElement && window.sessionStorage) {
      const host = ADOBE_DTM_KEY || 'launch-2f0c08439ba2';
      const adobeDtmSrc = ['https://assets.adobedtm.com/2fea3a6f04fc/6d1171907c18/', host, '.min.js'].join('');
      adobeDtmScriptElement = document.createElement('script');
      adobeDtmScriptElement.id = 'adobeDtmScript';
      adobeDtmScriptElement.src = adobeDtmSrc;
      if (nonce) {
        adobeDtmScriptElement.setAttribute('nonce', nonce);
      }
      document.head.appendChild(adobeDtmScriptElement);
    }
  } catch (ex) {
    // eslint-disable-next-line no-console
    console.warn(ex);
  }
};

const triggerPageView = ({ path = '/', pageId, pageName, isLoggedIn, ...additionalAttributes }) => {
  const attributes = additionalAttributes && JSON.parse(JSON.stringify(additionalAttributes).replace(/:null/gi, ':""'));
  const eventData = {
    ...attributes,
    acdl_event: 'view',
    event: ANALYTICS_EVENT_TYPES.PAGE_VIEW,
    login_status: `${isLoggedIn || 'false'}`,
    origin_country_code: window?.OneTrust?.getGeolocationData()?.country || 'US',
    page_id: pageId,
    page_name: pageName || '',
    page: path || '',
  };
  delete eventData.isLoggedIn;
  delete eventData.pageId;
  delete eventData.pageName;

  fireViewEvent(eventData);
};

const getAnalyticsState = (stateProperty) => {
  if (!window.adobeDataLayer) {
    window.adobeDataLayer = [];
  } else if (typeof window.adobeDataLayer === 'object' && typeof window.adobeDataLayer.getState === 'function') {
    return window.adobeDataLayer.getState(stateProperty);
  }

  return '';
};

const triggerLinkEvent = ({ linkText, linkType = ANALYTICS_LINK_TYPES.LINK, linkId, ...additionalAttributes }) => {
  const attributes = additionalAttributes && JSON.parse(JSON.stringify(additionalAttributes).replace(/:null/gi, ':""'));
  const eventData = {
    acdl_event: 'link',
    cta_name: keyify(linkText),
    element_name: linkId,
    link_id: keyify(linkId),
    link_type: linkType,
    origin_country_code: window?.OneTrust?.getGeolocationData()?.country || 'US',
    page_id: getAnalyticsState('page_id') || keyify(window.document?.title) || '',
    page_name: getAnalyticsState('page_name') || keyify(window.document?.title) || '',

    ...attributes,
  };
  fireLinkEvent(eventData);
};

const triggerFilterEvent = ({ category, value, ...otherProps }) => {
  triggerLinkEvent({
    ...otherProps,
    event: ANALYTICS_EVENT_TYPES.FAC_FILTER,
    fac_category: category,
    filter_value: value,
  });
};

// Private Functions //
const fireAdobeEvent = (tagData) => {
  const adobeEvent = cleanData({
    ...tagData,
  });

  if (!Array.isArray(window.adobeDataLayer)) {
    window.adobeDataLayer = [];
  }
  window.adobeDataLayer.push(adobeEvent);
};

const fireAdobeTargetView = (tagData) => {
  window?.adobe?.target?.triggerView(tagData.page_id);
};

const fireViewEvent = (inputTagData) => {
  const tagData = cleanData(inputTagData);
  fireAdobeEvent(tagData);
  fireAdobeTargetView(tagData);
};

const fireLinkEvent = (inputTagData) => {
  const tagData = cleanData(inputTagData);
  fireAdobeEvent(tagData);
};

const cleanData = (data) =>
  Object.keys(data)
    .sort()
    .reduce((acc, key) => {
      if (!!data[key] || [0, '0'].includes(data[key])) {
        return { ...acc, [key]: data[key] };
      }
      return acc;
    }, {});

const updateAdobeTargetSettings = (nonce) => {
  window.targetGlobalSettings = window.targetGlobalSettings || {};
  window.targetGlobalSettings.cspScriptNonce = nonce;
};

export {
  ANALYTICS_EVENT_TYPES,
  ANALYTICS_LINK_TYPES,
  ANALYTICS_NAMES_AND_TYPES,
  initializeTagData,
  insertAdobeDtmScript,
  triggerFilterEvent,
  triggerLinkEvent,
  triggerPageView,
  updateAdobeTargetSettings,
};
