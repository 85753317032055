import Button from '@viking-eng/button';
import { getAccessibilityFunctions } from '@viking-eng/utils';
import useEventListener from 'hooks/useEventListener';
import usePrefetch from 'hooks/usePrefetch';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useRef, useState } from 'react';
import { isRedirectUrl } from 'utils/routing';
import './Menu.scss';

const Menu = ({ getRedirectLink, id, isOpen, items, children, onNavigate, position, setIsOpen, tabIndex = 0 }) => {
  const { prefetchDataByRouteKey } = usePrefetch();
  const menuRef = useRef();
  const [isMenuContentFullHeight, setIsMenuContentFullHeight] = useState(false);
  const fullHeightBodyClass = position ? `fullheight-menu-is-open--${position}` : 'fullheight-menu-is-open';

  const setMenuHeight = () => {
    const viewportHeight = window?.innerHeight;
    const headerHeight = document.querySelector('.header')?.offsetHeight;
    if (menuRef.current?.style) {
      const menuHeight = menuRef.current.offsetHeight;
      if (viewportHeight - headerHeight <= menuHeight) {
        setIsMenuContentFullHeight(true);
        menuRef.current.style.height = `${viewportHeight - headerHeight}px`;
        menuRef.current.style.overflowY = 'auto';
        menuRef.current.scrollTop = 0;
      } else {
        setIsMenuContentFullHeight(false);
        menuRef.current.style.height = '';
      }
    }
  };

  const toggleBodyClass = useCallback(() => {
    if (isOpen && isMenuContentFullHeight) {
      document.body.classList.add(fullHeightBodyClass);
    } else {
      document.body.classList.remove(fullHeightBodyClass);
    }
  }, [fullHeightBodyClass, isMenuContentFullHeight, isOpen]);

  const handleMenuChange = useCallback(() => {
    if (isOpen) {
      setMenuHeight();
    }
    toggleBodyClass();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const handleMenuItemClick = useCallback(
    (item) => {
      setIsOpen(false);
      if (item.onClick) {
        item.onClick();
      } else if (onNavigate) {
        onNavigate({ url: item.url, target: item.openInNewWindow ? '_blank' : '' });
      }
    },
    [setIsOpen, onNavigate]
  );

  useEffect(() => {
    handleMenuChange();
  }, [handleMenuChange]);

  useEffect(() => {
    toggleBodyClass();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMenuContentFullHeight]);

  useEventListener('resize', handleMenuChange, window);

  const primaryMenuItems = items?.filter((item) => item?.isSmallText === false);
  const secondaryMenuItems = items?.filter((item) => item?.isSmallText === true);

  return (
    <div className="menu">
      {children}
      <div className={`menu-collapse ${isOpen ? 'show' : 'collapse'}`} id={id} ref={menuRef}>
        {primaryMenuItems?.map((item) => {
          const itemProps = {
            ...getAccessibilityFunctions(),
            'data-dismiss': item['data-dismiss'],
            'data-target': item['data-target'],
            'data-toggle': item['data-toggle'],
            rel: 'noopener noreferrer',
            tabIndex,
            target: item.openInNewWindow ? '_blank' : '',
          };
          return (
            <div className="menu-row menu-items-divider menu-item d-block" key={item.id}>
              {item?.url && isRedirectUrl(item?.url) ? (
                getRedirectLink({ item, itemProps, setIsOpen })
              ) : (
                <Button
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...itemProps}
                  attributes={{ onMouseEnter: () => prefetchDataByRouteKey(item.id) }}
                  onButtonClick={() => handleMenuItemClick(item)}
                >
                  {item.title}
                </Button>
              )}
            </div>
          );
        })}
        {secondaryMenuItems?.length > 0 && (
          <div className="secondary-menu">
            {secondaryMenuItems?.map((item) => {
              const itemProps = {
                ...getAccessibilityFunctions(),
                'data-dismiss': item['data-dismiss'],
                'data-target': item['data-target'],
                'data-toggle': item['data-toggle'],
                key: item.id,
                rel: 'noopener noreferrer',
                tabIndex,
                target: item.openInNewWindow ? '_blank' : '',
              };
              return (
                <div className="menu-items-row small-body-copy menu-item" key={item.id}>
                  <Button
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...itemProps}
                    attributes={{ onMouseEnter: () => prefetchDataByRouteKey(item.key) }}
                    onButtonClick={() => handleMenuItemClick(item)}
                  >
                    {item.title}
                  </Button>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

Menu.propTypes = {
  children: PropTypes.node,
  getRedirectLink: PropTypes.func,
  id: PropTypes.string.isRequired,
  isOpen: PropTypes.bool,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      isSmallText: PropTypes.bool.isRequired,
      openInNewWindow: PropTypes.bool,
      title: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    }).isRequired
    // TODO: Fix prop-types in UI Kit
  ).isRequired, // VikingPropTypes.menuItems.isRequired,
  onNavigate: PropTypes.func.isRequired,
  position: PropTypes.string,
  setIsOpen: PropTypes.func.isRequired,
  tabIndex: PropTypes.string,
};

export default Menu;
