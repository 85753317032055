import ROUTES, { BASE_PATH } from 'constants/ROUTES';
import { useResolvedPath } from 'react-router-dom';
import { getRouteFromPathname } from 'utils/routing';

const useRoute = () => {
  const { pathname } = useResolvedPath();
  let route = getRouteFromPathname(pathname);
  if (route.subRoutes) {
    let pathName = window.location.pathname;
    if (pathName?.endsWith('/')) {
      pathName = pathName.slice(0, -1);
    }
    const subRouteSegments = pathName.replace(`${BASE_PATH}${route.url}`, '').split('/');
    const getSubRouteByKey = (key) => route.subRoutes[key];
    let subRoute;
    for (let i = subRouteSegments.length - 1; i >= 0; i -= 1) {
      if (!subRoute) {
        const subRouteLookup = subRouteSegments?.pop();
        subRoute =
          route.subRoutes[subRouteLookup] ||
          Object.keys(route.subRoutes).find((key) => getSubRouteByKey(key) === subRouteLookup);
      }
    }
    if (subRoute) {
      route = ROUTES[subRoute];
    }
  }

  return route;
};

export default useRoute;
