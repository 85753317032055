// TODO: As part of IM-1206, we may be able to remove the logic for transformResponseForSecondaryCall,
// assuming the secondary call can be made in API.
// See corresponding blackhawk.js in tap-api and ticket for more details: https://vikingtravel.atlassian.net/browse/IM-1206
import Button from '@viking-eng/button';
import { ANALYTICS_EVENT_TYPES, triggerLinkEvent } from 'analytics/Analytics';
import { setIsRedirectModalEnabled, setRedirectPage } from 'app/slices/redirect/redirectSlice';
import classnames from 'classnames';
import { AUTH_ERRORS } from 'common/auth/AUTH_CONSTANTS';
import { API_PATH_AUTH, CMS_REDIRECT_URL_KEYS } from 'constants/API';
import { IS_APP_INSIGHTS_ENABLED } from 'constants/ENV';
import { nanoid } from 'nanoid';
import PropTypes from 'prop-types';
import { forwardRef, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getToken } from 'utils/auth';
import { getRequestHeaders } from 'utils/httpsUtils';
import { logError, logInfo } from 'utils/logging';
import { openUrlInTabWithFallback } from 'utils/routing';
import { appInsightsHelper } from 'utils/telemetry';
import './RedirectLink.scss';

const RedirectLink = forwardRef(
  (
    {
      action,
      buttonProps,
      children,
      className,
      cmsRedirectUrlKey,
      formData = [],
      isHidden,
      buttonType = 'button',
      method = 'GET',
      onClick = null,
      target = '_blank',
      transformResponseForSecondaryCall,
    },
    ref
  ) => {
    const { isRedirectModalEnabled } = useSelector((state) => state.redirectToPage);
    const dispatch = useDispatch();
    const auth = useSelector((state) => state.auth);
    const {
      agency: { office },
      agent: { email, tapEmail },
    } = auth;

    const REDIRECT_CONFIG = {
      [CMS_REDIRECT_URL_KEYS.academy]: {
        action: API_PATH_AUTH.agentSsoAcademy(tapEmail || email),
      },
      [CMS_REDIRECT_URL_KEYS.marketing]: {
        action: API_PATH_AUTH.agentSsoMarketing(tapEmail || email),
      },
      [CMS_REDIRECT_URL_KEYS.rewards]: {
        action: API_PATH_AUTH.agentSsoRewards(tapEmail || email),
        transformResponseForSecondaryCall: (response) => {
          return {
            action: response.endpoint,
            method: 'POST',
            formData: [{ name: 'SAMLResponse', value: response.request.split('SAMLResponse=')[1] }],
          };
        },
      },
    };

    const redirected = REDIRECT_CONFIG[cmsRedirectUrlKey];
    const updatedAction = redirected?.action || action;
    const updatedTransformResponseForSecondaryCall =
      redirected?.transformResponseForSecondaryCall || transformResponseForSecondaryCall;

    if (!updatedAction && !cmsRedirectUrlKey) {
      throw new Error('Redirect link requires action or cmsRedirectUrlKey');
    }

    const formDataRef = useRef();
    const formRef = useRef();
    const id = useMemo(() => nanoid(), []);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
      if (ref?.current && formDataRef && formRef) {
        ref.current.type = updatedAction ? 'submit' : 'button';
        if (formRef.current) {
          formRef.current.action = updatedAction;
          formRef.current.method = method;
          formRef.current.target = updatedAction ? target : undefined;
        }
        if (formDataRef.current) {
          formData.forEach(({ name, value }) => {
            const input = document.createElement('input');
            input.setAttribute('type', 'hidden');
            input.setAttribute('name', name);
            input.setAttribute('value', value);
            formDataRef.current.appendChild(input);
          });
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ref, formDataRef, formRef]);

    const onButtonClick = async (event) => {
      setIsLoading(true);
      if (onClick) {
        onClick(event);
      }
      if (buttonProps?.onButtonClick) {
        buttonProps.onButtonClick(event);
      } else {
        const ssoKey = Object.keys(CMS_REDIRECT_URL_KEYS).find(
          (key) => CMS_REDIRECT_URL_KEYS[key] === cmsRedirectUrlKey
        );
        triggerLinkEvent({
          event: ANALYTICS_EVENT_TYPES.BUTTON_CLICK,
          eventName: `sso_link_${ssoKey}`,
          country: office,
          linkText: typeof children === 'string' ? children : ssoKey,
          linkType: buttonType,
          linkId: `sso_link_${ssoKey}`,
        });
      }

      if (isHidden && isRedirectModalEnabled) {
        dispatch(setRedirectPage(cmsRedirectUrlKey));
      }

      event.preventDefault();
      let tokenResponse;
      try {
        tokenResponse = await getToken();
      } catch (err) {
        if (err.message !== AUTH_ERRORS.IS_USER_NOT_SIGNED_IN) {
          logError('RedirectLink.jsx - onButtonClick', err);
        } else {
          logInfo('RedirectLink.jsx - onButtonClick', err);
        }
      }
      const headers = getRequestHeaders({
        appInsightsSessionId: IS_APP_INSIGHTS_ENABLED ? appInsightsHelper?.getSessionInfo()?.session?.id || '' : '',
        auth,
        token: tokenResponse.accessToken,
      });

      const response = await fetch(updatedAction, {
        method: 'GET',
        headers,
      });
      const json = await response.json();

      if (updatedTransformResponseForSecondaryCall) {
        const formattedResponse = updatedTransformResponseForSecondaryCall(json);

        ref.current.type = 'submit';
        formRef.current.action = formattedResponse.action;
        formRef.current.method = formattedResponse.method;
        formRef.current.target = target;

        formDataRef.current.innerHTML = '';
        formattedResponse.formData.forEach(({ name, value }) => {
          const input = document.createElement('input');
          input.setAttribute('type', 'hidden');
          input.setAttribute('name', name);
          input.setAttribute('value', value);
          formDataRef.current.appendChild(input);
        });

        formRef.current.requestSubmit();
        setIsLoading(false);
      } else if (json?.endpoint) {
        openUrlInTabWithFallback({ callback: () => setIsLoading(false), target, url: json.endpoint });
      }
      if (isHidden) {
        dispatch(setRedirectPage(''));
      }
      dispatch(setIsRedirectModalEnabled(true));
    };

    return cmsRedirectUrlKey === CMS_REDIRECT_URL_KEYS.rewards ? (
      <form
        action={updatedAction}
        className={classnames({
          'd-inline-block': !isHidden,
          'd-none': isHidden,
          'redirect-form': true,
          'is-loading': isLoading,
          [`is-styled-as-${buttonType}`]: true,
          [className]: className,
        })}
        id={id}
        method={method}
        onClick={onClick}
        ref={formRef}
        role="presentation"
        target={target}
      >
        <div ref={formDataRef} />
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <Button {...buttonProps} attributes={{ ref }} loading={isLoading} onButtonClick={onButtonClick}>
          {children}
        </Button>
      </form>
    ) : (
      <div
        className={classnames({
          'd-none': isHidden,
          'is-loading': isLoading,
          'redirect-link': true,
          [`is-styled-as-${buttonType}`]: true,
          [className]: className,
        })}
      >
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <Button {...buttonProps} attributes={{ ref }} loading={isLoading} onButtonClick={onButtonClick}>
          {children}
        </Button>
      </div>
    );
  }
);

RedirectLink.propTypes = {
  action: PropTypes.string,
  buttonProps: PropTypes.shape({
    callToActionUrl: PropTypes.string,
    onButtonClick: PropTypes.func,
  }),
  children: PropTypes.node,
  className: PropTypes.string,
  cmsRedirectUrlKey: PropTypes.string,
  formData: PropTypes.arrayOf(
    PropTypes.shape({ name: PropTypes.string.isRequired, value: PropTypes.string.isRequired })
  ),
  isHidden: PropTypes.bool,
  buttonType: PropTypes.oneOf(['link', 'image', 'button']),
  method: PropTypes.string,
  onClick: PropTypes.func,
  target: PropTypes.string,
  transformResponseForSecondaryCall: PropTypes.func,
};

export default RedirectLink;
