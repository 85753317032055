import { API_PATHS } from 'constants/API';
import tapApi from './api';

const stateroomsApi = tapApi.injectEndpoints({
  endpoints: (build) => ({
    getStaterooms: build.query({
      providesTags: (res, err, { offerCode, voyageId }) => [
        { type: 'StateroomsSummary', id: `${voyageId}${offerCode ? `-${offerCode}` : ''}` },
      ],
      queryFn: async ({ currency, offerCode, office, voyageId }, _api, _extraOptions, baseQuery) => {
        const url = API_PATHS.staterooms({ currency, offerCode, office, voyageId });
        const response = await baseQuery(url);
        return response;
      },
    }),
  }),
});

const { useGetStateroomsQuery, usePrefetch: useGetStateroomsPrefetch } = stateroomsApi;

export { useGetStateroomsPrefetch, useGetStateroomsQuery };
