import { createSlice } from '@reduxjs/toolkit';
import { INITIAL_STATE } from 'common/auth/AUTH_CONSTANTS';
import { OFFICES } from 'constants/COUNTRIES';
import { IS_LOCAL_AUTH_LOGGING_ENABLED } from 'constants/LOGGING';
import { getCmsOfficeCode } from 'pages/account/accountHelper';
import { localLogger, logError, logInfo } from 'utils/logging';
import { appInsightsHelper } from 'utils/telemetry';

const authSlice = createSlice({
  name: 'auth',
  initialState: INITIAL_STATE,
  reducers: {
    clearAuth: () => INITIAL_STATE,
    setAuth: (state, action) => {
      let newState = {};
      try {
        const { accountStatus, agency, agent, callingFunction, msal, token, ...rest } = action.payload || {};

        const updatedAgency = { ...state.agency, ...agency };
        const updatedAgent = { ...state.agent, ...agent };
        if (agency?.currency || agent?.country) {
          const cmsOffice = getCmsOfficeCode({ agency: updatedAgency, agent: updatedAgent, defaultOffice: OFFICES.US });
          if (cmsOffice) {
            updatedAgency.cmsOffice = cmsOffice;
            updatedAgent.cmsOffice = cmsOffice;
          }
        }
        localLogger(`authSlice.js - setAuth called from: ${callingFunction}`, IS_LOCAL_AUTH_LOGGING_ENABLED);

        newState = {
          ...state,
          ...rest,
          accountStatus: accountStatus || state.accountStatus,
          agency: updatedAgency,
          agent: updatedAgent,
          msal: { ...state.msal, ...msal },
          token: token || state.token,
        };
        appInsightsHelper.updateCustomDimensions({
          agencyName: newState?.agency?.agencyName,
          agencyNumber: newState?.agent?.agencyNumber,
          agentCode: newState?.agent?.agentCode,
          country: newState?.agent?.country,
          email: newState?.agent?.email,
          firstName: newState?.agent?.firstName,
          lastName: newState?.agent?.lastName,
          office: newState?.agent?.office,
          tapEmail: newState?.agent?.tapEmail,
        });
        logInfo('authSlice - setAuth - success');
      } catch (error) {
        logError('authSlice - setAuth', error);
      }
      return newState;
    },
  },
});

export const { clearAuth, setAuth, setAuthAccountModalRequired } = authSlice.actions;

export default authSlice;
