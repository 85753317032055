import PageLoading from 'common/pageLoading/PageLoading';
import ROUTES from 'constants/ROUTES';
import useAuth from 'hooks/useAuth';
import useNavigate from 'hooks/useNavigate';
import { useEffect, useRef } from 'react';
import { logError } from 'utils/logging';

const Login = () => {
  const { login, logout } = useAuth();
  const isLoggingIn = useRef(false);
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      if (!isLoggingIn.current) {
        isLoggingIn.current = true;
        try {
          await login({ callingFunction: 'Login.jsx - useEffect', isPreCheckRequired: false });
        } catch (error) {
          logError(error);
          navigate(ROUTES.maintenance.url);
        }
      }
    })();
  }, [login, logout, navigate]);

  return <PageLoading />;
};

export default Login;
