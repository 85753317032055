import { useIsAuthenticated } from '@azure/msal-react';
import Button from '@viking-eng/button';
import Account from '@viking-eng/icon/lib/Account';
import ChevronDown from '@viking-eng/icon/lib/ChevronDown';
import { getScreenTier } from '@viking-eng/utils';
import RedirectLink from 'common/link/RedirectLink';
import VikingHeader from 'common/ui-kit/Header/Header';
import { CMS_PATHS } from 'constants/API';
import { IMAGE_VIKING_LOGO_WHITE_HORIZONTAL } from 'constants/IMAGE';
import ROUTES from 'constants/ROUTES';
import { SITE_NAME, TRAVEL_ADVISOR } from 'constants/STRINGS';
import useAuth from 'hooks/useAuth';
import useCmsState from 'hooks/useCmsState';
import useHistory from 'hooks/useHistory';
import useNavigate from 'hooks/useNavigate';
import usePermissions from 'hooks/usePermissions';
import useRoute from 'hooks/useRoute';
import PropTypes from 'prop-types';
import { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { getIsRouteAuthorized } from 'utils/auth';
import { getItemsLikeJcrName } from 'utils/cms';
import { getAuthSortedRoutes } from 'utils/routing';
import './Header.scss';

const Header = ({ children }) => {
  const [isLeftMenuOpen, setIsLeftMenuOpen] = useState(false);
  const [isRightMenuOpen, setIsRightMenuOpen] = useState(false);
  const auth = useSelector((state) => state.auth);
  const { agent } = auth;
  const { logout } = useAuth();
  const { resetHistory } = useHistory();
  const data = useCmsState({ cmsPath: CMS_PATHS.pages.common }) || {};
  const firstName = agent.firstName || TRAVEL_ADVISOR;
  const isAuthenticated = useIsAuthenticated();
  const navigate = useNavigate();
  const { permissions, role } = usePermissions();
  const redirectRef = useRef();
  const route = useRoute();
  const screenTier = getScreenTier();

  const authRightItems = isAuthenticated
    ? getAuthSortedRoutes({
        auth,
        commonData: data?.common,
        getIsRouteAuthorized,
        getItemsLikeJcrName,
        navigate,
        orderKey: 'headerRightOrder',
        resetHistory,
        role,
      })
    : [];

  const leftItems = isAuthenticated
    ? getAuthSortedRoutes({
        auth,
        commonData: data?.common,
        getIsRouteAuthorized,
        getItemsLikeJcrName,
        navigate,
        orderKey: 'headerLeftOrder',
        resetHistory,
        role,
      })
    : [];

  const rightItems = isAuthenticated
    ? [
        ...authRightItems,
        {
          headerRightOrder: authRightItems.length + 1,
          id: 'logout',
          isExternal: false,
          isHeaderVisible: true,
          isModal: false,
          isPrintLinkVisible: true,
          isPublic: true,
          isSmallText: false,
          key: 'logout',
          onClick: async () => {
            await logout({ callingFunction: 'HeaderWrapper.jsx' });
          },
          text: 'Sign Out',
          title: 'Sign Out',
          url: '',
        },
      ]
    : [];

  const getRedirectLink = ({ item, itemProps, setIsOpen }) => (
    <RedirectLink
      buttonProps={{
        ...itemProps,
        onButtonClick: (e) => {
          setTimeout(() => setIsOpen(false), 1000);
          if (item?.onClick) {
            item.onClick();
          }
          if (itemProps?.onButtonClick) {
            itemProps.onButtonClick(e);
          }
        },
      }}
      buttonType="link"
      className="menu-row menu-items-divider menu-item d-block"
      cmsRedirectUrlKey={item.url}
      key={item.id}
      ref={redirectRef}
    >
      {item.title}
    </RedirectLink>
  );

  return (
    <>
      <VikingHeader
        additionalMenus={[
          {
            id: 'right-items',
            items: rightItems,
          },
        ]}
        getRedirectLink={getRedirectLink}
        header={{
          items: leftItems,
          logo: IMAGE_VIKING_LOGO_WHITE_HORIZONTAL,
          navigationRoute: ROUTES.home.url,
        }}
        headerTitle={route.title}
        isHeaderLogoVisible={route.isHeaderLogoVisible}
        isLeftMenuOpen={isLeftMenuOpen}
        isPagePublic={route.isPublic}
        isRightMenuOpen={isRightMenuOpen}
        onNavigate={({ url }) => navigate(url)}
        preHeaderText={SITE_NAME}
        setIsLeftMenuOpen={setIsLeftMenuOpen}
        setIsRightMenuOpen={setIsRightMenuOpen}
      >
        {isAuthenticated && firstName && (
          <Button
            appearance="icon"
            attributes={{
              'aria-expanded': isRightMenuOpen,
              'aria-haspopup': 'true',
              'aria-label': 'Toggle navigation',
              'data-toggle': 'collapse',
              tabIndex: '0',
            }}
            inlineCss={{ color: 'White', fontSize: '16px' }}
            onButtonClick={(e) => {
              setIsLeftMenuOpen(false);
              if (isAuthenticated) {
                setIsRightMenuOpen(!isRightMenuOpen);
              }
              if (screenTier === 'extra-small') {
                e.preventDefault();
              }
            }}
          >
            <span className="d-md-none">
              <Account />
            </span>

            <span className="d-none d-md-inline-block text-uppercase text-nowrap" title={agent.tapEmail || agent.email}>
              {/* eslint-disable-next-line max-len */}
              {`${data?.common?.strings?.labels?.general?.welcome}, ${firstName}${role.display && permissions ? ` - ${role.display}` : ''}`}
              &nbsp;
              <ChevronDown />
            </span>
          </Button>
        )}
      </VikingHeader>
      {children}
    </>
  );
};

Header.propTypes = {
  children: PropTypes.node,
};

export default Header;
